import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataService } from 'src/app/services/data.service';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-decline-reason-dialog',
  templateUrl: './decline-reason-dialog.component.html',
  styleUrls: ['./decline-reason-dialog.component.css']
})
export class DeclineReasonDialogComponent implements OnInit {
  // declineReasonsArr = [
  //   {
  //     reason:
  //       'The video seems to be of someone else. Please contact the Jodi365 Help Desk to assure us that your video is genuine.',
  //     isActive: false,
  //   },
  //   {
  //     reason: 'The video contains inappropriate content.',
  //     isActive: false,
  //   },
  //   {
  //     reason: 'The video consists of copyrighted content.',
  //     isActive: false,
  //   },
  //   {
  //     reason: 'The video contains potentially offensive content.',
  //     isActive: false,
  //   },
  //   {
  //     reason: 'The video contains frivolous content or gibberish.',
  //     isActive: false,
  //   },
  //   {
  //     reason: 'The video is not clear enough.',
  //     isActive: false,
  //   },
  //   {
  //     reason: 'The video does not show your face.',
  //     isActive: false,
  //   },
  //   {
  //     reason: 'The video does not show your full face.',
  //     isActive: false,
  //   },
  //   {
  //     reason:
  //       'No side videos, please. At least one video must show your full face, clearly.',
  //     isActive: false,
  //   },
  //   {
  //     reason:
  //       'At least one video must show your full face, clearly, without sunglasses',
  //     isActive: false,
  //   },
  //   {
  //     reason: 'The video is not upright. Please rotate it and re-submit.',
  //     isActive: false,
  //   },
  //   {
  //     reason:
  //       'The video seems to be a duplicate (of a previously submitted video).',
  //     isActive: false,
  //   },
  //   {
  //     reason:
  //       'This video is too similar to another video that you have uploaded.',
  //     isActive: false,
  //   },
  //   {
  //     reason:
  //       'The video shows other individuals\' faces. Even group videos are not allowed, out of respect for others\' privacy.',
  //     isActive: false,
  //   },
  //   {
  //     reason:
  //       'The video may turn some of your prospects off. Please upload a better video so that your profile makes a better first impression.',
  //     isActive: false,
  //   },
  //   {
  //     reason:
  //       // tslint:disable-next-line: max-line-length
  //       'The video may turn some of your prospects off. Be yourself, of course, but we discourage pictures that are too posey, stilted, or serious.',
  //     isActive: false,
  //   },
  //   {
  //     reason:
  //       'The video contains a videographer or video studio\'s name. If you like the video, please blur or crop the name out and re-submit.',
  //     isActive: false,
  //   },
  //   {
  //     reason:
  //       'The video contains another site\'s watermark. Please remove the watermark or submit another video.',
  //     isActive: false,
  //   },
  //   {
  //     reason:
  //       'The video/caption includes contact information (phone numbers, email addresses, social media usernames, web site URLs, etc.).',
  //     isActive: false,
  //   },
  //   {
  //     reason:
  //       // tslint:disable-next-line: max-line-length
  //       'The video/caption contains references to identifying information (your name, biography, social media accounts, web site URLs, vehicle license plates, street addresses, etc.).',
  //     isActive: false,
  //   },
  //   {
  //     reason:
  //       'This looks like a childhood video. Please upload a recent video.',
  //     isActive: false,
  //   },
  //   {
  //     reason: 'This looks like an old video. Please upload a recent video.',
  //     isActive: false,
  //   },
  //   {
  //     reason:
  //       // tslint:disable-next-line: max-line-length
  //       'The video you uploaded happens to be publicly visible online. For your security, please upload a video that hasn\'t been uploaded on any social media account and isn\'t otherwise searchable on the web.',
  //     isActive: false,
  //   },
  // ];

  selectedReasons = {};
  declineInputReason = new UntypedFormControl();
  showDeclineReasons;
  selDeclineReasonsBySupervisor;
  selDeclineReasonsByReviewer;
  supervisor;
  reviewer;
  declineReasonsArr = [];
  // tslint:disable-next-line: max-line-length
  constructor(private dataservice: DataService,public dialogRef: MatDialogRef<DeclineReasonDialogComponent>, @Inject(MAT_DIALOG_DATA) public data, private sharedservice: SharedService) { 
   
  }

  ngOnInit(): void {
    //console.log(this.data.videoData);
    this.supervisor = this.data.videoData.moderatedBy;
    this.reviewer = this.data.videoData.reviewedBy;
    // tslint:disable-next-line: max-line-length
    this.showDeclineReasons = this.sharedservice.isReviewer && this.data.videoData.unmoderated && this.data.videoData.reviewerAction === null ? true : this.data.videoData.unmoderated && !this.sharedservice.isReviewer && !this.data.readmore ? true : false;
    this.selDeclineReasonsBySupervisor = this.data.videoData.declinereason?.split('; ');
    this.selDeclineReasonsByReviewer = this.data.videoData.reason?.split('; ');
    //console.log(this.selDeclineReasonsByReviewer);
    // this.declineReasonsArr.forEach(element => {
    //   if (declineArr.includes(element.reason)) {
    //     element.isActive = true;
    //   }
    // });
    this.dataservice.getAllDeclineReason().subscribe((res: any)  => {
      this.declineReasonsArr = res.videodeclineReason;
    });
  }

  selDeclineReason(e, item, i) {
    if (e.checked) {
      this.selectedReasons[i] = item.reason;
    } else {
      delete this.selectedReasons[i];
    }
    console.log(this.selectedReasons);
  }

  sendDataBack() {
    let reasons = null;
    for (const key in this.selectedReasons) {
      if (!reasons) {
        reasons = this.selectedReasons[key];
      } else {
        reasons += '; ' + this.selectedReasons[key];
      }
    }
    console.log(this.declineInputReason);
    if (this.declineInputReason.value) {
      if (reasons) {
        reasons += '; ' + this.declineInputReason.value;
      } else {
        reasons = this.declineInputReason.value;
      }
    }
    this.dialogRef.close(reasons);
  }

}
