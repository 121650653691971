import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from 'src/app/services/shared.service';
import { SessionStorageService } from 'src/app/services/session-storage.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { LoadingScreenComponent } from '../../loading-screen/loading-screen.component';

@Component({
  selector: 'app-support-notes',
  templateUrl: './support-notes.component.html',
  styleUrls: ['./support-notes.component.css']
})
export class SupportNotesComponent implements OnInit {
  currentDateAndTime;
  user;
  firstNameSupportType;
  supportUserType;
  userId = null;
  userData;
  talkWithUser = false;
  localUserDataNotes = [];
  supportUserEmail: any = {};
  supportNotes = '';
  disableSaveNoteButton = false;
  showMore = false;
  skipValue = 0;
  skelentonView = true;
  loaderDailog;
  constructor(private apiservice: ApiService,private md: MatDialog, private localservice: LocalStorageService, private route: ActivatedRoute, private sharedservice: SharedService, private sessionstorage: SessionStorageService, private router:Router) {
    this.userId = this.route.snapshot.queryParams.userid;
    this.supportUserEmail = this.localservice.getFromLocal('user');
   }

  ngOnInit(): void {
    this.userData = this.sessionstorage.getFromSession('user' + this.userId);
    if(this.userData){
      this.skipValue = 0;
     this.getUserNotesdata();
    }
    // this.apiservice.supportunreadmessages().subscribe(res => {
    //   if(res){
    //     // this.getUserNotes(res);
    //   }
    // },
    // (err: HttpErrorResponse) => { // HttpErrorResponse for checking errors and we will show a msg to users
    //   if (err.status === 401) {
    //     this.localservice.removeToken('user');
    //     this.router.navigate(['/login']);
    //   } else if (err.status >= 500) {

    //   }
    // });
  }

  talkwithuserFunction(e){
    this.talkWithUser = e.checked;
   
  }

  // getUserNotes(userNoteData){
  //   console.log(userNoteData.length);
  //   if(userNoteData[0].timestamp != null ){
  //     for(const i in userNoteData){
  //       this.prepareNotesData(userNoteData[i]);
  //     }
      
  //   } 
  // }

  // prepareNotesData(userNoteData){
  //   const temp: any = {}
  //   temp.createdby = userNoteData.createdby;
  //   temp.detail = this.replaceURLs(userNoteData.detail); // check URL inside Notes then hightlight the link.
  //   temp.talkwith = userNoteData.talkwith;
  //   temp.Time = new Date(userNoteData.timestamp);
  //   temp.day = temp.Time.toString().slice(0, 3);

  //   this.localUserDataNotes.push(temp);
  // }

   detectURLs(notes) {
    const urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
    return notes.match(urlRegex)
  }

  replaceURLs(notes) { //check URL inside Notes then hightlight the link.
    this.detectURLs(notes);
    if(!notes) return;
  
    const urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
    return notes.replace(urlRegex, function (url) {
      let hyperlink = url;
      if (!hyperlink.match('^https?:\/\/')) {
        hyperlink = 'http://' + hyperlink;
      }
      return  '<a href="' + hyperlink + '" target="_blank" rel="noopener noreferrer">' + url + ' </a>'
    });
  }

  reloadNotesData(){
    this.localUserDataNotes = [];
    this.skipValue = 0;
    this.skelentonView = true;
    this.getUserNotesdata()
  }

  getUserNotesdata(){
    
  const apiparams: any = {
      userid: Number(this.userId),
      limit: 10,
      skip: this.skipValue ,
    };
    this.apiservice.getusernotes(apiparams).subscribe(result => {
      let temp : any = result;
      if(temp){
        if(temp.length >= 0){
          this.skelentonView = false;
        }
      }
      if(temp.length % 10 == 0 && temp.length > 0){
        this.showMore = true;
    } else {
      this.showMore = false;
    }
      if (result) {
        for(const i in result){
          let temp :any = {}
          
            temp.createdby = result[i].createdby
            temp.detail = this.replaceURLs(result[i].detail);
            temp.talkwith = result[i].talkwith;
            temp.Time = new Date(result[i].timestamp);
            temp.day = temp.Time.toString().slice(0, 3);
            this.localUserDataNotes.push(temp);
      }
    
      }
      if(this.skipValue >= 10){
        this.loaderDailog.close();
      }

    },
      (err: HttpErrorResponse) => { // HttpErrorResponse for checking errors and we will show a msg to users
        if (err.status === 401) {
          this.localservice.removeToken('user');
          this.router.navigate(['/login']);
        } else if (err.status >= 500) {
          //  this.sharedservice.gettingServerError();
        }
      });
    

  }

  loadMoreData(){
    this.skipValue = this.skipValue + 10;
    this.getUserNotesdata();
    this.loaderDailog = this.md.open(LoadingScreenComponent, { disableClose: true });
      
  }

  saveSupportNotesData(notes){ 
    this.disableSaveNoteButton = true;
    const finalnotes = this.replaceURLs(notes);
    if(notes.length> 0){
      const apiParams: any = {
        id: Number(this.userId),
        detail: notes ,
        talkwith: this.talkWithUser,
      };
      this.apiservice.makeusernotes(apiParams).subscribe(result => {
        this.disableSaveNoteButton = false;
          this.supportNotes = null;
      },
      (err: HttpErrorResponse) => { // HttpErrorResponse for checking errors and we will show a msg to users
        if (err.status === 401) {
          this.localservice.removeToken('user');
          this.router.navigate(['/login']);
        } else if (err.status >= 500) {
        
        }
      });
  
      const temp : any = {};
      temp.createdby = this.supportUserEmail.email;
      temp.detail = finalnotes ;
      temp.talkwith = false;
      temp.Time = new Date();
      temp.day = new Date().toString().slice(0, 3);
      this.localUserDataNotes.splice(0,0,temp);
  
    }
  }
}
