import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { DataService } from 'src/app/services/data.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-edits',
  templateUrl: './edits.component.html',
  styleUrls: ['./edits.component.css']
})
export class EditsComponent implements OnInit {
  displayArray = [];
  jsonData = [];
  commanKeyNames = ['height','location','bodytype','degrees','religion','grewupin'];
  skipValue = 0;
  apiresponse = true;
  userId;
  showMore = false;
  skelentonView = true;
  constructor(private apiservice : ApiService, private localservice: LocalStorageService, private router: Router, private dataservice: DataService, private sharedservice: SharedService, private dialog: MatDialog) {
    this.userId = window.location.href.split('userid=');
    this.dataservice.getAllEditLogs().subscribe((res: any) => {
        this.jsonData = res;
      
    });
   }

  ngOnInit(): void {
    const params = {
      userid : this.userId[1],
      skip : this.skipValue,
      limit : 10
    };
    setTimeout(() => {
      this.apiservice.getsupportedits(params).subscribe(async result => {
        if(result){
          if(result.length > 0){
            await this.prepareData(result);
            this.skelentonView = false;
          }
          if(result.length % 10 == 0 && result.length > 0){
              this.showMore = true;
          } else {
            this.showMore = false;
          }
          if(this.displayArray.length > 0){
            this.apiresponse = true;
          } else {
            this.skelentonView = false;
            this.apiresponse = false;
          }
        } 
        // handel error 
      },(err: HttpErrorResponse) => { // HttpErrorResponse for checking errors and we will show a msg to users
        this.apiresponse = false;
        if (err.status === 401) {
          this.localservice.removeToken('user');
          this.router.navigate(['/login']);
        } else if (err.status >= 500) {
          //  this.sharedservice.gettingServerError();
        }
    });
    }, 1000);
    
    
  
  }

  getAge(dateString : number) {
        const today = new Date();
        const birthDate = new Date(dateString);
        let age = today.getFullYear() - birthDate.getFullYear();
        const m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) 
        {
            age--;
        }
        return age;
        
 }


 locationData(index, plusIdeal, plusAcceptable, minusIdeal, minusAcceptable){
  if(this.displayArray[index].reloadCheck){
    const apiparams = [];
    const containRegionPlusIdeal = [];
    const containRegionPlusAcceptable = [];
    const containRegionMinusIdeal = [];
    const containRegionMinusAcceptable = [];
    let idealPlusIdValue = 1;
    let plusAcceptableIdValue = 1;
    let minusIdealIdValue = 1;
    let minusAcceptableIdValue = 1;
    if(plusIdeal.length > 0){
      const filterData = plusIdeal.filter((value) =>{
      if(isNaN(value)){
          containRegionPlusIdeal.push(value);
        } else {
          return value;
        }
      })
      const number = filterData.map((n) => {
        return parseInt(n, 10);
      })
      if(number.length > 0) {
        idealPlusIdValue = 2;
        apiparams.push(number);
      }
      
    } 
    if(plusAcceptable.length > 0){
      const filterData = plusAcceptable.filter((value) =>{
        if(isNaN(value)){
          containRegionPlusAcceptable.push(value);
          } else {
            return value;
          }
        })
        const number = filterData.map((n) => {
          return parseInt(n, 10);
        })
        if(number.length > 0) {
          plusAcceptableIdValue = 2;
          apiparams.push(number);
        }
        
    }
    if(minusIdeal.length > 0){
      const filterData = minusIdeal.filter((value) =>{
        if(isNaN(value)){
          containRegionMinusIdeal.push(value);
          } else {
            return value;
          }
        })
        const number = filterData.map((n) => {
          return parseInt(n, 10);
        }) 
        if(number.length > 0) {
          minusIdealIdValue = 2;
          apiparams.push(number);
        }
    }
    if(minusAcceptable.length > 0){
      const filterData = minusAcceptable.filter((value) =>{
        if(isNaN(value)){
          containRegionMinusAcceptable.push(value);
          } else {
            return value;
          }
        })
        const number = filterData.map((n) => {
          return parseInt(n, 10);
        })
        if(number.length > 0) {
          minusAcceptableIdValue = 2;
          apiparams.push(number);
        }
    }
    if(apiparams.length > 0){
      this.apiservice.getsupportlocation({placeid:apiparams}).subscribe(result => {
        const temp : any = result;
        if(temp.length > 0){
          let i = 0;
          if(idealPlusIdValue == 2 && result[i]){
            plusIdeal = result[i];
            plusIdeal = plusIdeal.concat(containRegionPlusIdeal);
            this.displayArray[index].plusData.ideal = plusIdeal;
            i++;
          } 
          if(plusAcceptableIdValue == 2 && result[i]){
            plusAcceptable = result[i];
            plusAcceptable = plusAcceptable.concat(containRegionPlusAcceptable);
            this.displayArray[index].plusData.acceptable = plusAcceptable;
            i++;
          } 
          if(minusIdealIdValue == 2 && result[i]) {
            minusIdeal = result[i];
            minusIdeal = minusIdeal.concat(containRegionMinusIdeal);
            this.displayArray[index].minusData.ideal = minusIdeal;
            i++;
          } 
          if(minusAcceptableIdValue == 2 && result[i]){
            minusAcceptable = result[i];
            minusAcceptable = minusAcceptable.concat(containRegionMinusAcceptable);
            this.displayArray[index].minusData.acceptable = minusAcceptable;
            i++;
          }
        }
      });
    }
    
    this.displayArray[index].reloadCheck = false;
  }
  
 }

 grewUpInData(index, plusData, minusData){
   if(this.displayArray[index].reloadCheck){
    const apiparams = [];
    if(plusData.length > 0){
      const innerDataArray = [];
      if(plusData[0]){
        innerDataArray.push(plusData[0].nodeid);
      } if(plusData[1]){
        innerDataArray.push(plusData[1].nodeid);
      }
      apiparams.push(innerDataArray);
    } if(minusData.length > 0){
      const innerDataArray = [];
      if(minusData[0]){
        innerDataArray.push(minusData[0].nodeid);
      } if(minusData[1]){
        innerDataArray.push(minusData[1].nodeid);
      }
      apiparams.push(innerDataArray);
    }
  
    this.apiservice.getsupportlocation({placeid:apiparams}).subscribe(result => {
      const temp :any = result
      if(temp.length > 0) {
        let i = 0;
        if(plusData.length > 0){
          let j = 0;
          if(plusData[0]){
            this.displayArray[index].valuePlus[0].nodeid = result[i][j];
            j++;
          } if(plusData[1]){
            this.displayArray[index].valuePlus[1].nodeid = result[i][j];
            j++;
          }
          i++;
        } if(minusData.length > 0){
          let j = 0;
          if(minusData[0]){
            this.displayArray[index].valueMinus[0].nodeid = result[i][j];
            j++;
          } if(minusData[1]){
            this.displayArray[index].valueMinus[1].nodeid = result[i][j];
            j++;
          }
          i++;
        }
      }
    })
    this.displayArray[index].reloadCheck = false;
   }
  
 }

 locationEditSection(index, plusData, minusData){
  if(this.displayArray[index].reloadCheck){
    const apiparams = [];
    if(plusData){
      const temp = [];
      temp.push(plusData);
      apiparams.push(temp);
    } if(minusData){
      const temp = [];
      temp.push(minusData);
      apiparams.push(temp);
    }
    this.apiservice.getsupportlocation({placeid:apiparams}).subscribe(result => {
      if(result){
        let i = 0;
        if(plusData){
          this.displayArray[index].valuePlus = result[i];
          i++;
        } 
        if(minusData){
          this.displayArray[index].valueMinus = result[i];
          i++;
        }
      }
    });
    this.displayArray[index].reloadCheck = false;
  }
  
 }

familyLocation(index, plusData, minusData){
  if(this.displayArray[index].reloadCheck){
    const apiparams = [];
    if(plusData.length > 0){
      const innerDataArray = [];
      if(plusData[0]){
        innerDataArray.push(plusData[0]);
      } if(plusData[1]){
        innerDataArray.push(plusData[1]);
      }
      apiparams.push(innerDataArray);
    } if(minusData.length > 0){
      const innerDataArray = [];
      if(minusData[0]){
        innerDataArray.push(minusData[0]);
      } if(minusData[1]){
        innerDataArray.push(minusData[1]);
      }
      apiparams.push(innerDataArray);
    }
  
    this.apiservice.getsupportlocation({placeid:apiparams}).subscribe(result => {
      const temp :any = result
      if(temp.length > 0) {
        let i = 0;
        if(plusData){
          this.displayArray[index].valuePlus = result[i];
          i++;
        }
        if(minusData){
            this.displayArray[index].valueMinus = result[i];
            i++;
        }
      }
    });
    this.displayArray[index].reloadCheck = false;
  }
  
 }

async prepareData(apiData){
  for(let i=0;i<apiData.length;i++){
   
    const keyName = (Object.keys(apiData[i]));
    let finalKeyNameList = [];
    finalKeyNameList = await this.prepareKeyName(keyName);
    if(finalKeyNameList.length > 0) {
      await this.prepareDisplayData(finalKeyNameList, apiData[i]);
    }
  }
}

prepareKeyName(keyName){
  const tempData = [];
  for(let j=0;j<keyName.length;j++){
    if(keyName[j].includes('+')){
      const tempKeyWord = keyName[j].replace('+','');
      tempData.push(tempKeyWord);
    } 
  }
  return tempData;
}


loadMoreData(){
  this.skipValue = this.skipValue + 10;
  this.ngOnInit();
    
}

async prepareDisplayData(finalKeyNameList, apiData){
  for(let k=0;k<finalKeyNameList.length;k++){
    
    // prefrences
   
      if(finalKeyNameList.length == 1 && (apiData[finalKeyNameList[k] + '+']?.ideal || apiData[finalKeyNameList[k] + '+']?.ideal == null) && apiData[finalKeyNameList[k] + '+']?.importance != undefined){
        let temp : any = {
          "isPersonalInfo": true,
          "plusData" : {
              "ideal" : [],
              "acceptable" : [],
              "strict" : false,
              "importance" : 0
          },
          "minusData" : {
              "ideal" : [],
              "acceptable" : [],
              "strict" : false,
              "importance" : 0
          },
          "timestamp":0,
          "editedBy": "",
          "reloadCheck" : true,
        };
        temp.domName = this.jsonData[finalKeyNameList[k]].domName;
        if(temp.domName != 'Height (Preferences)'){
        
        // plusData  
        temp.plusData.ideal = apiData[finalKeyNameList[k] + '+']?.ideal;
        temp.plusData.acceptable = apiData[finalKeyNameList[k] + '+']?.acceptable;
        temp.plusData.strict = apiData[finalKeyNameList[k] + '+']?.strict;
        if(apiData[finalKeyNameList[k] + '+']?.importance == 0){
          temp.plusData.importance = 0;
        }
        if(apiData[finalKeyNameList[k] + '+']?.importance > 0 && apiData[finalKeyNameList[k] + '+']?.importance <= 25 ){
          temp.plusData.importance = 25;
        } else if(apiData[finalKeyNameList[k] + '+']?.importance >= 26 && apiData[finalKeyNameList[k] + '+']?.importance <= 50){
          temp.plusData.importance = 50;
        } else if(apiData[finalKeyNameList[k] + '+']?.importance >= 51 && apiData[finalKeyNameList[k] + '+']?.importance <= 75){
          temp.plusData.importance = 75;
        } else if(apiData[finalKeyNameList[k] + '+']?.importance >= 76 && apiData[finalKeyNameList[k] + '+']?.importance <= 100){
          temp.plusData.importance = 100;
        }
  
        // minusData 
        temp.minusData.ideal = apiData[finalKeyNameList[k] + '-']?.ideal;
        temp.minusData.acceptable = apiData[finalKeyNameList[k] + '-']?.acceptable;
        temp.minusData.strict = apiData[finalKeyNameList[k] + '-']?.strict;
        temp.minusData.importance = apiData[finalKeyNameList[k] + '-']?.importance;
        if(apiData[finalKeyNameList[k] + '-']?.importance >= 0 && apiData[finalKeyNameList[k] + '-']?.importance <= 25 ){
          temp.minusData.importance = 25;
        } else if(apiData[finalKeyNameList[k] + '-']?.importance >= 26 && apiData[finalKeyNameList[k] + '-']?.importance <= 50){
          temp.minusData.importance = 50;
        } else if(apiData[finalKeyNameList[k] + '-']?.importance >= 51 && apiData[finalKeyNameList[k] + '-']?.importance <= 75){
          temp.minusData.importance = 75;
        } else if(apiData[finalKeyNameList[k] + '-']?.importance >= 76 && apiData[finalKeyNameList[k] + '-']?.importance <= 100){
          temp.minusData.importance = 100;
        }
        temp.timestamp = apiData.timestamp;
        temp.editedBy = apiData.editedBy;
        // console.log(temp);
        await this.displayArray.push(temp);
        temp = {};
        // console.log(temp);
        } 
        else if(temp.domName == 'Height (Preferences)'){
         
          // plus data
          temp.plusData.ideal = apiData[finalKeyNameList[k] + '+']?.ideal;
          temp.plusData.acceptable = apiData[finalKeyNameList[k] + '+']?.acceptable;
          temp.plusData.strict = apiData[finalKeyNameList[k] + '+']?.strict;
          if(apiData[finalKeyNameList[k] + '+']?.importance == 0){
            temp.plusData.importance = 0;
          }
          else if(apiData[finalKeyNameList[k] + '+']?.importance > 0 && apiData[finalKeyNameList[k] + '+']?.importance <= 25 ){
            temp.plusData.importance = 25;
          } else if(apiData[finalKeyNameList[k] + '+']?.importance >= 26 && apiData[finalKeyNameList[k] + '+']?.importance <= 50){
            temp.plusData.importance = 50;
          } else if(apiData[finalKeyNameList[k] + '+']?.importance >= 51 && apiData[finalKeyNameList[k] + '+']?.importance <= 75){
            temp.plusData.importance = 75;
          } else if(apiData[finalKeyNameList[k] + '+']?.importance >= 76 && apiData[finalKeyNameList[k] + '+']?.importance <= 100){
            temp.plusData.importance = 100;
          }
          if(temp.plusData.ideal != null){
            temp.plusData.ideal.high = this.sharedservice.getConvertedHeight(temp.plusData.ideal.high);
            temp.plusData.ideal.low = this.sharedservice.getConvertedHeight(temp.plusData.ideal.low);
          } if(temp.plusData.acceptable != null){
            temp.plusData.acceptable.high = this.sharedservice.getConvertedHeight(temp.plusData.acceptable.high);
            temp.plusData.acceptable.low = this.sharedservice.getConvertedHeight(temp.plusData.acceptable.low);
          }
          // minusData 
          temp.minusData.ideal = apiData[finalKeyNameList[k] + '-']?.ideal;
          temp.minusData.acceptable = apiData[finalKeyNameList[k] + '-']?.acceptable;
          temp.minusData.strict = apiData[finalKeyNameList[k] + '-']?.strict;
          temp.minusData.importance = apiData[finalKeyNameList[k] + '-']?.importance;
          if(apiData[finalKeyNameList[k] + '-']?.importance >= 0 && apiData[finalKeyNameList[k] + '-']?.importance <= 25 ){
            temp.minusData.importance = 25;
          } else if(apiData[finalKeyNameList[k] + '-']?.importance >= 26 && apiData[finalKeyNameList[k] + '-']?.importance <= 50){
            temp.minusData.importance = 50;
          } else if(apiData[finalKeyNameList[k] + '-']?.importance >= 51 && apiData[finalKeyNameList[k] + '-']?.importance <= 75){
            temp.minusData.importance = 75;
          } else if(apiData[finalKeyNameList[k] + '-']?.importance >= 76 && apiData[finalKeyNameList[k] + '-']?.importance <= 100){
            temp.minusData.importance = 100;
          }
          if(temp.minusData.ideal != null){
            temp.minusData.ideal.high = this.sharedservice.getConvertedHeight(temp.minusData.ideal.high);
            temp.minusData.ideal.low = this.sharedservice.getConvertedHeight(temp.minusData.ideal.low);
          } if(temp.minusData.acceptable != null){
            temp.minusData.acceptable.high = this.sharedservice.getConvertedHeight(temp.minusData.acceptable.high);
            temp.minusData.acceptable.low = this.sharedservice.getConvertedHeight(temp.minusData.acceptable.low);
          }
          temp.timestamp = apiData.timestamp;
          temp.editedBy = apiData.editedBy;
          // console.log(temp);
          await this.displayArray.push(temp);
          temp = {};
          // console.log(temp);
        }
        
        
      } 
    
   
    // my profile edit
    else {
      const temp : any = {
        "isPersonalInfo": false,
        "valuePlus": "",
        "valueMinus": "",
        "timestamp":0,
        "editedBy": "",
        "reloadCheck" : true,
      };
      
      const cIndex  = this.commanKeyNames.findIndex(data => finalKeyNameList[k] === data);
      if(cIndex > -1){
        temp.domName = this.jsonData['own-' + finalKeyNameList[k]].domName;
        if(temp.domName == 'Height (Appearance)'){
          temp.valuePlus = apiData[finalKeyNameList[k] + '+'];
          temp.valuePlus = this.sharedservice.getConvertedHeight(temp.valuePlus);
          temp.valueMinus = apiData[finalKeyNameList[k] + '-'];
          temp.valueMinus = this.sharedservice.getConvertedHeight(temp.valueMinus);
          temp.timestamp = apiData.timestamp;
          temp.editedBy = apiData.editedBy;
          await this.displayArray.push(temp);
        } else {
          temp.valuePlus = apiData[finalKeyNameList[k] + '+'];
          temp.valueMinus = apiData[finalKeyNameList[k] + '-'];
          temp.timestamp = apiData.timestamp;
          temp.editedBy = apiData.editedBy;
          await this.displayArray.push(temp);
        }
        
        // console.log(this.displayArray);
      } else {
        temp.domName = this.jsonData[finalKeyNameList[k]].domName;
        if(temp.domName == 'Age (Basics)'){
          temp.valuePlus  = apiData[finalKeyNameList[k] + '+'];
          temp.valueMinus = apiData[finalKeyNameList[k] + '-'];
          if(temp.valuePlus != null){
            temp.valuePlus = await this.getAge(temp.valuePlus * 1000);
          } if(temp.valueMinus != null) {
            temp.valueMinus = await this.getAge(temp.valueMinus * 1000);
          }
          temp.timestamp = apiData.timestamp;
          temp.editedBy = apiData.editedBy;
          await this.displayArray.push(temp);
        } else {
          temp.valuePlus = apiData[finalKeyNameList[k] + '+'];
          temp.valueMinus = apiData[finalKeyNameList[k] + '-'];
          temp.timestamp = apiData.timestamp;
          temp.editedBy = apiData.editedBy;
          await this.displayArray.push(temp);
          // console.log(this.displayArray);
        }
        
      }
    
    }
  }
}

}

