import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-full-photoview-dialog',
  templateUrl: './full-photoview-dialog.component.html',
  styleUrls: ['./full-photoview-dialog.component.css'],
  encapsulation: ViewEncapsulation.None 
})
export class FullPhotoviewDialogComponent implements OnInit {

  fulImageView;
  rotation = 0;
   // How much to rotate the image at a time
  angle = 90;
    
  constructor(public dialogRef: MatDialogRef<FullPhotoviewDialogComponent>, @Inject(MAT_DIALOG_DATA) public data) {
    this.fulImageView = this.data.image;
   }

  ngOnInit(): void {
  
  }
 
     
  rotateImage(){
   // Access DOM element object
  const rotated = document.getElementById('rotated');
   // Variable to hold the current angle of rotation

 // Ensure angle range of 0 to 359 with "%" operator,
// e.g., 450 -> 90, 360 -> 0, 540 -> 180, etc.
 this.rotation = (this.rotation + this.angle) % 360;
  rotated.style.transform = `rotate(${this.rotation}deg)`;
  }

  imageViewCLose() {
    this.dialogRef.close();
  }

}
