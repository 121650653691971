<div class="row mx-0 form-height justify-content-center bg-white">
  <div class="col-9 col-md-7 px-0 text-center img-margin">
    <img class="img-fluid" src="../../../../assets/images/static/Server-error.svg" alt="Server-error.svg">
  </div>
  <div class="col-12 col-md-7 px-0 text-center">
    <div class="pb-2 oswald c-darkgray j-h3">Oops! Something is not right.</div>
    <div class="c-gray body-subtext">Our best minds are on it.</div>
    <div class="c-gray body-subtext"> Please retry or check back soon!</div>
    <div class="mt-5">
      <button (click)="reload()" mat-raised-button color="primary" class="retry-btn text-uppercase">Retry</button>
    </div>
  </div>
  <div class="col-11 px-0 text-center">
    <div class="oswald c-darkgray j-h3 my-2">Still having trouble? Call us:</div>
    <div class="c-gray body-subtext mt-3">+91 934 JODI 365 (India) or +1 305 JODI 365 (USA)</div>
  </div>
</div>