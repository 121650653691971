import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { DataService } from 'src/app/services/data.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { ConfirmationDialogComponent } from '../../profiles-moderation/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-email-template-dialog',
  templateUrl: './email-template-dialog.component.html',
  styleUrls: ['./email-template-dialog.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class EmailTemplateDialogComponent implements OnInit {
  bccemails = [];
  ccemails = [];
  ccmailError = false;
  bccmailError = false;
  alias;
  userId;
  userProfileImg;
  firstName;
  submitted = false;
  selectedOptions = '';
  templateData = '';
  editTemplateData = '';
  emailTemp: UntypedFormGroup;
  userData = [{ option: 'select', content: '' }];
  selectedData: UntypedFormControl;
  isClear = false;
  isSelectData = false;
  disableSendEmailButton = false;
  constructor(private fb: UntypedFormBuilder, public dialogRef: MatDialogRef<EmailTemplateDialogComponent>, @Inject(MAT_DIALOG_DATA) public data, public dataservice: DataService, private apiservice: ApiService, private authService: AuthService, private md: MatDialog, private localservice: LocalStorageService) {
    this.alias = data.userAlias;
    this.firstName = data.firstName;
    this.userId = data.userID;
    this.userProfileImg = data.userProfileImage;
    this.dataservice.getAllEmailTemplate().subscribe((res: any) => {
      // console.log(res);
      this.userData = res;
    })
    this.emailTemp = this.fb.group({
      ccmail: ['', [Validators.email]],
      bccmail: ['', [Validators.email]],
      subjectLine: ['', [Validators.required]],
      content: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {

      this.emailTemp.get('ccmail').valueChanges.subscribe(res => {
        this.ccmailError = false;
      // this.ccemails = [res];
      // if (this.emailTemp.value.ccmail?.includes(',')) {
      // this.ccemails = this.emailTemp.value.ccmail.split(',');
      // }
      // let regex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');

      // this.ccemails.forEach((email) => {
      // if(!regex.test(email)){
      //   this.ccmailError = true;
      // } else {
      //   this.ccmailError = false;
      // }
      // });
    });

    this.emailTemp.get('bccmail').valueChanges.subscribe(res => {
      this.bccmailError = false;
      // this.bccemails = [res];
      // if (this.emailTemp.value.bccmail?.includes(',')) {
      // this.bccemails = this.emailTemp.value.bccmail.split(',');
      // }
      // let regex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');

      // this.bccemails.forEach((email) => {
      // if(!regex.test(email)){
      //   this.bccmailError = true;
      // } else {
      //   this.bccmailError = false;
      // }
      // });
    });

  }

  selectedEmailTemp(val) {
    this.isSelectData = false;
    const agentName = this.localservice.getFromLocal('user').firstname;
    const UserFirstName = this.firstName;
    const ind = this.userData.findIndex(id => id.option === val);
   
    this.emailTemp.patchValue({
      subjectLine: this.selectedOptions
    })
   
    this.userData[ind].content = this.userData[ind].content.replace(/\s?(<br\s?\/?>)\s?/g, "\n");
    this.userData[ind].content = this.userData[ind].content.replace(/{AgentFirstName}/g, agentName);
    this.userData[ind].content = this.userData[ind].content.replace(/{UserFirstName}/g, UserFirstName);

    this.emailTemp.patchValue({
      content: this.userData[ind].content
    })

    this.editTemplateData = this.emailTemp.value.content.replace(/(?:\r\n|\r|\n)/g, '<br>');
  }

  getTextEmail() {
    this.editTemplateData = this.emailTemp.value.content.replace(/(?:\r\n|\r|\n)/g, '<br>');
    // console.log(this.editTemplateData);
  }

  clearData() {
    this.emailTemp.patchValue({
      content: ''
    });
    
    this.emailTemp.patchValue({
      subjectLine: ''
    });


    this.selectedOptions = '';
    this.isClear = true;
  }

  isValidccmail(){
    this.ccemails = [this.emailTemp.value.ccmail];
      if (this.emailTemp.value.ccmail?.includes(',')) {
      this.ccemails = this.emailTemp.value.ccmail.split(',');
      }
      const regex = new RegExp('[a-z0-9]+@[a-z]+.[a-z]{2,3}');

      this.ccemails.forEach((email) => {
      if(!regex.test(email)){
        this.ccmailError = true;
      } else {
        this.ccmailError = false;
      }
      });
  }

  isValidbccmail(){
    this.bccemails = [this.emailTemp.value.bccmail];
    if (this.emailTemp.value.bccmail?.includes(',')) {
    this.bccemails = this.emailTemp.value.bccmail.split(',');
    }
    const regex = new RegExp('[a-z0-9]+@[a-z]+.[a-z]{2,3}');

    this.bccemails.forEach((email) => {
    if(!regex.test(email)){
      this.bccmailError = true;
    } else {
      this.bccmailError = false;
    }
    });
  }

  onSubmit() {
    this.submitted = true;
    this.disableSendEmailButton = true;
    if (!this.selectedOptions) {
      this.isSelectData = true;
    } 
    // for api calling check conditions 
    if (this.emailTemp.value.subjectLine && this.editTemplateData) {

      // let bccemails = this.emailTemp.value.bccmail ? [this.emailTemp.value.bccmail] : [];
      // let ccemails = this.emailTemp.value.ccmail ? [this.emailTemp.value.ccmail] : [];
      
      // if (this.emailTemp.value.ccmail?.includes(',')) {
      //   ccemails = this.emailTemp.value.ccmail.split(',');
      // }

      // if (this.emailTemp.value.bccmail?.includes(',')) {
      //   bccemails = this.emailTemp.value.bccmail.split(',');
      // }

      const apiparams = {
        emailcc: this.ccemails,
        emailbcc: this.bccemails,
        emailsubject: this.emailTemp.value.subjectLine,
        emailbody: this.editTemplateData,
        userid: this.userId
      };

      // api call
      this.apiservice.sendemailtemplate(apiparams).subscribe(res => {
        if (res) {
          this.disableSendEmailButton = false;
          this.dialogRef.close();

          const successDialog = this.md.open(ConfirmationDialogComponent, {
            autoFocus: false,
            maxHeight: '90vh',
            maxWidth: '30vw',
            data: { tempEmailData: 'sentEmail', isEmailTemp: true, template: this.emailTemp.value.subjectLine }
          });
          successDialog.afterClosed().subscribe( res => {
            if (res) {
              // console.log('successful');
            }
          });
        }
      },
        (err: HttpErrorResponse) => { // HttpErrorResponse for checking errors and we will show a msg to users
          if (err.status === 401) {
            this.emailTemp.setErrors({ unauthorised: true });
          } else if (err.status >= 500) {
            this.authService.gettingServerError();
          }
        });
    }
  }

}
