<div class="row mx-0">
    <div class="col-11 px-0 mx-auto pt-4" *ngIf="!skelentonView">
        <div class="row mx-0">
            <div class="col-12 px-0">
                <span class="oswald subheading c-darkgray float-left pt-2">Preferences with free strict filters</span>
                <button mat-button class="float-right edit-button-class" (click)="navigateToUserview()">EDIT</button>
            </div>
            <div class="col-12 px-0 pt-4" *ngFor="let item of displayData">
                <!-- age -->
                <div class="row mx-0">
                    <div class="col-12 px-0">
                        <span class="oswald body-text c-darkgray float-left pt-2 pl-2">Age</span>
                        <div [ngClass]="item.age.strict ? 'strict-button-on-class c-white' : 'strict-button-off-class c-graycolor'" class="float-right strict-button">STRICT FILTER
                            {{item.age.strict ? 'ON' : 'OFF'}}
                        </div>
                       
                    </div>
                </div>
                <div class="row mx-0">
                    <div class="col-12 px-0">
                        <div class="row mx-0 pt-3">
                            <div class="col-4 px-0 pl-3 opensans-light c-darkgray text-left pt-1">
                                Importance
                            </div>
                            <div class="col-8 px-0 text-left font-class" *ngIf="item.age.importance != null">
                                <img class="pr-2" src="/assets/images/static/importance-{{item.age.importance}}-icon.svg" alt="">
                                <span class="position-absolute" style="left: 51px;top: 4px;">{{item.age.importance}}</span>
                                <span *ngIf="item.age.importance == 100">Strictly match</span>
                                <span *ngIf="item.age.importance == 75">Very important</span>
                                <span *ngIf="item.age.importance == 50">Somewhat important</span>
                                <span *ngIf="item.age.importance == 25">Not important</span>
                            </div>
                            <div class="col-8 px-0 text-left font-class" *ngIf="item.age.importance == null">
                                <div class="p-1 unspecified-class"><span style="padding-left: 90px;">Unspecified</span></div>
                                <span class="position-absolute" style="left: 65px;top: 5px;">0</span>
                            </div>
                        </div>
                        <div class="row mx-0 pt-3" *ngIf="item.age.importance != null">
                            <div class="col-4 px-0 pl-3 pt-2 opensans-light c-darkgray text-left">
                                Ideal
                            </div>
                            <div class="col-8 px-0 pl-3 py-2 text-left box-height c-darkgray">
                                <ul class="list-inline list-parent text-left mb-0">
                                    <li class="list-inline-item">
                                        {{item.age.ideal.low}}-{{item.age.ideal.high}} years
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="row mx-0 pt-3" *ngIf="item.age.importance != null">
                            <div class="col-4 px-0 pl-3 pt-2 opensans-light c-darkgray text-left">
                                Acceptable
                            </div>
                            <div class="col-8 px-0 pl-3 py-2 text-left box-height c-darkgray" >
                                <ul class="list-inline list-parent text-left mb-0">
                                    <li class="list-inline-item">
                                        {{item.age.acceptable.low}}-{{item.age.acceptable.high}} years
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
               

                <!-- marital -->
                <div class="row mx-0 pt-4">
                    <div class="col-12 px-0">
                        <span class="oswald body-text c-darkgray float-left pt-2 pl-2">Marital status</span>
                        <div [ngClass]="item.marital.strict ? 'strict-button-on-class c-white' : 'strict-button-off-class c-graycolor'" class="float-right strict-button">STRICT FILTER
                            {{item.marital.strict ? 'ON' : 'OFF'}}
                        </div>
                    </div>
                </div>
                <div class="row mx-0">
                    <div class="col-12 px-0">
                        <div class="row mx-0 pt-3" >
                            <div class="col-4 px-0 pl-3 opensans-light c-darkgray text-left pt-1">
                                Importance
                            </div>
                            <div class="col-8 px-0 text-left font-class" *ngIf="item.marital.importance != null">
                                <img class="pr-2" src="/assets/images/static/importance-{{item.marital.importance}}-icon.svg" alt="">
                                <span class="position-absolute" style="left: 51px;top: 4px;">{{item.marital.importance}}</span>
                               
                                <span *ngIf="item.marital.importance == 100">Strictly match</span>
                                <span *ngIf="item.marital.importance == 75">Very important</span>
                                <span *ngIf="item.marital.importance == 50">Somewhat important</span>
                                <span *ngIf="item.marital.importance == 25">Not important</span>
                            </div>
                            <div class="col-8 px-0 text-left font-class" *ngIf="item.marital.importance == null">
                                <div class="p-1 unspecified-class"><span style="padding-left: 90px;">Unspecified</span></div>
                                <span class="position-absolute" style="left: 65px;top: 5px;">0</span>
                            </div>
                        </div>
                        <div class="row mx-0 pt-3" *ngIf="item.marital.importance != null">
                            <div class="col-4 px-0 pl-3 pt-2 opensans-light c-darkgray text-left">
                                Ideal
                            </div>
                            <div class="col-8 px-0 pl-3 py-2 text-left box-height c-darkgray ideal-acceptable-box-height">
                                <ul class="list-inline list-parent text-left mb-0">
                                    <li class="list-inline-item" *ngFor="let maritalItem of item.marital.ideal">
                                        {{maritalItem}}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="row mx-0 pt-3" *ngIf="item.marital.importance != null">
                            <div class="col-4 px-0 pl-3 pt-2 opensans-light c-darkgray text-left">
                                Acceptable
                            </div>
                            <div class="col-8 px-0 pl-3 py-2 text-left box-height c-darkgray ideal-acceptable-box-height">
                                <ul class="list-inline list-parent text-left mb-0">
                                    <li class="list-inline-item" *ngFor="let maritalItem of item.marital.acceptable">
                                        {{maritalItem}}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- location -->
                <div class="row mx-0 pt-4">
                    <div class="col-12 px-0">
                        <span class="oswald body-text c-darkgray float-left pt-2 pl-2">Location</span>
                        <div [ngClass]="item.location.strict ? 'strict-button-on-class c-white' : 'strict-button-off-class c-graycolor'" class="float-right strict-button">STRICT FILTER
                            {{item.location.strict ? 'ON' : 'OFF'}}
                        </div>
                    </div>
                </div>
                <div class="row mx-0">
                    <div class="col-12 px-0">
                        <div class="row mx-0 pt-3" >
                            <div class="col-4 px-0 pl-3 opensans-light c-darkgray text-left pt-1">
                                Importance
                            </div>
                            <div class="col-8 px-0 text-left font-class" *ngIf="item.location.importance != null">
                                <img class="pr-2" src="/assets/images/static/importance-{{item.location.importance}}-icon.svg" alt="">
                                <span class="position-absolute" style="left: 51px;top: 4px;">{{item.location.importance}}</span>
                                <span *ngIf="item.location.importance == 100">Strictly match</span>
                                <span *ngIf="item.location.importance == 75">Very important</span>
                                <span *ngIf="item.location.importance == 50">Somewhat important</span>
                                <span *ngIf="item.location.importance == 25">Not important</span>
                            </div>
                            <div class="col-8 px-0 text-left font-class" *ngIf="item.location.importance == null">
                                <div class="p-1 unspecified-class"><span style="padding-left: 90px;">Unspecified</span></div>
                                <span class="position-absolute" style="left: 65px;top: 5px;">0</span>
                            </div>
                        </div>
                        <div class="row mx-0 pt-3" *ngIf="item.location.importance != null">
                            <div class="col-4 px-0 pl-3 pt-2 opensans-light c-darkgray text-left">
                                Ideal
                            </div>
                            <div class="col-8 px-0 pl-3 py-2 text-left box-height c-darkgray ideal-acceptable-box-height demo">
                                <ul class="list-inline list-parent text-left mb-0">
                                    <li class="list-inline-item" *ngFor="let locationItem of item.location.ideal">
                                        <span *ngIf="locationItem.city">{{locationItem.city}}<span *ngIf="locationItem.state">,</span> </span>
                                        <span *ngIf="locationItem.state"> {{locationItem.state}}<span *ngIf="locationItem.country">,</span> </span>
                                        <span> {{locationItem.country}}</span>
                                        <!-- {{locationItem.city}}, {{locationItem.state}}, {{locationItem.country}} -->
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="row mx-0 pt-3" *ngIf="item.location.importance != null">
                            <div class="col-4 px-0 pl-3 pt-2 opensans-light c-darkgray text-left">
                                Acceptable
                            </div>
                            <div class="col-8 px-0 pl-3 py-2 text-left box-height c-darkgray ideal-acceptable-box-height">
                                <ul class="list-inline list-parent text-left mb-0">
                                    <li class="list-inline-item" *ngFor="let locationItem of item.location.acceptable">
                                        <span *ngIf="locationItem.city">{{locationItem.city}}<span *ngIf="locationItem.state">,</span> </span>
                                        <span *ngIf="locationItem.state"> {{locationItem.state}}<span *ngIf="locationItem.country">,</span></span>
                                        <span> {{locationItem.country}}</span>
                                        <!-- {{locationItem.state}}, {{locationItem.country}} -->
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
               
                <!-- height -->
                <div class="row mx-0 pt-4">
                    <div class="col-12 px-0">
                        <span class="oswald body-text c-darkgray float-left pt-2 pl-2">Height</span>
                        <div [ngClass]="item.height.strict ? 'strict-button-on-class c-white' : 'strict-button-off-class c-graycolor'" class="float-right strict-button">STRICT FILTER
                            {{item.height.strict ? 'ON' : 'OFF'}}
                        </div>
                    </div>
                </div>
                <div class="row mx-0">
                    <div class="col-12 px-0">
                        <div class="row mx-0 pt-3">
                            <div class="col-4 px-0 pl-3 opensans-light c-darkgray text-left pt-1">
                                Importance
                            </div>
                            <div class="col-8 px-0 text-left font-class" *ngIf="item.height.importance != null">
                                <img class="pr-2" src="/assets/images/static/importance-{{item.height.importance}}-icon.svg" alt="">
                                <span class="position-absolute" style="left: 51px;top: 4px;">{{item.height.importance}}</span>
                                <span *ngIf="item.height.importance == 100">Strictly match</span>
                                <span *ngIf="item.height.importance == 75">Very important</span>
                                <span *ngIf="item.height.importance == 50">Somewhat important</span>
                                <span *ngIf="item.height.importance == 25">Not important</span>
                            </div>
                            <div class="col-8 px-0 text-left font-class" *ngIf="item.height.importance == null">
                                <div class="p-1 unspecified-class"><span style="padding-left: 90px;">Unspecified</span></div>
                                <span class="position-absolute" style="left: 65px;top: 5px;">0</span>
                            </div>
                        </div>
                        <div class="row mx-0 pt-3" *ngIf="item.height.importance != null">
                            <div class="col-4 px-0 pl-3 pt-2 opensans-light c-darkgray text-left">
                                Ideal
                            </div>
                            <div class="col-8 px-0 pl-3 py-2 text-left box-height c-darkgray ideal-acceptable-box-height">
                                <ul class="list-inline list-parent text-left mb-0">
                                    <li class="list-inline-item">
                                        {{item.height.ideal.low}} To {{item.height.ideal.high}}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="row mx-0 pt-3" *ngIf="item.height.importance != null">
                            <div class="col-4 px-0 pl-3 pt-2 opensans-light c-darkgray text-left">
                                Acceptable
                            </div>
                            <div class="col-8 px-0 pl-3 py-2 text-left box-height c-darkgray ideal-acceptable-box-height" >
                                <ul class="list-inline list-parent text-left mb-0">
                                    <li class="list-inline-item">
                                        {{item.height.acceptable.low}} To {{item.height.acceptable.high}}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- edulevel -->
                <div class="row mx-0 pt-4">
                    <div class="col-12 px-0">
                        <span class="oswald body-text c-darkgray float-left pt-2 pl-2">Minimum education level</span>
                        <div [ngClass]="item.educationLevel.strict ? 'strict-button-on-class c-white' : 'strict-button-off-class c-graycolor'" class="float-right strict-button">STRICT FILTER
                            {{item.educationLevel.strict ? 'ON' : 'OFF'}}
                        </div>
                    </div>
                </div>
                <div class="row mx-0">
                    <div class="col-12 px-0">
                        <div class="row mx-0 pt-3">
                            <div class="col-4 px-0 pl-3 opensans-light c-darkgray text-left pt-1">
                                Importance
                            </div>
                            <div class="col-8 px-0 text-left font-class" *ngIf="item.educationLevel.importance != null">
                                <img class="pr-2" src="/assets/images/static/importance-{{item.educationLevel.importance}}-icon.svg" alt="">
                                <span class="position-absolute" style="left: 51px;top: 4px;">{{item.educationLevel.importance}}</span>
                                <span *ngIf="item.educationLevel.importance == 100">Strictly match</span>
                                <span *ngIf="item.educationLevel.importance == 75">Very important</span>
                                <span *ngIf="item.educationLevel.importance == 50">Somewhat important</span>
                                <span *ngIf="item.educationLevel.importance == 25">Not important</span>
                            </div>
                            <div class="col-8 px-0 text-left font-class" *ngIf="item.educationLevel.importance == null">
                                <div class="p-1 unspecified-class"><span style="padding-left: 90px;">Unspecified</span></div>
                                <span class="position-absolute" style="left: 65px;top: 5px;">0</span>
                            </div>
                        </div>
                        <div class="row mx-0 pt-3" *ngIf="item.educationLevel.importance != null">
                            <div class="col-4 px-0 pl-3 pt-2 opensans-light c-darkgray text-left">
                                Ideal
                            </div>
                            <div class="col-8 px-0 pl-3 py-2 text-left box-height c-darkgray ideal-acceptable-box-height">
                                <ul class="list-inline list-parent text-left mb-0">
                                    <li class="list-inline-item">
                                        {{item.educationLevel.ideal}}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="row mx-0 pt-3" *ngIf="item.educationLevel.importance != null">
                            <div class="col-4 px-0 pl-3 pt-2 opensans-light c-darkgray text-left">
                                Acceptable
                            </div>
                            <div class="col-8 px-0 pl-3 py-2 text-left box-height c-darkgray ideal-acceptable-box-height">
                                <ul class="list-inline list-parent text-left mb-0">
                                    <li class="list-inline-item">
                                        {{item.educationLevel.acceptable}}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Language  -->
                <div class="row mx-0 pt-4">
                    <div class="col-12 px-0">
                        <span class="oswald body-text c-darkgray float-left pt-2 pl-2">Language</span>
                        <div [ngClass]="item.language.strict ? 'strict-button-on-class c-white' : 'strict-button-off-class c-graycolor'" class="float-right strict-button">STRICT FILTER
                            {{item.language.strict ? 'ON' : 'OFF'}}
                        </div>
                    </div>
                </div>
                <div class="row mx-0">
                    <div class="col-12 px-0">
                        <div class="row mx-0 pt-3" >
                            <div class="col-4 px-0 pl-3 opensans-light c-darkgray text-left pt-1">
                                Importance
                            </div>
                            <div class="col-8 px-0 text-left font-class" *ngIf="item.language.importance != null">
                                <img class="pr-2" src="/assets/images/static/importance-{{item.language.importance}}-icon.svg" alt="">
                                <span class="position-absolute" style="left: 51px;top: 4px;">{{item.language.importance}}</span>
                                <span *ngIf="item.language.importance == 100">Strictly match</span>
                                <span *ngIf="item.language.importance == 75">Very important</span>
                                <span *ngIf="item.language.importance == 50">Somewhat important</span>
                                <span *ngIf="item.language.importance == 25">Not important</span>
                            </div>
                            <div class="col-8 px-0 text-left font-class" *ngIf="item.language.importance == null">
                                <div class="p-1 unspecified-class"><span style="padding-left: 90px;">Unspecified</span></div>
                                <span class="position-absolute" style="left: 65px;top: 5px;">0</span>
                            </div>
                        </div>
                        <div class="row mx-0 pt-3" *ngIf="item.language.importance != null">
                            <div class="col-4 px-0 pl-3 pt-2 opensans-light c-darkgray text-left">
                                Ideal
                            </div>
                            <div class="col-8 px-0 pl-3 py-2 text-left box-height c-darkgray ideal-acceptable-box-height">
                                <ul class="list-inline list-parent text-left mb-0">
                                    <li class="list-inline-item" *ngFor="let languageItem of item.language.ideal">
                                        {{languageItem}}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="row mx-0 pt-3" *ngIf="item.language.importance != null">
                            <div class="col-4 px-0 pl-3 pt-2 opensans-light c-darkgray text-left">
                                Acceptable
                            </div>
                            <div class="col-8 px-0 pl-3 py-2 text-left box-height c-darkgray ideal-acceptable-box-height">
                                <ul class="list-inline list-parent text-left mb-0" >
                                    <li class="list-inline-item" *ngFor="let languageItem of item.language.acceptable">
                                        {{languageItem}}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Religious affiliation  -->
                <div class="row mx-0 pt-4">
                    <div class="col-12 px-0">
                        <span class="oswald body-text c-darkgray float-left pt-2 pl-2">Religious affiliation </span>
                        <div [ngClass]="item.religion.strict ? 'strict-button-on-class c-white' : 'strict-button-off-class c-graycolor'" class="float-right strict-button">STRICT FILTER
                            {{item.religion.strict ? 'ON' : 'OFF'}}
                        </div>
                    </div>
                </div>
                <div class="row mx-0">
                    <div class="col-12 px-0">
                        <div class="row mx-0 pt-3" >
                            <div class="col-4 px-0 pl-3 opensans-light c-darkgray text-left pt-1">
                                Importance
                            </div>
                            <div class="col-8 px-0 text-left font-class" *ngIf="item.religion.importance != null">
                                <img class="pr-2" src="/assets/images/static/importance-{{item.religion.importance}}-icon.svg" alt="">
                                <span class="position-absolute" style="left: 51px;top: 4px;">{{item.religion.importance}}</span>
                                <span *ngIf="item.religion.importance == 100">Strictly match</span>
                                <span *ngIf="item.religion.importance == 75">Very important</span>
                                <span *ngIf="item.religion.importance == 50">Somewhat important</span>
                                <span *ngIf="item.religion.importance == 25">Not important</span>
                            </div>
                            <div class="col-8 px-0 text-left font-class" *ngIf="item.religion.importance == null">
                                <div class="p-1 unspecified-class"><span style="padding-left: 90px;">Unspecified</span></div>
                                <span class="position-absolute" style="left: 65px;top: 5px;">0</span>
                            </div>
                        </div>
                        <div class="row mx-0 pt-3" *ngIf="item.religion.importance != null">
                            <div class="col-4 px-0 pl-3 pt-2 opensans-light c-darkgray text-left">
                                Ideal
                            </div>
                            <div class="col-8 px-0 pl-3 py-2 text-left box-height c-darkgray ideal-acceptable-box-height">
                                <ul class="list-inline list-parent text-left mb-0" >
                                    <li class="list-inline-item" *ngFor="let religionItem of item.religion.ideal">
                                        {{religionItem}}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="row mx-0 pt-3" *ngIf="item.religion.importance != null">
                            <div class="col-4 px-0 pl-3 pt-2 opensans-light c-darkgray text-left">
                                Acceptable
                            </div>
                            <div class="col-8 px-0 pl-3 py-2 text-left box-height c-darkgray ideal-acceptable-box-height">
                                <ul class="list-inline list-parent text-left mb-0" >
                                    <li class="list-inline-item" *ngFor="let religionItem of item.religion.acceptable">
                                        {{religionItem}}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Approach to food  -->
                <div class="row mx-0 pt-4">
                    <div class="col-12 px-0">
                        <span class="oswald body-text c-darkgray float-left pt-2 pl-2">Approach to food</span>
                        <div [ngClass]="item.approchToFood.strict ? 'strict-button-on-class c-white' : 'strict-button-off-class c-graycolor'" class="float-right strict-button">STRICT FILTER
                            {{item.approchToFood.strict ? 'ON' : 'OFF'}}
                        </div>
                    </div>
                </div>
                <div class="row mx-0">
                    <div class="col-12 px-0">
                        <div class="row mx-0 pt-3" >
                            <div class="col-4 px-0 pl-3 opensans-light c-darkgray text-left pt-1">
                                Importance
                            </div>
                            <div class="col-8 px-0 text-left font-class" *ngIf="item.approchToFood.importance != null">
                                <img class="pr-2" src="/assets/images/static/importance-{{item.approchToFood.importance}}-icon.svg" alt="">
                                <span class="position-absolute" style="left: 51px;top: 4px;">{{item.approchToFood.importance}}</span>
                                <span *ngIf="item.approchToFood.importance == 100">Strictly match</span>
                                <span *ngIf="item.approchToFood.importance == 75">Very important</span>
                                <span *ngIf="item.approchToFood.importance == 50">Somewhat important</span>
                                <span *ngIf="item.approchToFood.importance == 25">Not important</span>
                            </div>
                            <div class="col-8 px-0 text-left font-class"  *ngIf="item.approchToFood.importance == null">
                                <div class="p-1 unspecified-class"><span style="padding-left: 90px;">Unspecified</span></div>
                                <span class="position-absolute" style="left: 65px;top: 5px;">0</span>
                            </div>
                        </div>
                        <div class="row mx-0 pt-3"  *ngIf="item.approchToFood.importance != null">
                            <div class="col-4 px-0 pl-3 pt-2 opensans-light c-darkgray text-left">
                                Ideal
                            </div>
                            <div class="col-8 px-0 pl-3 py-2 text-left box-height c-darkgray ideal-acceptable-box-height">
                                <ul class="list-inline list-parent text-left mb-0" >
                                    <li class="list-inline-item" *ngFor="let foodItem of item.approchToFood.ideal">
                                        {{foodItem}}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="row mx-0 pt-3"  *ngIf="item.approchToFood.importance != null">
                            <div class="col-4 px-0 pl-3 pt-2 opensans-light c-darkgray text-left">
                                Acceptable
                            </div>
                            <div class="col-8 px-0 pl-3 py-2 text-left box-height c-darkgray ideal-acceptable-box-height">
                                <ul class="list-inline list-parent text-left mb-0" >
                                    <li class="list-inline-item" *ngFor="let foodItem of item.approchToFood.acceptable">
                                        {{foodItem}}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>


                <!-- Smoking habit  -->
                <div class="row mx-0 pt-4">
                    <div class="col-12 px-0">
                        <span class="oswald body-text c-darkgray float-left pt-2 pl-2">Smoking habit</span>
                        <div [ngClass]="item.smoking.strict ? 'strict-button-on-class c-white' : 'strict-button-off-class c-graycolor'" class="float-right strict-button">STRICT FILTER
                            {{item.smoking.strict ? 'ON' : 'OFF'}}
                        </div>
                    </div>
                </div>
                <div class="row mx-0">
                    <div class="col-12 px-0">
                        <div class="row mx-0 pt-3" >
                            <div class="col-4 px-0 pl-3 opensans-light c-darkgray text-left pt-1">
                                Importance
                            </div>
                            <div class="col-8 px-0 text-left font-class" *ngIf="item.smoking.importance != null">
                                <img class="pr-2" src="/assets/images/static/importance-{{item.smoking.importance}}-icon.svg" alt="">
                                <span class="position-absolute" style="left: 51px;top: 4px;">{{item.smoking.importance}}</span>
                               
                                <span *ngIf="item.smoking.importance == 100">Strictly match</span>
                                <span *ngIf="item.smoking.importance == 75">Very important</span>
                                <span *ngIf="item.smoking.importance == 50">Somewhat important</span>
                                <span *ngIf="item.smoking.importance == 25">Not important</span>
                            </div>
                            <div class="col-8 px-0 text-left font-class" *ngIf="item.smoking.importance == null">
                                <div class="p-1 unspecified-class"><span style="padding-left: 90px;">Unspecified</span></div>
                                <span class="position-absolute" style="left: 65px;top: 5px;">0</span>
                            </div>
                        </div>
                        <div class="row mx-0 pt-3" *ngIf="item.smoking.importance != null">
                            <div class="col-4 px-0 pl-3 pt-2 opensans-light c-darkgray text-left">
                                Ideal
                            </div>
                            <div class="col-8 px-0 pl-3 py-2 text-left box-height c-darkgray ideal-acceptable-box-height">
                                <ul class="list-inline list-parent text-left mb-0" >
                                    <li class="list-inline-item" *ngFor="let smokingItem of item.smoking.ideal">
                                        {{smokingItem}}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="row mx-0 pt-3" *ngIf="item.smoking.importance != null">
                            <div class="col-4 px-0 pl-3 pt-2 opensans-light c-darkgray text-left">
                                Acceptable
                            </div>
                            <div class="col-8 px-0 pl-3 py-2 text-left box-height c-darkgray ideal-acceptable-box-height">
                                <ul class="list-inline list-parent text-left mb-0" >
                                    <li class="list-inline-item" *ngFor="let smokingItem of item.smoking.acceptable">
                                        {{smokingItem}}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                 <!-- Drinking habit  -->
                 <div class="row mx-0 pt-4">
                    <div class="col-12 px-0">
                        <span class="oswald body-text c-darkgray float-left pt-2 pl-2">Drinking habit</span>
                        <div [ngClass]="item.drinking.strict ? 'strict-button-on-class c-white' : 'strict-button-off-class c-graycolor'" class="float-right strict-button">STRICT FILTER
                            {{item.drinking.strict ? 'ON' : 'OFF'}}
                        </div>
                    </div>
                </div>
                <div class="row mx-0 pb-3">
                    <div class="col-12 px-0">
                        <div class="row mx-0 pt-3" >
                            <div class="col-4 px-0 pl-3 opensans-light c-darkgray text-left pt-1">
                                Importance
                            </div>
                            <div class="col-8 px-0 text-left font-class" *ngIf="item.drinking.importance != null">
                                <img class="pr-2" src="/assets/images/static/importance-{{item.drinking.importance}}-icon.svg" alt="">
                                <span class="position-absolute" style="left: 51px;top: 4px;">{{item.drinking.importance}}</span>
                                <span *ngIf="item.drinking.importance == 100">Strictly match</span>
                                <span *ngIf="item.drinking.importance == 75">Very important</span>
                                <span *ngIf="item.drinking.importance == 50">Somewhat important</span>
                                <span *ngIf="item.drinking.importance == 25">Not important</span>
                            </div>
                            <div class="col-8 px-0 text-left font-class" *ngIf="item.drinking.importance == null">
                                <div class="p-1 unspecified-class"><span style="padding-left: 90px;">Unspecified</span></div>
                                <span class="position-absolute" style="left: 65px;top: 5px;">0</span>
                            </div>
                        </div>
                        <div class="row mx-0 pt-3" *ngIf="item.drinking.importance != null">
                            <div class="col-4 px-0 pl-3 pt-2 opensans-light c-darkgray text-left">
                                Ideal
                            </div>
                            <div class="col-8 px-0 pl-3 py-2 text-left box-height c-darkgray ideal-acceptable-box-height">
                                <ul class="list-inline list-parent text-left mb-0" >
                                    <li class="list-inline-item" *ngFor="let drinkingItem of item.drinking.ideal">
                                        {{drinkingItem}}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="row mx-0 pt-3" *ngIf="item.drinking.importance != null">
                            <div class="col-4 px-0 pl-3 pt-2 opensans-light c-darkgray text-left">
                                Acceptable
                            </div>
                            <div class="col-8 px-0 pl-3 py-2 text-left box-height c-darkgray ideal-acceptable-box-height">
                                <ul class="list-inline list-parent text-left mb-0" >
                                    <li class="list-inline-item" *ngFor="let drinkingItem of item.drinking.acceptable">
                                        {{drinkingItem}}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <hr style="border-top: 1px solid #cacaca;">

                <!-- Additional filters under Quick Connect -->
                <div class="row mx-0 pt-2">
                    <div class="col-12 px-0">
                        <span class="oswald subheading c-darkgray float-left pt-2">Additional filters under Quick Connect</span>
                        <button mat-button class="float-right edit-button-class" (click)="navigateToUserview()">EDIT</button>
                    </div>
                    <div class="col-12 px-0 pt-3">
                        
                        <div class="row mx-0 pt-4">
                            <div class="col-12 px-0">
                                <span class="oswald body-text c-darkgray float-left pt-2 pl-2">Profile managed by
                                </span>
                                <div [ngClass]="item.managedby.strict ? 'strict-button-on-class c-white' : 'strict-button-off-class c-graycolor'" class="float-right strict-button">STRICT FILTER
                                    {{item.managedby.strict ? 'ON' : 'OFF'}}
                                </div>
                            </div>
                        </div>
                        <div class="row mx-0 pb-3">
                            <div class="col-12 px-0">
                                <div class="row mx-0 pt-3" >
                                    <div class="col-4 px-0 pl-3 opensans-light c-darkgray text-left pt-1">
                                        Importance
                                    </div>
                                    <div class="col-8 px-0 text-left font-class" *ngIf="item.managedby.importance != null">
                                        <img class="pr-2" src="/assets/images/static/importance-{{item.managedby.importance}}-icon.svg" alt="">
                                        <span class="position-absolute" style="left: 51px;top: 4px;">{{item.managedby.importance}}</span>
                                        <span *ngIf="item.managedby.importance == 100">Strictly match</span>
                                        <span *ngIf="item.managedby.importance == 75">Very important</span>
                                        <span *ngIf="item.managedby.importance == 50">Somewhat important</span>
                                        <span *ngIf="item.managedby.importance == 25">Not important</span>
                                    </div>
                                    <div class="col-8 px-0 text-left font-class" *ngIf="item.managedby.importance == null">
                                        <div class="p-1 unspecified-class"><span style="padding-left: 90px;">Unspecified</span></div>
                                        <span class="position-absolute" style="left: 65px;top: 5px;">0</span>
                                    </div>
                                </div>
                                <div class="row mx-0 pt-3" *ngIf="item.managedby.importance != null">
                                    <div class="col-4 px-0 pl-3 pt-2 opensans-light c-darkgray text-left">
                                        Ideal
                                    </div>
                                    <div class="col-8 px-0 pl-3 py-2 text-left box-height c-darkgray ideal-acceptable-box-height">
                                        <ul class="list-inline list-parent text-left mb-0" >
                                            <li class="list-inline-item" *ngFor="let drinkingItem of item.managedby.ideal">
                                                {{drinkingItem}}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="row mx-0 pt-3" *ngIf="item.managedby.importance != null">
                                    <div class="col-4 px-0 pl-3 pt-2 opensans-light c-darkgray text-left">
                                        Acceptable
                                    </div>
                                    <div class="col-8 px-0 pl-3 py-2 text-left box-height c-darkgray ideal-acceptable-box-height">
                                        <ul class="list-inline list-parent text-left mb-0" >
                                            <li class="list-inline-item" *ngFor="let drinkingItem of item.managedby.acceptable">
                                                {{drinkingItem}}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <!-- <div class="row mx-0 pt-3">
                            <div class="col-12 px-0">
                                <span class="oswald body-text c-darkgray float-left pt-2 pl-2">Profiles with photos only</span>
                                <div [ngClass]="item.withPhotos.strict ? 'strict-button-on-class c-white' : 'strict-button-off-class c-graycolor'" class="float-right strict-button">STRICT FILTER
                                    {{item.withPhotos.strict ? 'ON' : 'OFF'}}
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
                
                <hr style="border-top: 1px solid #cacaca;">

                <!-- Additional filters under Express Connect -->
                <div class="row mx-0 pt-2">
                    <div class="col-12 px-0">
                        <span class="oswald subheading c-darkgray float-left pt-2">Additional filters under Express Connect</span>
                        <button mat-button class="float-right edit-button-class" (click)="navigateToUserview()">EDIT</button>
                    </div>
                    <div class="col-12 px-0">
                        <!-- Degree(s) -->
                        <div class="row mx-0 pt-4">
                            <div class="col-12 px-0">
                                <span class="oswald body-text c-darkgray float-left pt-2 pl-2">Degree(s)</span>
                                <div [ngClass]="item.degrees.strict ? 'strict-button-on-class c-white' : 'strict-button-off-class c-graycolor'" class="float-right strict-button">STRICT FILTER
                                    {{item.degrees.strict ? 'ON' : 'OFF'}}
                                </div>
                            </div>
                        </div>
                        <div class="row mx-0">
                            <div class="col-12 px-0">
                                <div class="row mx-0 pt-3" >
                                    <div class="col-4 px-0 pl-3 opensans-light c-darkgray text-left pt-1">
                                        Importance
                                    </div>
                                    <div class="col-8 px-0 text-left font-class" *ngIf="item.degrees.importance != null">
                                        <img class="pr-2" src="/assets/images/static/importance-{{item.degrees.importance}}-icon.svg" alt="">
                                        <span class="position-absolute" style="left: 51px;top: 4px;">{{item.degrees.importance}}</span>
                                        <span *ngIf="item.degrees.importance == 100">Strictly match</span>
                                        <span *ngIf="item.degrees.importance == 75">Very important</span>
                                        <span *ngIf="item.degrees.importance == 50">Somewhat important</span>
                                        <span *ngIf="item.degrees.importance == 25">Not important</span>
                                    </div>
                                    <div class="col-8 px-0 text-left font-class" *ngIf="item.degrees.importance == null">
                                        <div class="p-1 unspecified-class"><span style="padding-left: 90px;">Unspecified</span></div>
                                        <span class="position-absolute" style="left: 65px;top: 5px;">0</span>
                                    </div>
                                </div>
                                <div class="row mx-0 pt-3" *ngIf="item.degrees.importance != null">
                                    <div class="col-4 px-0 pl-3 pt-2 opensans-light c-darkgray text-left">
                                        Ideal
                                    </div>
                                    <div class="col-8 px-0 pl-3 py-2 text-left box-height c-darkgray ideal-acceptable-box-height">
                                        <ul class="list-inline list-parent text-left mb-0" >
                                            <li class="list-inline-item" *ngFor="let degreesItem of item.degrees.ideal">
                                                {{degreesItem}}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="row mx-0 pt-3" *ngIf="item.degrees.importance != null">
                                    <div class="col-4 px-0 pl-3 pt-2 opensans-light c-darkgray text-left">
                                        Acceptable
                                    </div>
                                    <div class="col-8 px-0 pl-3 py-2 text-left box-height c-darkgray ideal-acceptable-box-height">
                                        <ul class="list-inline list-parent text-left mb-0" >
                                            <li class="list-inline-item" *ngFor="let degreesItem of item.degrees.acceptable">
                                                {{degreesItem}}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Occupation -->
                        <div class="row mx-0 pt-4">
                            <div class="col-12 px-0">
                                <span class="oswald body-text c-darkgray float-left pt-2 pl-2">Occupation</span>
                                <div [ngClass]="item.occupation.strict ? 'strict-button-on-class c-white' : 'strict-button-off-class c-graycolor'" class="float-right strict-button">STRICT FILTER
                                    {{item.occupation.strict ? 'ON' : 'OFF'}}
                                </div>
                            </div>
                        </div>
                        <div class="row mx-0">
                            <div class="col-12 px-0">
                                <div class="row mx-0 pt-3" >
                                    <div class="col-4 px-0 pl-3 opensans-light c-darkgray text-left pt-1">
                                        Importance
                                    </div>
                                    <div class="col-8 px-0 text-left font-class" *ngIf="item.occupation.importance != null">
                                        <img class="pr-2" src="/assets/images/static/importance-{{item.occupation.importance}}-icon.svg" alt="">
                                        <span class="position-absolute" style="left: 51px;top: 4px;">{{item.occupation.importance}}</span>
                                        <span *ngIf="item.occupation.importance == 100">Strictly match</span>
                                        <span *ngIf="item.occupation.importance == 75">Very important</span>
                                        <span *ngIf="item.occupation.importance == 50">Somewhat important</span>
                                        <span *ngIf="item.occupation.importance == 25">Not important</span>
                                    </div>
                                    <div class="col-8 px-0 text-left font-class" *ngIf="item.occupation.importance == null">
                                        <div class="p-1 unspecified-class"><span style="padding-left: 90px;">Unspecified</span></div>
                                        <span class="position-absolute" style="left: 65px;top: 5px;">0</span>
                                    </div>
                                </div>
                                <div class="row mx-0 pt-3" *ngIf="item.occupation.importance != null">
                                    <div class="col-4 px-0 pl-3 pt-2 opensans-light c-darkgray text-left">
                                        Ideal
                                    </div>
                                    <div class="col-8 px-0 pl-3 py-2 text-left box-height c-darkgray ideal-acceptable-box-height">
                                        <ul class="list-inline list-parent text-left mb-0" >
                                            <li class="list-inline-item" *ngFor="let occupationItem of item.occupation.ideal">
                                                {{occupationItem}}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="row mx-0 pt-3" *ngIf="item.occupation.importance != null">
                                    <div class="col-4 px-0 pl-3 pt-2 opensans-light c-darkgray text-left">
                                        Acceptable
                                    </div>
                                    <div class="col-8 px-0 pl-3 py-2 text-left box-height c-darkgray ideal-acceptable-box-height">
                                        <ul class="list-inline list-parent text-left mb-0" >
                                            <li class="list-inline-item" *ngFor="let occupationItem of item.occupation.acceptable">
                                                {{occupationItem}}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- socioEconomic -->
                        <div class="row mx-0 pt-4">
                            <div class="col-12 px-0">
                                <span class="oswald body-text c-darkgray float-left pt-2 pl-2">Socio-economic status</span>
                                <div [ngClass]="item.socioEconomic.strict ? 'strict-button-on-class c-white' : 'strict-button-off-class c-graycolor'" class="float-right strict-button">STRICT FILTER
                                    {{item.socioEconomic.strict ? 'ON' : 'OFF'}}
                                </div>
                            </div>
                        </div>
                        <div class="row mx-0">
                            <div class="col-12 px-0">
                                <div class="row mx-0 pt-3" >
                                    <div class="col-4 px-0 pl-3 opensans-light c-darkgray text-left pt-1">
                                        Importance
                                    </div>
                                    <div class="col-8 px-0 text-left font-class" *ngIf="item.socioEconomic.importance != null">
                                        <img class="pr-2" src="/assets/images/static/importance-{{item.socioEconomic.importance}}-icon.svg" alt="">
                                        <span class="position-absolute" style="left: 51px;top: 4px;">{{item.socioEconomic.importance}}</span>
                                       
                                        <span *ngIf="item.socioEconomic.importance == 100">Strictly match</span>
                                        <span *ngIf="item.socioEconomic.importance == 75">Very important</span>
                                        <span *ngIf="item.socioEconomic.importance == 50">Somewhat important</span>
                                        <span *ngIf="item.socioEconomic.importance == 25">Not important</span>
                                    </div>
                                    <div class="col-8 px-0 text-left font-class" *ngIf="item.socioEconomic.importance == null">
                                        <div class="p-1 unspecified-class"><span style="padding-left: 90px;">Unspecified</span></div>
                                        <span class="position-absolute" style="left: 65px;top: 5px;">0</span>
                                    </div>
                                </div>
                                <div class="row mx-0 pt-3" *ngIf="item.socioEconomic.importance != null">
                                    <div class="col-4 px-0 pl-3 pt-2 opensans-light c-darkgray text-left">
                                        Ideal
                                    </div>
                                    <div class="col-8 px-0 pl-3 py-2 text-left box-height c-darkgray ideal-acceptable-box-height">
                                        <ul class="list-inline list-parent text-left mb-0" >
                                            <li class="list-inline-item" *ngFor="let socioEconomicItem of item.socioEconomic.ideal">
                                                {{socioEconomicItem}}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="row mx-0 pt-3" *ngIf="item.socioEconomic.importance != null">
                                    <div class="col-4 px-0 pl-3 pt-2 opensans-light c-darkgray text-left">
                                        Acceptable
                                    </div>
                                    <div class="col-8 px-0 pl-3 py-2 text-left box-height c-darkgray ideal-acceptable-box-height">
                                        <ul class="list-inline list-parent text-left mb-0" >
                                            <li class="list-inline-item" *ngFor="let socioEconomicItem of item.socioEconomic.acceptable">
                                                {{socioEconomicItem}}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <hr class="pt-3" style="border-top: 1px solid #cacaca;">
                <!-- Basics -->
                <div class="row mx-0 pt-2">
                    <div class="col-12 px-0">
                        <span class="oswald subheading c-darkgray float-left pt-2">Basics</span>
                        <button mat-button class="float-right edit-button-class" (click)="navigateToUserview()">EDIT</button>
                    </div>
                    <div class="col-12 px-0">
                        <!-- Has children -->
                        <div class="row mx-0 pt-4">
                            <div class="col-12 px-0">
                                <span class="oswald body-text c-darkgray float-left pt-2 pl-2">Has children</span>
                            </div>
                        </div>
                        <div class="row mx-0">
                            <div class="col-12 px-0">
                                <div class="row mx-0 pt-3" >
                                    <div class="col-4 px-0 pl-3 opensans-light c-darkgray text-left pt-1">
                                        Importance
                                    </div>
                                    <div class="col-8 px-0 text-left font-class" *ngIf="item.hasChildren.importance != null">
                                        <img class="pr-2" src="/assets/images/static/importance-{{item.hasChildren.importance}}-icon.svg" alt="">
                                        <span class="position-absolute" style="left: 51px;top: 4px;">{{item.hasChildren.importance}}</span>
                                       
                                        <span *ngIf="item.hasChildren.importance == 100">Strictly match</span>
                                        <span *ngIf="item.hasChildren.importance == 75">Very important</span>
                                        <span *ngIf="item.hasChildren.importance == 50">Somewhat important</span>
                                        <span *ngIf="item.hasChildren.importance == 25">Not important</span>
                                    </div>
                                    <div class="col-8 px-0 text-left font-class" *ngIf="item.hasChildren.importance == null">
                                        <div class="p-1 unspecified-class"><span style="padding-left: 90px;">Unspecified</span></div>
                                        <span class="position-absolute" style="left: 65px;top: 5px;">0</span>
                                    </div>
                                </div>
                                <div class="row mx-0 pt-3" *ngIf="item.hasChildren.importance != null">
                                    <div class="col-4 px-0 pl-3 pt-2 opensans-light c-darkgray text-left">
                                        Ideal
                                    </div>
                                    <div class="col-8 px-0 pl-3 py-2 text-left box-height c-darkgray ideal-acceptable-box-height">
                                        <ul class="list-inline list-parent text-left mb-0" >
                                            <li class="list-inline-item" *ngFor="let hasChildrenItem of item.hasChildren.ideal">
                                                {{hasChildrenItem}}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="row mx-0 pt-3" *ngIf="item.hasChildren.importance != null">
                                    <div class="col-4 px-0 pl-3 pt-2 opensans-light c-darkgray text-left">
                                        Acceptable
                                    </div>
                                    <div class="col-8 px-0 pl-3 py-2 text-left box-height c-darkgray ideal-acceptable-box-height">
                                        <ul class="list-inline list-parent text-left mb-0" >
                                            <li class="list-inline-item" *ngFor="let hasChildrenItem of item.hasChildren.acceptable">
                                                {{hasChildrenItem}}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Wants more children -->
                        <div class="row mx-0 pt-4">
                            <div class="col-12 px-0">
                                <span class="oswald body-text c-darkgray float-left pt-2 pl-2">Wants more children</span>
                            </div>
                        </div>
                        <div class="row mx-0">
                            <div class="col-12 px-0">
                                <div class="row mx-0 pt-3" >
                                    <div class="col-4 px-0 pl-3 opensans-light c-darkgray text-left pt-1">
                                        Importance
                                    </div>
                                    <div class="col-8 px-0 text-left font-class" *ngIf="item.wantMoreChildren.importance != null">
                                        <img class="pr-2" src="/assets/images/static/importance-{{item.wantMoreChildren.importance}}-icon.svg" alt="">
                                        <span class="position-absolute" style="left: 51px;top: 4px;">{{item.wantMoreChildren.importance}}</span>
                                       
                                        <span *ngIf="item.wantMoreChildren.importance == 100">Strictly match</span>
                                        <span *ngIf="item.wantMoreChildren.importance == 75">Very important</span>
                                        <span *ngIf="item.wantMoreChildren.importance == 50">Somewhat important</span>
                                        <span *ngIf="item.wantMoreChildren.importance == 25">Not important</span>
                                    </div>
                                    <div class="col-8 px-0 text-left font-class" *ngIf="item.wantMoreChildren.importance == null">
                                        <div class="p-1 unspecified-class"><span style="padding-left: 90px;">Unspecified</span></div>
                                        <span class="position-absolute" style="left: 65px;top: 5px;">0</span>
                                    </div>
                                </div>
                                <div class="row mx-0 pt-3" *ngIf="item.wantMoreChildren.importance != null">
                                    <div class="col-4 px-0 pl-3 pt-2 opensans-light c-darkgray text-left">
                                        Ideal
                                    </div>
                                    <div class="col-8 px-0 pl-3 py-2 text-left box-height c-darkgray ideal-acceptable-box-height">
                                        <ul class="list-inline list-parent text-left mb-0" >
                                            <li class="list-inline-item" *ngFor="let wantMoreChildrenItem of item.wantMoreChildren.ideal">
                                                {{wantMoreChildrenItem}}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="row mx-0 pt-3" *ngIf="item.wantMoreChildren.importance != null">
                                    <div class="col-4 px-0 pl-3 pt-2 opensans-light c-darkgray text-left">
                                        Acceptable
                                    </div>
                                    <div class="col-8 px-0 pl-3 py-2 text-left box-height c-darkgray ideal-acceptable-box-height">
                                        <ul class="list-inline list-parent text-left mb-0" >
                                            <li class="list-inline-item" *ngFor="let wantMoreChildrenItem of item.wantMoreChildren.acceptable">
                                                {{wantMoreChildrenItem}}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <hr class="pt-3" style="border-top: 1px solid #cacaca;">
                <!-- Appearance -->
                <div class="row mx-0 pt-2">
                    <div class="col-12 px-0">
                        <span class="oswald subheading c-darkgray float-left pt-2">Appearance</span>
                        <button mat-button class="float-right edit-button-class" (click)="navigateToUserview()">EDIT</button>
                    </div>
                    <div class="col-12 px-0">
                        <!-- Body type -->
                        <div class="row mx-0 pt-3">
                            <div class="col-12 px-0">
                                <span class="oswald body-text c-darkgray float-left pt-2 pl-2">Body type</span>
                            </div>
                        </div>
                        <div class="row mx-0">
                            <div class="col-12 px-0">
                                <div class="row mx-0 pt-3" >
                                    <div class="col-4 px-0 pl-3 opensans-light c-darkgray text-left pt-1">
                                        Importance
                                    </div>
                                    <div class="col-8 px-0 text-left font-class" *ngIf="item.bodyType.importance != null">
                                        <img class="pr-2" src="/assets/images/static/importance-{{item.bodyType.importance}}-icon.svg" alt="">
                                        <span class="position-absolute" style="left: 51px;top: 4px;">{{item.bodyType.importance}}</span>
                                        <span *ngIf="item.bodyType.importance == 100">Strictly match</span>
                                        <span *ngIf="item.bodyType.importance == 75">Very important</span>
                                        <span *ngIf="item.bodyType.importance == 50">Somewhat important</span>
                                        <span *ngIf="item.bodyType.importance == 25">Not important</span>
                                    </div>
                                    <div class="col-8 px-0 text-left font-class" *ngIf="item.bodyType.importance == null">
                                        <div class="p-1 unspecified-class"><span style="padding-left: 90px;">Unspecified</span></div>
                                        <span class="position-absolute" style="left: 65px;top: 5px;">0</span>
                                    </div>
                                </div>
                                <div class="row mx-0 pt-3" *ngIf="item.bodyType.importance != null">
                                    <div class="col-4 px-0 pl-3 pt-2 opensans-light c-darkgray text-left">
                                        Ideal
                                    </div>
                                    <div class="col-8 px-0 pl-3 py-2 text-left box-height c-darkgray ideal-acceptable-box-height">
                                        <ul class="list-inline list-parent text-left mb-0" >
                                            <li class="list-inline-item" *ngFor="let bodyTypeItem of item.bodyType.ideal">
                                                {{bodyTypeItem}}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="row mx-0 pt-3" *ngIf="item.bodyType.importance != null">
                                    <div class="col-4 px-0 pl-3 pt-2 opensans-light c-darkgray text-left">
                                        Acceptable
                                    </div>
                                    <div class="col-8 px-0 pl-3 py-2 text-left box-height c-darkgray ideal-acceptable-box-height">
                                        <ul class="list-inline list-parent text-left mb-0" >
                                            <li class="list-inline-item" *ngFor="let bodyTypeItem of item.bodyType.acceptable">
                                                {{bodyTypeItem}}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Body piercings -->
                        <div class="row mx-0 pt-3">
                            <div class="col-12 px-0">
                                <span class="oswald body-text c-darkgray float-left pt-2 pl-2">Body piercings</span>
                            </div>
                        </div>
                        <div class="row mx-0">
                            <div class="col-12 px-0">
                                <div class="row mx-0 pt-3" >
                                    <div class="col-4 px-0 pl-3 opensans-light c-darkgray text-left pt-1">
                                        Importance
                                    </div>
                                    <div class="col-8 px-0 text-left font-class" *ngIf="item.bodyPiercings.importance != null">
                                        <img class="pr-2" src="/assets/images/static/importance-{{item.bodyPiercings.importance}}-icon.svg" alt="">
                                        <span class="position-absolute" style="left: 51px;top: 4px;">{{item.bodyPiercings.importance}}</span>
                                        <span *ngIf="item.bodyPiercings.importance == 100">Strictly match</span>
                                        <span *ngIf="item.bodyPiercings.importance == 75">Very important</span>
                                        <span *ngIf="item.bodyPiercings.importance == 50">Somewhat important</span>
                                        <span *ngIf="item.bodyPiercings.importance == 25">Not important</span>
                                    </div>
                                    <div class="col-8 px-0 text-left font-class" *ngIf="item.bodyPiercings.importance == null">
                                        <div class="p-1 unspecified-class"><span style="padding-left: 90px;">Unspecified</span></div>
                                        <span class="position-absolute" style="left: 65px;top: 5px;">0</span>
                                    </div>
                                </div>
                                <div class="row mx-0 pt-3" *ngIf="item.bodyPiercings.importance != null">
                                    <div class="col-4 px-0 pl-3 pt-2 opensans-light c-darkgray text-left">
                                        Ideal
                                    </div>
                                    <div class="col-8 px-0 pl-3 py-2 text-left box-height c-darkgray ideal-acceptable-box-height">
                                        <ul class="list-inline list-parent text-left mb-0" >
                                            <li class="list-inline-item" *ngFor="let bodyPiercingsItem of item.bodyPiercings.ideal">
                                                {{bodyPiercingsItem}}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="row mx-0 pt-3" *ngIf="item.bodyPiercings.importance != null">
                                    <div class="col-4 px-0 pl-3 pt-2 opensans-light c-darkgray text-left">
                                        Acceptable
                                    </div>
                                    <div class="col-8 px-0 pl-3 py-2 text-left box-height c-darkgray ideal-acceptable-box-height">
                                        <ul class="list-inline list-parent text-left mb-0" >
                                            <li class="list-inline-item" *ngFor="let bodyPiercingsItem of item.bodyPiercings.acceptable">
                                                {{bodyPiercingsItem}}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Tattoos -->
                        <div class="row mx-0 pt-3">
                            <div class="col-12 px-0">
                                <span class="oswald body-text c-darkgray float-left pt-2 pl-2">Tattoos</span>
                            </div>
                        </div>
                        <div class="row mx-0">
                            <div class="col-12 px-0">
                                <div class="row mx-0 pt-3" >
                                    <div class="col-4 px-0 pl-3 opensans-light c-darkgray text-left pt-1">
                                        Importance
                                    </div>
                                    <div class="col-8 px-0 text-left font-class" *ngIf="item.tattoos.importance != null">
                                        <img class="pr-2" src="/assets/images/static/importance-{{item.tattoos.importance}}-icon.svg" alt="">
                                        <span class="position-absolute" style="left: 51px;top: 4px;">{{item.tattoos.importance}}</span>
                                        <span *ngIf="item.tattoos.importance == 100">Strictly match</span>
                                        <span *ngIf="item.tattoos.importance == 75">Very important</span>
                                        <span *ngIf="item.tattoos.importance == 50">Somewhat important</span>
                                        <span *ngIf="item.tattoos.importance == 25">Not important</span>
                                    </div>
                                    <div class="col-8 px-0 text-left font-class" *ngIf="item.tattoos.importance == null">
                                        <div class="p-1 unspecified-class"><span style="padding-left: 90px;">Unspecified</span></div>
                                        <span class="position-absolute" style="left: 65px;top: 5px;">0</span>
                                    </div>
                                </div>
                                <div class="row mx-0 pt-3" *ngIf="item.tattoos.importance != null">
                                    <div class="col-4 px-0 pl-3 pt-2 opensans-light c-darkgray text-left">
                                        Ideal
                                    </div>
                                    <div class="col-8 px-0 pl-3 py-2 text-left box-height c-darkgray ideal-acceptable-box-height">
                                        <ul class="list-inline list-parent text-left mb-0" >
                                            <li class="list-inline-item" *ngFor="let tattoosItem of item.tattoos.ideal">
                                                {{tattoosItem}}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="row mx-0 pt-3" *ngIf="item.tattoos.importance != null">
                                    <div class="col-4 px-0 pl-3 pt-2 opensans-light c-darkgray text-left">
                                        Acceptable
                                    </div>
                                    <div class="col-8 px-0 pl-3 py-2 text-left box-height c-darkgray ideal-acceptable-box-height">
                                        <ul class="list-inline list-parent text-left mb-0" >
                                            <li class="list-inline-item" *ngFor="let tattoosItem of item.tattoos.acceptable">
                                                {{tattoosItem}}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <hr class="pt-3" style="border-top: 1px solid #cacaca;">
                <!-- Socio-cultural background -->
                <div class="row mx-0 pt-2">
                    <div class="col-12 px-0">
                        <span class="oswald subheading c-darkgray float-left pt-2">Socio-cultural background</span>
                        <button mat-button class="float-right edit-button-class" (click)="navigateToUserview()">EDIT</button>
                    </div>  
                    <div class="col-12 px-0">
                        <!-- Community/caste -->
                        <div class="row mx-0 pt-3">
                            <div class="col-12 px-0">
                                <span class="oswald body-text c-darkgray float-left pt-2 pl-2">Community/caste</span>
                            </div>
                        </div>
                        <div class="row mx-0">
                                <div class="col-12 px-0">
                                    <div class="row mx-0 pt-3" >
                                        <div class="col-4 px-0 pl-3 opensans-light c-darkgray text-left pt-1">
                                            Importance
                                        </div>
                                        <div class="col-8 px-0 text-left font-class" *ngIf="item.communityCaste.importance != null">
                                            <img class="pr-2" src="/assets/images/static/importance-{{item.communityCaste.importance}}-icon.svg" alt="">
                                            <span class="position-absolute" style="left: 51px;top: 4px;">{{item.communityCaste.importance}}</span>
                                            <span *ngIf="item.communityCaste.importance == 100">Strictly match</span>
                                            <span *ngIf="item.communityCaste.importance == 75">Very important</span>
                                            <span *ngIf="item.communityCaste.importance == 50">Somewhat important</span>
                                            <span *ngIf="item.communityCaste.importance == 25">Not important</span>
                                        </div>
                                        <div class="col-8 px-0 text-left font-class" *ngIf="item.communityCaste.importance == null">
                                            <div class="p-1 unspecified-class"><span style="padding-left: 90px;">Unspecified</span></div>
                                            <span class="position-absolute" style="left: 65px;top: 5px;">0</span>
                                        </div>
                                    </div>
                                    <div class="row mx-0 pt-3" *ngIf="item.communityCaste.importance != null">
                                        <div class="col-4 px-0 pl-3 pt-2 opensans-light c-darkgray text-left">
                                            Ideal
                                        </div>
                                        <div class="col-8 px-0 pl-3 py-2 text-left box-height c-darkgray ideal-acceptable-box-height">
                                            <ul class="list-inline list-parent text-left mb-0" >
                                                <li class="list-inline-item" *ngFor="let communityCasteItem of item.communityCaste.ideal">
                                                    {{communityCasteItem}}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="row mx-0 pt-3" *ngIf="item.communityCaste.importance != null">
                                        <div class="col-4 px-0 pl-3 pt-2 opensans-light c-darkgray text-left">
                                            Acceptable
                                        </div>
                                        <div class="col-8 px-0 pl-3 py-2 text-left box-height c-darkgray ideal-acceptable-box-height">
                                            <ul class="list-inline list-parent text-left mb-0" >
                                                <li class="list-inline-item" *ngFor="let communityCasteItem of item.communityCaste.acceptable">
                                                    {{communityCasteItem}}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                        </div>

                        <!-- Ethnic/national origin -->
                        <div class="row mx-0 pt-3">
                            <div class="col-12 px-0">
                                <span class="oswald body-text c-darkgray float-left pt-2 pl-2">Ethnic/national origin</span>
                            </div>
                        </div>
                        <div class="row mx-0">
                            <div class="col-12 px-0">
                                <div class="row mx-0 pt-3" >
                                    <div class="col-4 px-0 pl-3 opensans-light c-darkgray text-left pt-1">
                                        Importance
                                    </div>
                                    <div class="col-8 px-0 text-left font-class" *ngIf="item.ethnicNationalOrigin.importance != null">
                                        <img class="pr-2" src="/assets/images/static/importance-{{item.ethnicNationalOrigin.importance}}-icon.svg" alt="">
                                        <span class="position-absolute" style="left: 51px;top: 4px;">{{item.ethnicNationalOrigin.importance}}</span>
                                        <span *ngIf="item.ethnicNationalOrigin.importance == 100">Strictly match</span>
                                        <span *ngIf="item.ethnicNationalOrigin.importance == 75">Very important</span>
                                        <span *ngIf="item.ethnicNationalOrigin.importance == 50">Somewhat important</span>
                                        <span *ngIf="item.ethnicNationalOrigin.importance == 25">Not important</span>
                                    </div>
                                    <div class="col-8 px-0 text-left font-class" *ngIf="item.ethnicNationalOrigin.importance == null">
                                        <div class="p-1 unspecified-class"><span style="padding-left: 90px;">Unspecified</span></div>
                                        <span class="position-absolute" style="left: 65px;top: 5px;">0</span>
                                    </div>
                                </div>
                                <div class="row mx-0 pt-3" *ngIf="item.ethnicNationalOrigin.importance != null">
                                    <div class="col-4 px-0 pl-3 pt-2 opensans-light c-darkgray text-left">
                                        Ideal
                                    </div>
                                    <div class="col-8 px-0 pl-3 py-2 text-left box-height c-darkgray ideal-acceptable-box-height">
                                        <ul class="list-inline list-parent text-left mb-0" >
                                            <li class="list-inline-item" *ngFor="let ethnicNationalOriginItem of item.ethnicNationalOrigin.ideal">
                                                {{ethnicNationalOriginItem}}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="row mx-0 pt-3" *ngIf="item.ethnicNationalOrigin.importance != null">
                                    <div class="col-4 px-0 pl-3 pt-2 opensans-light c-darkgray text-left">
                                        Acceptable
                                    </div>
                                    <div class="col-8 px-0 pl-3 py-2 text-left box-height c-darkgray ideal-acceptable-box-height">
                                        <ul class="list-inline list-parent text-left mb-0" >
                                            <li class="list-inline-item" *ngFor="let ethnicNationalOriginItem of item.ethnicNationalOrigin.acceptable">
                                                {{ethnicNationalOriginItem}}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Horoscope matching -->
                        <div class="row mx-0 pt-3">
                            <div class="col-12 px-0">
                                <span class="oswald body-text c-darkgray float-left pt-2 pl-2">Horoscope matching</span>
                            </div>
                        </div>
                        <div class="row mx-0">
                            <div class="col-12 px-0">
                                <div class="row mx-0 pt-3" >
                                    <div class="col-4 px-0 pl-3 opensans-light c-darkgray text-left pt-1">
                                        Importance
                                    </div>
                                    <div class="col-8 px-0 text-left font-class" *ngIf="item.horoscopeMatching.importance != null">
                                        <img class="pr-2" src="/assets/images/static/importance-{{item.horoscopeMatching.importance}}-icon.svg" alt="">
                                        <span class="position-absolute" style="left: 51px;top: 4px;">{{item.horoscopeMatching.importance}}</span>
                                        <span *ngIf="item.horoscopeMatching.importance == 100">Strictly match</span>
                                        <span *ngIf="item.horoscopeMatching.importance == 75">Very important</span>
                                        <span *ngIf="item.horoscopeMatching.importance == 50">Somewhat important</span>
                                        <span *ngIf="item.horoscopeMatching.importance == 25">Not important</span>
                                    </div>
                                    <div class="col-8 px-0 text-left font-class" *ngIf="item.horoscopeMatching.importance == null">
                                        <div class="p-1 unspecified-class"><span style="padding-left: 90px;">Unspecified</span></div>
                                        <span class="position-absolute" style="left: 65px;top: 5px;">0</span>
                                    </div>
                                </div>
                                <div class="row mx-0 pt-3" *ngIf="item.horoscopeMatching.importance != null">
                                    <div class="col-4 px-0 pl-3 pt-2 opensans-light c-darkgray text-left">
                                        Ideal
                                    </div>
                                    <div class="col-8 px-0 pl-3 py-2 text-left box-height c-darkgray ideal-acceptable-box-height">
                                        <ul class="list-inline list-parent text-left mb-0" >
                                            <li class="list-inline-item" *ngFor="let horoscopeMatchingItem of item.horoscopeMatching.ideal">
                                                {{horoscopeMatchingItem}}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="row mx-0 pt-3" *ngIf="item.horoscopeMatching.importance != null">
                                    <div class="col-4 px-0 pl-3 pt-2 opensans-light c-darkgray text-left">
                                        Acceptable
                                    </div>
                                    <div class="col-8 px-0 pl-3 py-2 text-left box-height c-darkgray ideal-acceptable-box-height">
                                        <ul class="list-inline list-parent text-left mb-0" >
                                            <li class="list-inline-item" *ngFor="let horoscopeMatchingItem of item.horoscopeMatching.acceptable">
                                                {{horoscopeMatchingItem}}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Grew up in -->
                        <div class="row mx-0 pt-3">
                            <div class="col-12 px-0">
                                <span class="oswald body-text c-darkgray float-left pt-2 pl-2">Grew up in</span>
                            </div>
                        </div>
                        <div class="row mx-0">
                            <div class="col-12 px-0">
                                <div class="row mx-0 pt-3" >
                                    <div class="col-4 px-0 pl-3 opensans-light c-darkgray text-left pt-1">
                                        Importance
                                    </div>
                                    <div class="col-8 px-0 text-left font-class" *ngIf="item.grewUpIn.importance != null">
                                        <img class="pr-2" src="/assets/images/static/importance-{{item.grewUpIn.importance}}-icon.svg" alt="">
                                        <span class="position-absolute" style="left: 51px;top: 4px;">{{item.grewUpIn.importance}}</span>
                                        <span *ngIf="item.grewUpIn.importance == 100">Strictly match</span>
                                        <span *ngIf="item.grewUpIn.importance == 75">Very important</span>
                                        <span *ngIf="item.grewUpIn.importance == 50">Somewhat important</span>
                                        <span *ngIf="item.grewUpIn.importance == 25">Not important</span>
                                    </div>
                                    <div class="col-8 px-0 text-left font-class" *ngIf="item.grewUpIn.importance == null">
                                        <div class="p-1 unspecified-class"><span style="padding-left: 90px;">Unspecified</span></div>
                                        <span class="position-absolute" style="left: 65px;top: 5px;">0</span>
                                    </div>
                                </div>
                                <div class="row mx-0 pt-3" *ngIf="item.grewUpIn.importance != null">
                                    <div class="col-4 px-0 pl-3 pt-2 opensans-light c-darkgray text-left">
                                        Ideal
                                    </div>
                                    <div class="col-8 px-0 pl-3 py-2 text-left box-height c-darkgray ideal-acceptable-box-height">
                                        <ul class="list-inline list-parent text-left mb-0" >
                                            <li class="list-inline-item" *ngFor="let grewUpInItem of item.grewUpIn.ideal">
                                                {{grewUpInItem}}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="row mx-0 pt-3" *ngIf="item.grewUpIn.importance != null">
                                    <div class="col-4 px-0 pl-3 pt-2 opensans-light c-darkgray text-left">
                                        Acceptable
                                    </div>
                                    <div class="col-8 px-0 pl-3 py-2 text-left box-height c-darkgray ideal-acceptable-box-height">
                                        <ul class="list-inline list-parent text-left mb-0" >
                                            <li class="list-inline-item" *ngFor="let grewUpInItem of item.grewUpIn.acceptable">
                                                {{grewUpInItem}}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <hr class="pt-3" style="border-top: 1px solid #cacaca;">
                <!-- Lifestyle -->
                <div class="row mx-0 pt-2">
                    <div class="col-12 px-0">
                        <span class="oswald subheading c-darkgray float-left pt-2">Lifestyle</span>
                        <button mat-button class="float-right edit-button-class" (click)="navigateToUserview()">EDIT</button>
                    </div>  
                    <div class="col-12 px-0">
                        <!-- Dietary Preferences -->
                        <div class="row mx-0 pt-3">
                            <div class="col-12 px-0">
                                <span class="oswald body-text c-darkgray float-left pt-2 pl-2">Dietary Preferences</span>
                            </div>
                        </div>
                        <div class="row mx-0">
                            <div class="col-12 px-0">
                                <div class="row mx-0 pt-3" >
                                    <div class="col-4 px-0 pl-3 opensans-light c-darkgray text-left pt-1">
                                        Importance
                                    </div>
                                    <div class="col-8 px-0 text-left font-class" *ngIf="item.dietary.importance != null">
                                        <img class="pr-2" src="/assets/images/static/importance-{{item.dietary.importance}}-icon.svg" alt="">
                                        <span class="position-absolute" style="left: 51px;top: 4px;">{{item.dietary.importance}}</span>
                                        <span *ngIf="item.dietary.importance == 100">Strictly match</span>
                                        <span *ngIf="item.dietary.importance == 75">Very important</span>
                                        <span *ngIf="item.dietary.importance == 50">Somewhat important</span>
                                        <span *ngIf="item.dietary.importance == 25">Not important</span>
                                    </div>
                                    <div class="col-8 px-0 text-left font-class" *ngIf="item.dietary.importance == null">
                                        <div class="p-1 unspecified-class"><span style="padding-left: 90px;">Unspecified</span></div>
                                        <span class="position-absolute" style="left: 65px;top: 5px;">0</span>
                                    </div>
                                </div>
                                <div class="row mx-0 pt-3" *ngIf="item.dietary.importance != null">
                                    <div class="col-4 px-0 pl-3 pt-2 opensans-light c-darkgray text-left">
                                        Ideal
                                    </div>
                                    <div class="col-8 px-0 pl-3 py-2 text-left box-height c-darkgray ideal-acceptable-box-height">
                                        <ul class="list-inline list-parent text-left mb-0" >
                                            <li class="list-inline-item" *ngFor="let dietaryItem of item.dietary.ideal">
                                                {{dietaryItem}}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="row mx-0 pt-3" *ngIf="item.dietary.importance != null">
                                    <div class="col-4 px-0 pl-3 pt-2 opensans-light c-darkgray text-left">
                                        Acceptable
                                    </div>
                                    <div class="col-8 px-0 pl-3 py-2 text-left box-height c-darkgray ideal-acceptable-box-height">
                                        <ul class="list-inline list-parent text-left mb-0" >
                                            <li class="list-inline-item" *ngFor="let dietaryItem of item.dietary.acceptable">
                                                {{dietaryItem}}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Exercise Habits -->
                        <div class="row mx-0 pt-3">
                            <div class="col-12 px-0">
                                <span class="oswald body-text c-darkgray float-left pt-2 pl-2">Exercise Habits</span>
                            </div>
                        </div>
                        <div class="row mx-0">
                            <div class="col-12 px-0 pb-3">
                                <div class="row mx-0 pt-3" >
                                    <div class="col-4 px-0 pl-3 opensans-light c-darkgray text-left pt-1">
                                        Importance
                                    </div>
                                    <div class="col-8 px-0 text-left font-class" *ngIf="item.exerciseHabits.importance != null">
                                        <img class="pr-2" src="/assets/images/static/importance-{{item.exerciseHabits.importance}}-icon.svg" alt="">
                                        <span class="position-absolute" style="left: 51px;top: 4px;">{{item.exerciseHabits.importance}}</span>
                                        <span *ngIf="item.exerciseHabits.importance == 100">Strictly match</span>
                                        <span *ngIf="item.exerciseHabits.importance == 75">Very important</span>
                                        <span *ngIf="item.exerciseHabits.importance == 50">Somewhat important</span>
                                        <span *ngIf="item.exerciseHabits.importance == 25">Not important</span>
                                    </div>
                                    <div class="col-8 px-0 text-left font-class" *ngIf="item.exerciseHabits.importance == null">
                                        <div class="p-1 unspecified-class"><span style="padding-left: 90px;">Unspecified</span></div>
                                        <span class="position-absolute" style="left: 65px;top: 5px;">0</span>
                                    </div>
                                </div>
                                <div class="row mx-0 pt-3" *ngIf="item.exerciseHabits.importance != null">
                                    <div class="col-4 px-0 pl-3 pt-2 opensans-light c-darkgray text-left">
                                        Ideal
                                    </div>
                                    <div class="col-8 px-0 pl-3 py-2 text-left box-height c-darkgray ideal-acceptable-box-height">
                                        <ul class="list-inline list-parent text-left mb-0" >
                                            <li class="list-inline-item" *ngFor="let exerciseHabitsItem of item.exerciseHabits.ideal">
                                                {{exerciseHabitsItem}}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="row mx-0 pt-3" *ngIf="item.exerciseHabits.importance != null">
                                    <div class="col-4 px-0 pl-3 pt-2 opensans-light c-darkgray text-left">
                                        Acceptable
                                    </div>
                                    <div class="col-8 px-0 pl-3 py-2 text-left box-height c-darkgray ideal-acceptable-box-height">
                                        <ul class="list-inline list-parent text-left mb-0" >
                                            <li class="list-inline-item" *ngFor="let exerciseHabitsItem of item.exerciseHabits.acceptable">
                                                {{exerciseHabitsItem}}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- skelenton -->
    <div class="col-12 px-3 pb-5" *ngIf="skelentonView">
        <div class="row mx-0 ">
            <div class="pt-4">
                <div class="row mx-0 ph-item">  
                    <div class="col-4 px-0 mat-elevation-z5 skelenton-class"></div>
                </div>
                <div class="row mx-0 mt-4 ph-item">
                    <div class="col-9 px-0 mat-elevation-z5 skelenton-class" style="height: 20px;"></div>
                </div>
                <div class="row mx-0 mt-4 ph-item">
                    <div class="col-3 px-0 mat-elevation-z5 skelenton-class-left"></div>
                    <div class="col-7 px-0 pl-5">
                        <div class="mat-elevation-z5 skelenton-class-width"></div>
                    </div>
                </div>
                <div class="row mx-0 mt-4 ph-item">
                    <div class="col-3 px-0 mat-elevation-z5 skelenton-class-left"></div>
                    <div class="col-7 px-0 pl-5">
                        <div class="mat-elevation-z5 skelenton-class-width"></div>
                    </div>
                </div>
            </div>
            <div class="pt-5">
                <div class="row mx-0 ph-item">  
                    <div class="col-4 px-0 mat-elevation-z5 skelenton-class"></div>
                </div>
                <div class="row mx-0 mt-4 ph-item">
                    <div class="col-9 px-0 mat-elevation-z5 skelenton-class" style="height: 20px;"></div>
                </div>
                <div class="row mx-0 mt-4 ph-item">
                    <div class="col-6 px-0 mat-elevation-z5 skelenton-class-left"></div>
                </div>
                <div class="row mx-0 mt-4 ph-item">
                    <div class="col-6 px-0 mat-elevation-z5 skelenton-class-left"></div>
                </div>
            </div>
            <div class="pt-5">
                <div class="row mx-0 ph-item">  
                    <div class="col-4 px-0 mat-elevation-z5 skelenton-class"></div>
                </div>
                <div class="row mx-0 mt-4 ph-item">
                    <div class="col-9 px-0 mat-elevation-z5 skelenton-class" style="height: 20px;"></div>
                </div>
                <div class="row mx-0 mt-4 ph-item">
                    <div class="col-3 px-0 mat-elevation-z5 skelenton-class-left"></div>
                    <div class="col-7 px-0 pl-5">
                        <div class="mat-elevation-z5 skelenton-class-width"></div>
                    </div>
                </div>
                <div class="row mx-0 mt-4 ph-item">
                    <div class="col-3 px-0 mat-elevation-z5 skelenton-class-left"></div>
                    <div class="col-7 px-0 pl-5">
                        <div class="mat-elevation-z5 skelenton-class-width"></div>
                    </div>
                </div>
            </div>
        </div>
       
    </div>
    
</div>