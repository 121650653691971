<div class="row mx-0 justify-content-center bg-element">
    <div class="col-7 px-0 mt-5 mb-5">
        <div class="row mx-0 mb-1" style="background-color:#ffffff;">
            <div class="col-12 px-0">
                <div class="j-h3 c-gray oswald px-4 py-3">Account Settings</div>
            </div>
        </div>
        <div class="row mx-0" style="background-color:#ffffff; min-height: 60vh;">
            <div class="col-12 px-0">
                <div class="row mx-0 my-3">
                    <div class="col-3 px-0 py-2">
                        <div class="oswald subheading text-left px-4 subheading-element">Name</div>
                    </div>
                    <div class="col-9 px-0 py-2">
                        <div class="row mx-0">
                            <div class="col-10 px-0">
                                <input  class="input-field opensans-light c-darkgray border-element" [(ngModel)]="ownDetails.firstName" type="text" autocomplete="off" readonly>
                            </div> 
                        </div>
                    </div>
                </div>
                <div class="row mx-0 my-3">
                    <div class="col-3 px-0 py-2">
                        <div class="oswald subheading text-left px-4 subheading-element">Email</div>
                    </div>
                    <div class="col-9 px-0 py-2">
                        <div class="row mx-0">
                            <div class="col-10 px-0">
                            <input  class="input-field opensans-light c-darkgray border-element" [(ngModel)]="ownDetails.email" type="text" autocomplete="off" readonly>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mx-0 my-3">
                    <div class="col-3 px-0 py-2">
                        <div class="oswald subheading text-left px-4 subheading-element">Mobile number</div>
                    </div>
                    <div class="col-9 px-0 py-2">
                        <div class="row mx-0">
                            <div class="col-10 px-0">
                            <input  class="input-field opensans-light c-darkgray border-element" [(ngModel)]="ownDetails.phoneNumber" type="text" autocomplete="off" readonly>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mx-0 my-3">
                    <div class="col-3 px-0 py-2">
                        <div class="oswald subheading text-left px-4 subheading-element">Role</div>
                    </div>
                    <div class="col-9 px-0 py-2">
                        <div class="row mx-0">
                            <div class="col-10 px-0">
                            <input  class="input-field opensans-light c-darkgray border-element" [(ngModel)]="ownDetails.role" type="text" autocomplete="off" readonly>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</div>