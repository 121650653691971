<div class="row mx-0  bg-white">
    <div class="col-12 px-0 mb-5">
        <div class="row mx-3">
            <div class="col-4 px-0 oswald subheading c-darkgray pt-4 text-left">
                Timeline
            </div>
            <div class="col-8 px-0" style="margin-top: 4%;">
                <img class="float-right clickable" height="18" src="/assets/images/static/reload-icon.svg" (click)="reloadNotesData()"  alt="">
            </div>
            
            <!-- <div class="col-12 px-0 pt-2 text-center">
                <span [ngClass] = "talkWithUser ? 'selectCheckBoxClass' : 'checkBoxClass'" (click)= "talkwithuserFunction()"></span>
                <label class="pl-4"> Did you talk with this user?</label><br>
            </div> -->
            <div class="col-12 px-0 text-center">
                <mat-checkbox class="c-btn-primary" (change)="talkwithuserFunction($event)"></mat-checkbox>
                <label class="pl-2"> Did you talk with this user?</label><br>
            </div>
            <div class="pt-3">
                <textarea [(ngModel)]="supportNotes" class="col-12 px-0 p-2 pt-4" id="written-area"  value="Clear" rows="7" cols="190" placeholder="Type message"  style="border:1px solid #979797;border-radius: 3px;"></textarea>
            </div>
            <div class="col-12 px-0 pt-2">
                <div class="row mx-0 justify-content-center">
                    <div class="col-3 px-0">
                        <button mat-flat-button [disabled]="!supportNotes" [ngClass]="!supportNotes ? 'disabledSaveNotes' : 'c-white enableSaveNotes' "  (click)="saveSupportNotesData(supportNotes)" [disabled]="disableSaveNoteButton">
                            SAVE NOTES
                        </button>
                    </div>
                </div>
            </div>

            <div class="row mx-0" *ngIf="!skelentonView">
            <div class="col-12 px-0 pt-4 text-left"  *ngFor = "let item of localUserDataNotes">
                    <img src="../../../../assets/images/static/sms-gray.svg" height="22px" width="22px" alt="">
                    <span class="pl-2 opensans-regular c-darkgray body-subtext">{{item.day}}, {{item.Time | date : 'medium'}} </span> 
                    <span class="pl-1 body-subtext">
                        <span class="subheading">By: </span>{{item.createdby}} </span>
                    <br>
                    <div class="row mx-0 ml-4 pb-3">
                        <div class="col-12 px-0 opensans-light c-darkgray small-text" style="word-wrap: break-word;" [innerHtml]="item.detail">
                            
                        </div>
                    </div>
            </div>
            <div class="col-12 px-0 pt-4 pb-3" *ngIf="showMore">
                <div class="row mx-0 justify-content-center">
                    <div class="col-12 px-0 text-center">
                        <button class="showMoreButtonBgColor" mat-button (click)="loadMoreData()">SHOW MORE</button>
                    </div>
                </div>
            </div>
            </div>

    <!-- skelenton -->
    <div class="col-12 px-3 pb-5" *ngIf="skelentonView">
        <div class="row mx-0 ">
            <div class="pt-4">
                <div class="row mx-0 ph-item">  
                    <div class="col-4 px-0 mat-elevation-z5 skelenton-class"></div>
                </div>

                <div class="row mx-0 mt-4 ph-item">
                    <div class="col-12 px-0">
                        <div class="mat-elevation-z5 skelenton-class-width"></div>
                    </div>
                </div>
              
            </div> 
        </div>
        <div class="row mx-0 ">
            <div class="pt-4">
                <div class="row mx-0 ph-item">  
                    <div class="col-4 px-0 mat-elevation-z5 skelenton-class"></div>
                </div>

                <div class="row mx-0 mt-4 ph-item">
                    <div class="col-12 px-0">
                        <div class="mat-elevation-z5 skelenton-class-width"></div>
                    </div>
                </div>
              
            </div> 
        </div>
        <div class="row mx-0 ">
            <div class="pt-4">
                <div class="row mx-0 ph-item">  
                    <div class="col-4 px-0 mat-elevation-z5 skelenton-class"></div>
                </div>

                <div class="row mx-0 mt-4 ph-item">
                    <div class="col-12 px-0">
                        <div class="mat-elevation-z5 skelenton-class-width"></div>
                    </div>
                </div>
              
            </div> 
        </div>
       
    </div>




 
    </div>
          

            
</div>



</div>