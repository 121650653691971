<div class="row mx-0 my-3" *ngIf="!screenerPointSkeleton">
    <div class="col-12 px-0 pt-2 text-center opensans-regular subheading c-darkgray">
        Total Points: {{totalScreenerPointsValue}}
    </div>
    <div class="col-12 px-0 mt-3" > 
        <div class="row mx-0">
                <ng-container *ngFor="let item of screenerPointsArrayList;let i = index">
                     <!-- matchesIconArr -->
                    <button matRipple class="col text-center px-0" [ngClass]="i === selectActiveScreener ? 'sel-matches-icon-class':'matches-icon-class'"
                        (click)="screenerPiontsList(item.passedValue, i)">
                        <span style="font-size: 14px; float:none;"
                            [ngClass]="i === selectActiveScreener ? 'sel-matches-icon-font-class':'matches-icon-font-class'">
                            {{item.text}}  <span class="c-darkgray opensans-regular ml-1 px-1" style="background-color: #fd7c72;">{{item.total * item.value}}</span>
                        </span>
                    </button>
                </ng-container>
        </div>
    </div>
    <div class="col-12 px-2 pt-3">
        <div class="row mx-0">
            <div class="col-12 px-0" *ngFor="let item of screenerPointArray;">
                <div class="row mx-0">
                    <div class="col-12 px-2 text-left">
                        <div class="oswald body-text c-darkgray pt-3" *ngIf="item.scroeType && item.scroeType != 'otherThenScreenerType'">
                            {{item.scroeType}}
                        </div>
                        <div class="opensans-light body-subtext c-darkgray pl-3 pt-3">{{item.subtext}}</div>
                        <div class="opensans-regular body-subtext c-darkgray pl-3 pt-1">{{item.redFlag}}</div> 
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- skeleton for screener points -->
<div class="row mx-0 my-4 justify-content-center ph-item" *ngIf="screenerPointSkeleton" style="height: 350px;">
    <div class="col-3 px-0 text-center screener-skelenton-class"></div>
    <div class="col-12 px-0 pt-2">
        <div class="row mx-3 justify-content-between">
            <div class="col-2 px-0 screener-skelenton-class" style="height: 30px;"></div>
            <div class="col-2 px-0 screener-skelenton-class" style="height: 30px;"></div>
            <div class="col-2 px-0 screener-skelenton-class" style="height: 30px;"></div>
            <div class="col-2 px-0 screener-skelenton-class" style="height: 30px;"></div>
            <div class="col-2 px-0 screener-skelenton-class" style="height: 30px;"></div>
        </div>
    </div>
    <div class="col-12 px-0">
        <div class="row mx-2 justify-content-start mt-3">
            <div class="col-4 px-0 pl-3 pt-3 screener-skelenton-class">
            </div>
        </div>
        <div class="row mx-4 justify-content-start mt-3">
            <div class="col-3 px-0 pl-2 pt-2 screener-skelenton-class"></div>
        </div>
        <div class="row mx-4 justify-content-start mt-3">
            <div class="col-5 px-0 pl-2 pt-2 screener-skelenton-class"></div>
        </div>
    </div>
    <div class="col-12 px-0 pt-3">
        <div class="row mx-2 justify-content-start mt-3">
            <div class="col-4 px-0 pl-3 pt-3 screener-skelenton-class">
            </div>
        </div>
        <div class="row mx-4 justify-content-start mt-3">
            <div class="col-3 px-0 pl-2 pt-2 screener-skelenton-class"></div>
        </div>
        <div class="row mx-4 justify-content-start mt-3">
            <div class="col-5 px-0 pl-2 pt-2 screener-skelenton-class"></div>
        </div>
    </div>
</div>