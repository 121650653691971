<div class="row mx-0 justify-content-center bg-color-admin">
    <div class="col-8 px-0 mt-4">
        <span class="opensans-regular c-darkgray subheading">Admin Console</span>
        <span class="float-right">
            <button mat-stroked-button class="bg-btn-primary c-white small-text" (click)="addMember('Add member')">
                ADD NEW MEMBER
            </button>
        </span>
    </div>
    <div class="col-6 px-0 mt-5">
        <table style="width: 100%; " class="bg-list-color mb-5">
            <thead style="width: 100%; background-color: #e1effb;">
                <tr>
                    <th class="oswald-regular pl-4"
                        style="width: 50%;  background-color: #e1effb; font-size: 22px; padding: 15px;">Agents</th>
                    <th class="oswald-regular j-h3 text-center"
                        style="width: 30%;  background-color: #e1effb; font-size: 22px;">Role</th>
                    <th style="width: 10%;  background-color: #e1effb;"></th>
                    <th class="" style="width: 10%;  background-color: #e1effb;"></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of memberList; let i = index;"
                    [ngClass]="item.kickoutuser === true ? 'bg-color-kicked' : '' ">
                    <th>
                        <div class="oswald-regular body-subtext pl-3">{{item.name}}</div>
                        <div class="smaller-text opensans-light pl-3">{{item.email}}</div>
                    </th>
                    <th class="small-text opensans-light text-center">{{item.issupervisor? 'Supervisor' : 'Reviewer'}}</th>
                    <th class="text-center"><span [ngClass]="item.kickoutuser === true ? '' : 'clickable' "
                            (click)="memberForm('Edit Agent Details',item.kickoutuser, i)"><img
                                src="/assets/images/static/edit-pencil-icon.svg" alt="" height="20" width="20"></span>
                    </th>
                    <th class="text-center"><span [ngClass]="item.kickoutuser === true ? '' : 'clickable' "
                            (click)="removeUser(item.kickoutuser, item.name, item.userid)"><img
                                src="/assets/images/static/Discard.svg" alt=""></span></th>
                </tr>

            </tbody>
        </table>
    </div>
</div>