import { Injectable } from '@angular/core';
import { SharedService } from './shared.service';

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {

  constructor(private sharedservice: SharedService) { }

  // setting to session (GLOBAL FUNCTION)
  setToSession(key: string, value: any): any {
    try {
      sessionStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.log('error is setting in session', error);
    }
  }

  // getting from session (GLOBAL FUNCTION)
  getFromSession(key: string): any {
    try {
      return JSON.parse(sessionStorage.getItem(key));
    } catch (error) {
      console.log('error is gettin from session', error);
    }
  }


  // removing token
  removeToken(key: string): any {
    try {
      if (key === 'user' + this.sharedservice.userId) {
        sessionStorage.clear();
      } else {
        sessionStorage.removeItem(key);
      }
    } catch (err) {
      console.log('error is removing from session', err);
    }
  }
}
