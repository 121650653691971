import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() { }

  // setting to local (GLOBAL FUNCTION)
  setToLocal(key: string, value: any): any {
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.log('error is setting in local', error);
    }
  }

  // getting from local (GLOBAL FUNCTION)
  getFromLocal(key: string): any {
    try {
      return JSON.parse(localStorage.getItem(key));
    } catch (error) {
      console.log('error is gettin from local', error);
    }
  }

  // removing token
  removeToken(key: string): any {
    try {
      if (key === 'user') {
        localStorage.clear();
      } else {
        localStorage.removeItem(key);
      }
    } catch (err) {
      console.log('error is removing from local', err);
    }
  }
}
