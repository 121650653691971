import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from 'src/app/services/local-storage.service';

@Component({
  selector: 'app-account-manage',
  templateUrl: './account-manage.component.html',
  styleUrls: ['./account-manage.component.css']
})
export class AccountManageComponent implements OnInit {

  ownDetails = {
    firstName: '',
    email: '',
    phoneNumber: 0,
    role: ''
  }

  constructor(private localService : LocalStorageService) {

    const result = localService.getFromLocal('user');
    if(result){
      this.ownDetails.firstName = result.firstname;
      this.ownDetails.email = result.email;
      this.ownDetails.phoneNumber = result.phonenumber;
      this.ownDetails.role = result.supportUserType;
      // console.log(result);
    }
    
  }

  ngOnInit(): void {
  }

}
