import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ApiService } from '../services/api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { LocalStorageService } from '../services/local-storage.service';
import { SharedService } from '../services/shared.service';
import { Router } from '@angular/router';
import { interval } from 'rxjs';
import { AuthService } from '../services/auth.service';
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  
  userEmail;
  forgotPass;
  checkEmail = false;
  timerCount = 0;
  showTimer = false;
  showResend = false;
  showContact = false;
  progressbarValue = 60;
  curSec = 0;
  awaitResponse = false;
  constructor(private fb: UntypedFormBuilder, private apiservice: ApiService, private localservice: LocalStorageService, private sharedservice: SharedService, private router: Router, private authService: AuthService) { }

  ngOnInit(): void {
    this.forgotPass = this.fb.group({
      Emailforgot: ['', [Validators.required, Validators.email, Validators.maxLength(50)]],
      howdidyouhear: ['']
    });
  }


   OnSubmitForgot() {
    // console.log(this.forgotPass);
    if (this.forgotPass.controls.Emailforgot.value) {
      this.forgotPass.patchValue({
        Emailforgot: ((this.forgotPass.controls.Emailforgot.value).trim())
      });
    }
    // console.log(this.forgotPass);
    this.userEmail = this.forgotPass.controls.Emailforgot.value;
    if ((this.forgotPass.status === 'VALID') && (!this.forgotPass.controls.howdidyouhear.value)) {
      // checking email availability in the data base // calling doesemailexist api
      this.awaitResponse = true;
          this.apiservice.forgot({ username: this.forgotPass.controls.Emailforgot.value }).subscribe(res => {
            if (res) {
              this.checkEmail = true;
              if (this.timerCount <= 1) {
                this.showTimer = true;
                this.showResend = false;
                // this.seconds = 60;
                this.setOtpTimer();
                this.timerCount++;
              } else {
                //this.showContact = true;
                this.showTimer = false;
              }
            }
          }, (err: HttpErrorResponse) => { // HttpErrorResponse for checking errors and we will show a msg to users
            this.awaitResponse = false;
            if (err.status === 401) {
              this.localservice.removeToken('user');
              this.router.navigate(['/login']);
            } else if (err.status >= 500) {
              this.authService.gettingServerError();
            }
          });
        }
      }
  


  onBlurForgetPswdMethod() {
    if (this.forgotPass.controls.Emailforgot.value) {
      this.forgotPass.patchValue({
        Emailforgot: ((this.forgotPass.controls.Emailforgot.value).trim())
      });
    }
  }

  setOtpTimer() {
    // Set the date we're counting down to

    // Update the count down every 1 second
    // this.clrInterval = setInterval(() => {

    //   seconds--;



    //   // Output the result in an element with id="demo"
    //   document.getElementById('timer').innerHTML = seconds + ' s';
    //   this.progressbarValue = 100 - sec * 100 / seconds;
    //   // If the count down is over, write some text
    //   if (seconds < 1) {
    //     clearInterval(this.clrInterval);
    //     this.showTimer = false;
    //     if (this.timerCount <= 1) {
    //       this.showResend = true;
    //     } else {
    //       this.showContact = true;
    //     }
    //   }
    // }, 1000);


    // const time = seconds;
    const timer$ = interval(1000);
    // this.progressbarValue = 100;
    const seconds = 60;
    const sub = timer$.subscribe((sec) => {
      this.progressbarValue = 100 - sec * 100 / seconds;
      this.curSec = sec;
      if (this.curSec === seconds) {
        this.showTimer = false;

        if (this.timerCount <= 1) {
          this.showResend = true;
        } else {
          this.showContact = true;
        }
        sub.unsubscribe();
      }
    });

  }
}
