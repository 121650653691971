<div class="" *ngIf="forWhom == 'fake user'">
    <div mat-dialog-title class="text-center">
        <div class="subheading oswald">
            Flag this profile as fake?
        </div>
        <div class="smaller-text opensans-regular">Select/write a reason for flagging profile as fake</div>
    </div>

    <div mat-dialog-content>
        <div class="row mx-0">
            <div *ngIf="markAsfakeReasons" class="col-12 px-0">
                <ng-container *ngFor="let item of markAsfakeReasons;let i = index">
                    <div class="">
                        <mat-checkbox [checked]="item.isActive" (change)="selDeclineReason($event,item,i)">
                            <span class="c-darkgray opensans-light small-text">{{item.reason}}</span>
                        </mat-checkbox>
                    </div>
                </ng-container>

            </div>
            <div class="col-12 px-0 pb-1">
                <div class="smaller-text opensans-regular">Write a reason</div>
                    <textarea matInput [formControl]="declineInputReason" class="w-100 reason-class"
                        placeholder="Any other comments for the user ?" required></textarea>
                        <!-- <div *ngIf="!declineInputReason.value" class="c-alert-danger small-text pt-1">This field is required</div> -->
            </div>
        </div>
    </div>

    <div mat-dialog-actions align="center" class="mt-5 pb-2">
        <button mat-button mat-dialog-close class="c-graycolor">CANCEL</button>
        <button [disabled]="!declineInputReason.value" [ngClass]="declineInputReason.value? 'bg-btn-primary': 'bg-disabled-primary'" mat-raised-button class="c-white" [disabled]="disableFakeProfileButton" (click)="sendDataBack()">YES, FLAG AS FAKE</button>
    </div>
</div>
<div *ngIf="forWhom == 'undo'" class="row mx-0">
    <div class="col-12 px-0 pb-2 text-center">
        <div class="row mx-0">
            <div class="col-12 px-0">
                <div class="text-right">
                    <span (click)="closeDialog()" class="c-lightgray" style="cursor: pointer;">&#10005;</span>
                </div>
                <div class="">
                    <img src="/assets/images/static/warning.svg" alt="" height="150px" width="150px">
                    <div *ngIf="isFakeUser"  class="mt-3 oswald c-darkgray subheading">This profile has been flagged as fake.</div>
                    <div *ngIf="!isFakeUser"  class="mt-3 oswald c-darkgray subheading">This profile was flagged as fake. Undo?</div>
                </div>
            </div>
            <div *ngIf="!isFakeUser" class="col-12 px-0 mt-4">
                <button mat-button mat-dialog-close class="c-graycolor">CANCEL</button>
                <button mat-raised-button class="bg-btn-primary c-white ml-2" [disabled]="disableFakeProfileButton" (click)="sendDataBack()">YES, UNDO</button>
            </div>
            <div  *ngIf="isFakeUser" class="col-12 px-0 mt-4">
                <button mat-raised-button class="bg-btn-primary c-white" (click)="goBack()">OK</button>
            </div>
        </div>
        
    </div>
    
</div>
<div *ngIf="forWhom == 'screening' || forWhom == 'screening hold'" class="row mx-0">
    <div class="col-12 px-0 pb-2 text-center">
        <div class="text-right">
            <span (click)="closeDialog()" class="c-lightgray" style="cursor: pointer;">&#10005;</span>
        </div>
        <div class="row mx-0">
            <div class="col-12 px-0" *ngIf="forWhom == 'screening'">
                <div class="">
                    <img src="../../../../assets/images/static/success.svg" alt="" height="150px" width="150px">
                    <div  class="mt-3 oswald c-darkgray subheading">Sure you want to screen this profile?</div>
                </div>
            </div>
            <div class="col-12 px-0" *ngIf="forWhom == 'screening hold'">
                <div class="">
                    <img src="../../../../assets/images/static/exclamation-icon.svg" alt="" height="100px" width="100px">
                    <div  class="mt-3 oswald c-darkgray j-h3">Put this profile's screening on hold?</div>
                    <div class="smaller-text opensans-regular mt-2">Select/write a reason for put this profile on hold</div>
                </div>
                <div class="col-12 px-0 pb-1 mt-2">
                        <textarea matInput [formControl]="holdInputProfileReason" class="w-100 reason-class"
                            placeholder="Specify reason" required></textarea>
                </div>
                
            </div>
            <div *ngIf="!isFakeUser" class="col-12 px-0 mt-4">
                <button mat-button mat-dialog-close class="c-graycolor">CANCEL</button>
                <button mat-raised-button class="bg-btn-primary c-white ml-2 pointer" *ngIf="forWhom == 'screening hold'" [disabled]="!holdInputProfileReason.value" [ngClass]="holdInputProfileReason.value.trim() == ''? 'bg-disabled-primary': 'bg-btn-primary' " [disabled]="disableHoldProfileButton" (click)="screeningDone('hold')">YES, PUT ON HOLD</button>
                <button mat-raised-button class="bg-btn-primary c-white ml-2" *ngIf="forWhom == 'screening'" (click)="screeningDone('screening done')">YES, MARK AS SCREENED</button>
            </div>
        </div>
        
    </div>
    
</div>
<div *ngIf="forWhom == 'sentEmail'" class="row mx-0">
    <div class="col-12 px-0 pb-2 text-center p-2">
        <div class="row mx-0">
            <div class="col-12 px-0">
                <div class="fa fa-times-thin fa-3x float-right mr-3" style="color: #a369e8;" mat-dialog-close></div>
            </div>
            <div class="col-12 px-0 mt-2">
                <div class="mt-2">
                    <img src="../../../../assets/images/static/check.svg" alt="" height="150px" width="150px">
                    <div  class="mt-2 oswald c-darkgray j-h3">Your email has been successfully sent.</div>
                </div>
            </div>
            <div class="col-12 px-0 mt-2">
                <div class="c-darkgray body-text m-2">Template: {{templateData}}</div>
            </div>
            <div class="col-12 px-0 mt-3 mb-3">
                <button mat-raised-button  mat-dialog-close class="bg-btn-primary c-white ml-2">OK</button>
            </div>
        </div>
        
    </div>
    
</div>