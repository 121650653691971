import { Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage.service';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { SharedService } from './shared.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private localService: LocalStorageService, private router: Router) { }

  logOut() {
    localStorage.clear();
    window.location.reload();
  }

  // if server error occurs
  gettingServerError() {
    const localData = this.localService.getFromLocal('user');
    if (localData) {
      this.router.navigate(['/oops'], { skipLocationChange: true });
    }
  }
}

@Injectable({
  providedIn:'root'
})
export class adminGuardService implements CanActivate {
  constructor(private localservice:LocalStorageService, private router: Router) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    try {
      const userData = this.localservice.getFromLocal('user');
      if(userData){
        if(userData.supportUserType == "admin"){
          this.router.navigate(['/admin-console']);
          return false;
        }else {
          console.log('access granted')
          return true;
        }
       
      }else {
        console.log('access denied');
        this.router.navigate(['/login']);
        return false;
      }
    } catch (error) {
      console.log('error in implementation');
    }
    throw new Error('Method not implemented.');
  }

}

@Injectable({
  providedIn:'root'
})
export class sessionGuardService implements CanActivate{
  constructor(private localservice:LocalStorageService, private router: Router) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
   try {
    const userData = this.localservice.getFromLocal('user');
    if(userData){
      this.router.navigate(['/admin']);
      return false;
    } else {
      return true;
    }
   } catch (error) {
     console.log('error in session guard service');
     
   }
    throw new Error('Method not implemented.');
  }
}

 // adminUrlAccessService
 @Injectable({
  providedIn:'root'
})
export class adminUrlAccessService implements CanActivate {
  constructor(private localservice:LocalStorageService, private router: Router, private sharedservice:SharedService) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    try {
      const userData = this.localservice.getFromLocal('user');
      if(userData){
        if( userData.supportUserType == "admin"){
          this.sharedservice.userType.isAdmin = true;
          return true;
        }else{
          this.router.navigate(['/admin/dashboard']);
          return false;
        }
      }else {
        this.router.navigate(['/login']);
        return false;
      }
    } catch (error) {
      console.log('error in implementation');
    }
    throw new Error('Method not implemented.');
  }

}