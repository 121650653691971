import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-oops-error-page',
  templateUrl: './oops-error-page.component.html',
  styleUrls: ['./oops-error-page.component.css']
})
export class OopsErrorPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  reload() {
    window.location.reload();
  }

}
