import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { SessionStorageService } from 'src/app/services/session-storage.service';
import { SharedService } from 'src/app/services/shared.service';
import { LoadingScreenComponent } from '../loading-screen/loading-screen.component';
import { DeclineReasonDialogComponent } from '../profile/decline-reason-dialog/decline-reason-dialog.component';
import { DeclineReasonsPhotosDialogComponent } from '../profile/photos/decline-reasons-photos-dialog/decline-reasons-photos-dialog.component';
import { FullPhotoviewDialogComponent } from '../profile/photos/full-photoview-dialog/full-photoview-dialog.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
declare const $;
@Component({
  selector: 'app-profiles-moderation',
  templateUrl: './profiles-moderation.component.html',
  styleUrls: ['./profiles-moderation.component.css'],
})
export class ProfilesModerationComponent implements OnInit {
  queryField: UntypedFormControl = new UntypedFormControl();
  tempCaption = null;
  filteredOptions = [];
  showFiller = false;
  hasApiResponse = false;
  dateFlag = null;
  editable = false;
  fakeUserFlag = false;
  holdUserFlag = false;
  showPlanDetails = false;
  wordToNumber = { One: 1, Four: 4, Seven: 7, Ten: 10 };
  assetImagePath = '/assets/images/static/';
  displayDataArray = [];
  userProfileValueForm: UntypedFormGroup;
  userPlanForm: UntypedFormGroup;
  talkWithUser = false;
  supportUserEmail: any = {};
  supportNotes = '';
  companyAction = '';
  isSubmitted = false;
  showMore = false;
  skipValue = 0;
  skelentonView = true;
  localUserDataNotes = [
  ];
  activityLabel = [
    {
      label: 'VERY ACTIVE',
      color: '#4f03e0'
    },
    {
      label: 'ACTIVE',
      color: '#008538'
    },
    {
      label: 'SLIPPING',
      color: '#ff9800'
    },
    {
      label: 'INACTIVE',
      color: '#d50000'
    }
  ];

  profileStatus = [
    {
      heading: 'IN SCREENING',
      color: '#a369e8'
    },
    {
      heading: 'ON HOLD',
      color: '#ff9800'
    },
    {
      heading: 'SCREENED',
      color: '#008538'
    }
  ];

  activity = {
    signUp: null,
    signUpVia: null,
    signUpViaOption: ['Desktop', 'Android App', 'Ios App', 'MobileWeb'],
    profileCompleted: null,
    profileScreened: null,
    lastOnline: null,
    lastOnlineVia: null
  }

  // selectedProfileStatus = this.profileStatus[0];

  userData = {
    alias: null,
    userId: null,
    ismale: true,
    firstName: null,
    lastName: null,
    prospectFirstName: null,
    prospectLastName: null,
    whom: null,
    age: null,
    height: null,
    email: null,
    phone: null,
    activityStatus: this.activityLabel[1],
    profileImage: this.assetImagePath + 'male-avatar-600x600.jpg',
    loginCount: null,
    basicLeftView: [
      {
        parameter: null,
        icon: this.assetImagePath + 'seeking-for.svg',
        toolTipText: 'Seeking For'
      },
      {
        parameter: null,
        icon: this.assetImagePath + 'time-frame.svg',
        toolTipText: 'Time Frame for Marriage'
      },
      {
        parameter: null,
        icon: this.assetImagePath + 'marital-status.svg',
        toolTipText: 'Marital Status'
      },
      {
        parameter: null,
        icon: this.assetImagePath + 'divorced-since.svg',
        toolTipText: 'Divorced Since'
      },
      {
        parameter: null,
        icon: this.assetImagePath + 'number-of-kids.svg',
        toolTipText: 'Number of Kids'
      },
      {
        parameter: null,
        icon: this.assetImagePath + 'location.svg',
        toolTipText: 'Location'
      },
      {
        parameter: null,
        icon: this.assetImagePath + 'citizen.svg',
        toolTipText: 'Citizen'
      },
      {
        parameter: null,
        icon: this.assetImagePath + 'degree.svg',
        toolTipText: 'Degree'
      },
      {
        parameter: null,
        icon: this.assetImagePath + 'income.svg',
        toolTipText: 'Income'
      },
      {
        parameter: null,
        subparameter: null,
        icon: this.assetImagePath + 'socio-economic-status.svg',
        toolTipText: 'Social Economic Status'
      },
      {
        parameter: null,
        icon: this.assetImagePath + 'religion.svg',
        toolTipText: 'Religion'
      },
      {
        parameter: null,
        icon: this.assetImagePath + 'community.svg',
        toolTipText: 'Community'
      },
    ],
    photoCounter: null,
    verifiedIconsArr: [
      {
        icon: this.assetImagePath + 'mobile-verified.svg',
        verified: false,
        toolTipText: 'Mobile Verified'
      },
      {
        icon: this.assetImagePath + 'email-verified.svg',
        verified: false,
        toolTipText: 'Email Verified'
      },
      {
        icon: this.assetImagePath + 'photoID-verified.svg',
        verified: false,
        toolTipText: 'PhotoID Verified'
      },
      {
        icon: this.assetImagePath + 'degree-verified.svg',
        verified: false,
        toolTipText: 'Degree Verified'
      },
      {
        icon: this.assetImagePath + 'employee-verified.svg',
        verified: false,
        toolTipText: 'Employee Verified'
      },
      {
        icon: this.assetImagePath + 'video-verified.svg',
        verified: false,
        toolTipText: 'Video Verified'
      }
    ]
  };

  languages = {
    parameter: [],
    icon: this.assetImagePath + 'language.svg',
    toolTipText: 'Languages'
  };

  planType = ['Quick Connect', 'Express Connect', 'Assisted Connect', 'Personal Touch'];

  planDuration = [1, 2, 3, 4, 5, 6];

  planTag = ['Complementary'];

  profileQuality = [1, 4, 7, 10];

  dimondProfile = ['Yes', 'No', 'Undecided'];

  // upgradeProbability = [10, 40, 70, 100];
  upgradeProbabilityValue = [
    {
      faceValue: 10,
      value: 40
    },
    {
      faceValue: 40,
      value: 60
    },
    {
      faceValue: 70,
      value: 80
    },
    {
      faceValue: 100,
      value: 100
    }
  ];

  acProspect = ['Yes', 'No', 'Maybe'];


  userPlanSubmit = false;
  userProfileValueSubmit = false;
  readMoreIndex = -1;

  timelineArr = [
    this.assetImagePath + 'call-gray.svg',
    this.assetImagePath + 'mail-gray.svg',
    this.assetImagePath + 'sms-gray.svg',
    this.assetImagePath + 'notification-gray.svg',
    this.assetImagePath + 'notes-gray.svg',
    this.assetImagePath + 'user-activity-gray.svg',
    this.assetImagePath + 'match-activity-gray.svg',
  ];

  matchesIconArr = [
    {
      icon: this.assetImagePath + 'total-matches-blue.svg',
      toolTipText: 'Browse Matches',
      count: 0,
      type: 1
    },
    {
      icon: this.assetImagePath + 'unviewed-matches-blue.svg',
      toolTipText: 'Unviewed Matches',
      count: 0,
      type: 10
    },
    {
      icon: this.assetImagePath + 'viewed-matches-blue.svg',
      toolTipText: 'Viewed Matches',
      count: 0,
      type: 2
    },
    {
      icon: this.assetImagePath + 'shortlisted-matches-blue.svg',
      toolTipText: 'Shortlisted Matches',
      count: 0,
      type: 3
    },
    {
      icon: this.assetImagePath + 'ignored-matches-blue.svg',
      toolTipText: 'Ignored Matches',
      count: 0,
      type: 4
    },
    {
      icon: this.assetImagePath + 'interest-sent-blue.svg',
      toolTipText: 'Interest Sent',
      count: 0,
      type: 5
    },
    {
      icon: this.assetImagePath + 'mutual-matches-blue.svg',
      toolTipText: 'Mutual Matches',
      count: 0,
      type: 6
    },
    {
      icon: this.assetImagePath + 'interest-received-blue.svg',
      toolTipText: 'Interest Received',
      count: 0,
      type: 7
    },
    {
      icon: this.assetImagePath + 'interest-I-declined-blue.svg',
      toolTipText: 'Interest I declined',
      count: 0,
      type: 8
    },
    {
      icon: this.assetImagePath + 'interest-they-declined-blue.svg',
      toolTipText: 'Interest they declined',
      count: 0,
      type: 9
    },
    {
      icon: this.assetImagePath + 'block-blue.svg',
      toolTipText: 'Block matches',
      count: 0,
      type: 14
    }
  ]

  tabSelection = 1;
  selMatchAction = -1;
  selImagePath;
  selTimelineIcon;
  selTimelineIconIndex;
  profileActionBtn = false;
  // moderation objects 
  objTraits = {
    name: '',
    approvedByReviewer: false,
    declineByReviewer: false,
    approvedBySupervisor: false,
    declineBySupervisior: false,
    customized: false,
    color: 'orange'
  };
  college = {
    degree: null,
    college: null,
    id: null,
    approvedByReviewer: false,
    declineByReviewer: false,
    approvedBySupervisor: false,
    declineBySupervisior: false,
    leftBtnColor: 'orange',
    rightBtnColor: 'orange'
  };
  moderationData = {
    academics: [],
    company: {
      name: '',
      approvedByReviewer: false,
      declineByReviewer: false,
      approvedBySupervisor: false,
      declineBySupervisior: false,
      leftBtnColor: 'orange',
      rightBtnColor: 'orange'
    },
    moreAboutMyself: {
      wordsDescribeMe: [],
      myTraits: [],
      myHobbies: [],
    },
    moreAboutPatner: {
      lookingFor: [],
      incompatibleQualites: [],
      doesntMatterQualities: []
    }
  };
  btnObj = {
    directionBtn: this.assetImagePath + 'moderation-direction-arrow.svg',
    edit: this.assetImagePath + 'edit.svg',
    info: this.assetImagePath + 'info.svg',
    tick: {
      orange: this.assetImagePath + 'tick-orange.svg',
      purple: this.assetImagePath + 'tick-purple.svg',
      white: this.assetImagePath + 'tick-white.svg',
      gray: this.assetImagePath + 'tick-gray.svg',
      red: this.assetImagePath + 'tick-red.svg',
      bgGreen: this.assetImagePath + 'tick-bg-green.svg'
    },
    cross: {
      orange: this.assetImagePath + 'cross-orange.svg',
      purple: this.assetImagePath + 'cross-purple.svg',
      white: this.assetImagePath + 'cross-white.svg',
      gray: this.assetImagePath + 'cross-gray.svg',
      green: this.assetImagePath + 'cross-green.svg',
      bgRed: this.assetImagePath + 'cross-bg-red.svg'
    },
    action: {
      edit: '../../../../assets/images/static/edit.svg'
    }
  };

  surveyQuestions = [
    {
      question: 'How did you hear about Jodi365? What was your first impression?',
      answer: ''
    },
    {
      question: 'Have you used any other matchmaking site/app/service? What has been your experience, good and bad, with them?',
      answer: ''
    },
    {
      question: 'What is one thing that Jodi365 could do to WOW you?',
      answer: ''
    },
    {
      question: ' We also offer personalized assistance from start to finish. Would you like to learn more?',
      answer: ''
    }
  ];
  isReviewer;
  checkisReviewer = false; // check isReviewer is here ,screening option should be not allowed.
  sharedData;
  // editCompany = [false];
  editUniversity = -1;
  moderationPanelOpen = false;
  surveyQuestion = false;
  submitted = false;

  // photos 
  photoArr = [];
  userNotes = [];
  video;
  apiKey;
  declineReasonsBySupervisor;
  declineReasonsByReviewer;
  sortBy = "Descending order";
  unmoderatedData;
  captionEditIndex = -1;
  SortingOrder = true;
  checkFilterOn = 'Not-screened';
  printFilterOn = 'Not-screened';
  totalUsers;
  PagesNumber = [];
  lastPageNumber = 0;
  apiparams: any = {
    limit: 25,
    skip: 0,
    type: 3,
    orderby: true
  };
  apiparamsForFiltering: any = {
    limit: 25,
    skip: 0,
    type: 0,
    orderby: ''
  }
  loaderDailog;
  singleModerationClick = false;
  displayPageNumber = 1;
  disableSaveNoteButton = false;  
  @ViewChildren('companyName') newCompanyName;
  @ViewChildren('universityName') newUniversityName;
  @ViewChild('pageNumber') pageNumber: ElementRef;
  @ViewChildren('firstName') newFirstName;
  constructor(private sharedservice: SharedService,private md: MatDialog, private localservice: LocalStorageService, private route: ActivatedRoute, private apiservice: ApiService, private fb: UntypedFormBuilder, private sessionstorage: SessionStorageService, private dialog: MatDialog, private router: Router) {
    this.sharedservice.userId = this.route.snapshot.queryParams.userid;
    this.supportUserEmail = this.localservice.getFromLocal('user');
   
    // console.log(this.route.snapshot.queryParams.userid);

    //  this.sharedservice.userId = 179571
    const user = this.localservice.getFromLocal('user');
    this.sharedservice.userType.isReviewer = user.supportUserType === 'reviewer' ? true : false;
    this.isReviewer = user.supportUserType === 'reviewer' ? true : false;
    this.checkisReviewer = user.supportUserType === 'reviewer' ? true : false;
    this.sharedservice.userType.name = user?.firstname;
    this.userPlanForm = this.fb.group({
      planName: [null, [Validators.required]],
      planStartDate: ['1', [Validators.required]],
      planDuration: [null, [Validators.required]],
      planTag: [null, [Validators.required]]
    });

  }

  ngOnInit(): void {

    if (this.route.snapshot.queryParams.userid) {
      this.apiparams.type = 12;
      this.apiparams.userids = this.sharedservice.userId;
      this.singleModerationClick = true;
    }
    // console.log(this.apiparams);

    

    this.apiservice.getunmoderatedprofilesdata(this.apiparams).subscribe(async (res: any) => {
      //  console.log(res.userdata);
      this.totalUsers = res.total;
      this.PagesNumber = [];
      for (let i = 0; i < (this.totalUsers / 25); i++) {
        const temp: any = {};
        temp.number = i + 1;
        this.PagesNumber.push(temp);
      }
      if (this.totalUsers % 25 != 0) {
        this.lastPageNumber = Math.floor((this.totalUsers / 25) + 1);
      }
      this.displayDataArray = [];
    //  console.time("preparedatatoview");
      await this.prepareIterableDataToView(res.userdata);
    //  console.timeEnd("preparedatatoview");
      this.hasApiResponse = true;
    },
      (err: HttpErrorResponse) => { // HttpErrorResponse for checking errors and we will show a msg to users
        this.hasApiResponse = false;
        if (err.status === 401) {
          this.localservice.removeToken('user');
          this.router.navigate(['/login']);
        } else if (err.status >= 500) {
          //  this.sharedservice.gettingServerError();
        }
      });

    this.queryField.valueChanges.pipe(
      debounceTime(100),
    ).subscribe(value => {
      if (value.length == 0) {
        this.filteredOptions = [];
      }
      if (value.length > 2) {
        const apiparams: any = {
          searchfragment: value,
          sequenceno: 1
        };
        this.apiservice.searchforuniversity(apiparams).subscribe(result => {
          result[0].searchlist.pop();
          this.filteredOptions = result[0].searchlist;
        }, (err: HttpErrorResponse) => {
          if (err.status === 401) {
            this.localservice.removeToken('user');
            this.router.navigate(['/login']);
          } else if (err.status >= 500) {
            //  this.sharedservice.gettingServerError();
          }
        });
      }
    });

  }

  
  async storeDifferentScorePoints(x, pointType, index, pindex, callingArraySource){
    if(index == 0){
      x.scroeType = pointType;
    } else {
      x.scroeType = 'otherThenScreenerType'; // jusy for ui purpose to display 
    }
    x.callingArraySource = callingArraySource;
    if(x.point == 1){
      await this.createscreenerPointArray(this.displayDataArray[pindex].veryLowScreenerPoints, pointType, x);
    } else if(x.point == 3){
      await this.createscreenerPointArray(this.displayDataArray[pindex].lowScreenerPoints, pointType, x);
    } else if(x.point == 5){
      await this.createscreenerPointArray(this.displayDataArray[pindex].mediumScreenerPoints, pointType, x);
    } else if(x.point == 7){
      await this.createscreenerPointArray(this.displayDataArray[pindex].highScreenerPoints, pointType, x);
    } else {
      await this.createscreenerPointArray(this.displayDataArray[pindex].veryHighScreenerPoints, pointType, x);
      
    }
  }

  createscreenerPointArray(arrayforPoints :any [], pointType, x){
    let len = arrayforPoints.length;
    if(arrayforPoints[len-1]?.callingArraySource != x.callingArraySource){
      x.scroeType = pointType;
    }
    arrayforPoints.push(x);
  }

  screenerPiontsList(type, pindex, index){
    this.displayDataArray[pindex].selectActiveScreener = index;
    this.displayDataArray[pindex].screenerPointArray = [];
    if(type == 'veryHighScreenerPoints'){
      this.displayDataArray[pindex].screenerPointArray = this.displayDataArray[pindex].veryHighScreenerPoints;
    } else if(type == 'highScreenerPoints'){
      this.displayDataArray[pindex].screenerPointArray = this.displayDataArray[pindex].highScreenerPoints;
    } else if(type == 'mediumScreenerPoints'){
      this.displayDataArray[pindex].screenerPointArray = this.displayDataArray[pindex].mediumScreenerPoints;
    } else if(type == 'lowScreenerPoints'){
      this.displayDataArray[pindex].screenerPointArray = this.displayDataArray[pindex].lowScreenerPoints;
    } else {
      this.displayDataArray[pindex].screenerPointArray = this.displayDataArray[pindex].veryLowScreenerPoints;
    }
  }
  nextPageDataLoad(pageNumber) {
    //  1 digit width  = 33
    //  2 digit width = 42
    //  3 digit width= 51
    //  4 digit width= 60
    //  5 digit width = 69
    //  6 digit width= 78
    // console.log(pageNumber);
    if (pageNumber <= 9) {
      $('.scroll-empty-class').animate({ scrollLeft: (33 * (pageNumber - 3)) });
    } else if (pageNumber >= 10 && pageNumber <= 99) {
      $('.scroll-empty-class').animate({ scrollLeft: (297 + (42 * (pageNumber - 10))) });
    } else if (pageNumber >= 100 && pageNumber <= 999) {
      $('.scroll-empty-class').animate({ scrollLeft: (4035 + (51 * (pageNumber - 100))) });
    } else if (pageNumber >= 1000 && pageNumber <= 9999) {
      $('.scroll-empty-class').animate({ scrollLeft: (50181 + (60 * (pageNumber - 1000))) });
    } else if (pageNumber >= 10000 && pageNumber <= 99999) {
      $('.scroll-empty-class').animate({ scrollLeft: (675444 + (69 * (pageNumber - 10000))) });
    } else if (pageNumber >= 100000 && pageNumber <= 999999) {
      $('.scroll-empty-class').animate({ scrollLeft: (70875366 + (78 * (pageNumber - 100000))) });
    }

    this.displayPageNumber = pageNumber;
    const loaderDailog = this.dialog.open(LoadingScreenComponent, { disableClose: true });
    this.apiparamsForFiltering.skip = (pageNumber - 1) * 25;
    if (this.checkFilterOn == 'Unmoderated-profiles') {
      this.apiparamsForFiltering.type = 0;
    } else if (this.checkFilterOn == 'Image-moderation') {
      this.apiparamsForFiltering.type = 1;
    } else if (this.checkFilterOn == 'Not-screened') {
      this.apiparamsForFiltering.type = 3;
    } else if (this.checkFilterOn == 'Profile-quality') {
      this.apiparamsForFiltering.type = 5;
    } else if (this.checkFilterOn == 'Deletion-request') {
      this.apiparamsForFiltering.type = 8;
    } else if (this.checkFilterOn == 'Survey-answers') {
      this.apiparamsForFiltering.type = 10;
    } else if (this.checkFilterOn == 'Personalized-assistance') {
      this.apiparamsForFiltering.type = 11;
    } else if (this.checkFilterOn == 'Video-moderation') {
      this.apiparamsForFiltering.type = 15;
    } else if(this.checkFilterOn == 'Fake-User'){
      this.apiparamsForFiltering.type = 18;
    } else if(this.checkFilterOn == 'Degree'){
      this.apiparamsForFiltering.type = 19;
    }
    this.apiparamsForFiltering.orderby = this.SortingOrder;

    this.apiservice.getunmoderatedprofilesdata(this.apiparamsForFiltering).subscribe(async (res: any) => {
      this.totalUsers = res.total;
      this.displayDataArray = [];
      await this.prepareIterableDataToView(res.userdata);
      this.hasApiResponse = true;
      setTimeout(() => {
        loaderDailog.close();
      }, 1500);
    },
      (err: HttpErrorResponse) => { // HttpErrorResponse for checking errors and we will show a msg to users
        this.hasApiResponse = false;
        if (err.status === 401) {
          this.localservice.removeToken('user');
          this.router.navigate(['/login']);
        } else if (err.status >= 500) {
          //  this.sharedservice.gettingServerError();
        }
      });

  }

  selectUniversity(item, pindex, i) {
    this.displayDataArray[pindex].academics[i].college = item.universityname;
  }

  appliedFilterByOrder(orderType) {
    if (orderType == 'Descending-order') {
      this.sortBy = "Descending order"
      this.SortingOrder = true;
    } else {
      this.sortBy = "Ascending order"
      this.SortingOrder = false;
    }
    this.appliedFilter(this.checkFilterOn);
  }

  appliedFilter(filterType) {
    this.apiparamsForFiltering.skip = 0;
    if (filterType == 'Unmoderated-profiles') {
      this.checkFilterOn = 'Unmoderated-profiles';
      this.printFilterOn = 'Unmoderated profiles'
      this.apiparamsForFiltering.type = 0;
    } else if (filterType == 'Image-moderation') {
      this.checkFilterOn = 'Image-moderation';
      this.printFilterOn = 'Image moderation'
      this.apiparamsForFiltering.type = 1;
    } else if (filterType == 'Not-screened') {
      this.checkFilterOn = 'Not-screened';
      this.printFilterOn = 'Not screened'
      this.apiparamsForFiltering.type = 3;
    } else if (filterType == 'Profile-quality') {
      this.checkFilterOn = 'Profile-quality';
      this.printFilterOn = 'Profile quality'
      this.apiparamsForFiltering.type = 5;
    } else if (filterType == 'Deletion-request') {
      this.checkFilterOn = 'Deletion-request';
      this.printFilterOn = 'Deletion request'
      this.apiparamsForFiltering.type = 8;
    } else if (filterType == 'Survey-answers') {
      this.checkFilterOn = 'Survey-answers';
      this.printFilterOn = 'Survey answers'
      this.apiparamsForFiltering.type = 10;
    } else if (filterType == 'Personalized-assistance') {
      this.checkFilterOn = 'Personalized-assistance';
      this.printFilterOn = 'Personalized assistance'
      this.apiparamsForFiltering.type = 11;
    } else if (filterType == 'Video-moderation') {
      this.checkFilterOn = 'Video-moderation';
      this.printFilterOn = 'Video moderation'
      this.apiparamsForFiltering.type = 15;
    } else if (filterType == 'Name-moderation') {
      this.checkFilterOn = 'Name moderation';
      this.printFilterOn = 'Name moderation'
      this.apiparamsForFiltering.type = 16;
    } else if (filterType == 'Screening on hold') {
      this.checkFilterOn = 'Screening on hold';
      this.printFilterOn = 'Screening on hold'
      this.apiparamsForFiltering.type = 17;
    } else if (filterType == 'Fake-User') {
      this.checkFilterOn = 'Fake-user';
      this.printFilterOn = 'Fake user'
      this.apiparamsForFiltering.type = 18;
    } else if (filterType == 'Degree') {
      this.checkFilterOn = 'Degree';
      this.printFilterOn = 'Degree'
      this.apiparamsForFiltering.type = 19;
    }
    this.apiparamsForFiltering.orderby = this.SortingOrder;
    // api calling
    const loaderDailog = this.dialog.open(LoadingScreenComponent, { disableClose: true });
    this.apiservice.getunmoderatedprofilesdata(this.apiparamsForFiltering).subscribe(async (res: any) => {
      this.totalUsers = res.total;
      // console.log(res.total);
      this.PagesNumber = [];
      this.displayPageNumber = 1;
      for (let i = 0; i < (this.totalUsers / 25); i++) {
        const temp: any = {};
        temp.number = i + 1;
        this.PagesNumber.push(temp);
      }
      if (this.totalUsers % 25 != 0) {
        this.lastPageNumber = Math.floor((this.totalUsers / 25) + 1);
      }

      this.displayDataArray = [];
      $('.scroll-empty-class').animate({ scrollLeft: 0 });
      await this.prepareIterableDataToView(res.userdata);
      this.hasApiResponse = true;
      setTimeout(() => {
        loaderDailog.close();
        document.getElementById("mySidepanel").style.width = "0";
      }, 1500);
      //  console.log(this.displayDataArray.length);

    },
      (err: HttpErrorResponse) => { // HttpErrorResponse for checking errors and we will show a msg to users
        this.hasApiResponse = false;
        if (err.status === 401) {
          this.localservice.removeToken('user');
          this.router.navigate(['/login']);
        } else if (err.status >= 500) {
          //  this.sharedservice.gettingServerError();
        }
      });

  }

  editAccountDetails(pindex) {
    this.displayDataArray[pindex].editable = true;
    //  this.editable = true;
  }

  saveAccountDetails(pindex) {
    this.displayDataArray[pindex].editable = false;
    // const temp = ['username', 'prospectname', 'email', 'phone'];
    // const param: any = {};
    // console.log(param);
  }
  // mark as fake profie
  async fakeUser(pindex, isfake, userId) {
    const dialog = this.dialog.open(ConfirmationDialogComponent, {
      autoFocus: false,
      width: '30vw',
      height: '50vh',
      maxHeight: '70vh',
      data: { isfakeUser: isfake, userId: userId }
    });
    dialog.afterClosed().subscribe(res => {
      if (res.forWhom === 'marked as fake' || res.forWhom === 'undo') {
        this.displayDataArray[pindex].fakeReasons = res.fakeReason;
        const fakeReasonLength = this.displayDataArray[pindex].fakeReasons?.length;
        // this.displayDataArray[pindex].fakeReasons = this.displayDataArray[pindex].fakeReasons?.split("\n", fakeReasonLength);
        if(this.displayDataArray[pindex].fakeReasons){
          this.displayDataArray[pindex].fakeReasons = this.replaceURLs(this.displayDataArray[pindex].fakeReasons);
        }
        
        this.displayDataArray[pindex].fakeUserFlag = isfake ? true : false;
        this.displayDataArray[pindex].isfakeprofile = isfake;
        //console.log(this.displayDataArray[pindex].isfakeprofile );
      }
    });
  }
  // mark as screened
  async changeProfileStatus(pindex, index, userId, type) {
    if (type === 'ON HOLD') {
      // && this.displayDataArray[pindex].selectedProfileStatus.heading != 'ON HOLD'  // This line was used for disable ON HOLD button when heading is ON HOLD.
      const dialog = this.dialog.open(ConfirmationDialogComponent, {
        autoFocus: false,
        maxHeight: '55vh',
        maxWidth: '30vw',
        data: { isScreeningHold: true, userId: userId }
      });
      dialog.afterClosed().subscribe(res => {
        if (res.comingfrom === 'screening is on hold') {
          this.displayDataArray[pindex].HoldReasons = res.holdReason;
          if(this.displayDataArray[pindex].HoldReasons){
            this.displayDataArray[pindex].HoldReasons = this.replaceURLs(this.displayDataArray[pindex].HoldReasons);
          }
          this.displayDataArray[pindex].profileStatus = [
            {
              heading: 'ON HOLD',
              color: '#ff9800'
            },
            {
              heading: 'SCREENED',
              color: '#008538'
            }
          ];
          this.displayDataArray[pindex].selectedProfileStatus = this.displayDataArray[pindex].profileStatus.length == 3 ? this.displayDataArray[pindex].profileStatus[1] : this.displayDataArray[pindex].profileStatus[0];
          this.displayDataArray[pindex].holdUserFlag = type === 'ON HOLD' ? true : false;
        }
      });
    } else if(type === 'SCREENED'){
      const dialog = this.dialog.open(ConfirmationDialogComponent, {
        autoFocus: false,
        maxHeight: '55vh',
        maxWidth: '30vw',
        data: { isScreeningProfile: true, userId: userId }
      });
      dialog.afterClosed().subscribe(res => {
        if (res === 'screening done') {
          //  console.log(res);
          this.displayDataArray[pindex].activity.screeningTime = new Date();
          this.displayDataArray[pindex].selectedProfileStatus = this.displayDataArray[pindex].profileStatus.length == 3 ? this.displayDataArray[pindex].profileStatus[2] : this.displayDataArray[pindex].profileStatus[1];
          this.displayDataArray[pindex].holdUserFlag = false;
        } 
      });
    }
  }

  

  // async changeProfileStatus(pindex, index, userId) {
  //   if (index === 1) {
  //     const dialog = this.dialog.open(ConfirmationDialogComponent, {
  //       autoFocus: false,
  //       maxHeight: '55vh',
  //       data: { isScreeningProfile: true, userId: userId }
  //     });
  //     dialog.afterClosed().subscribe(res => {
  //       if (res === 'screening done') {
  //         //  console.log(res);
  //         this.displayDataArray[pindex].selectedProfileStatus = this.profileStatus[index];
  //       }
  //     });
  //   }
  // }

  buyPlan() {
    this.userPlanSubmit = true;
    // console.log(this.userPlanForm);
  }

  userMatcheAction(pindex, index) {
    this.displayDataArray[pindex].selMatchAction = index;
    const re = /blue/gi;
    this.displayDataArray[pindex].selImagePath = this.displayDataArray[pindex].matchesIconArr[index].icon.replace(re, 'white');
  }

  async tabChange(e, pindex,userid) {


    if (e.index > 0) {
      this.displayDataArray[pindex].selMatchAction = -1;
      if (e.index == 2) {
        if (this.displayDataArray[pindex].imagedata.length > 0) {
          this.displayDataArray[pindex].countUnapprovedPhotos = 0;
          if(this.displayDataArray[pindex]?.photoArr?.length > 0){
            this.displayDataArray[pindex].photoArr = await this.getUserPhotos(this.displayDataArray[pindex].photoArr, this.displayDataArray[pindex].profileImage, pindex);
          } else {
            this.displayDataArray[pindex].photoArr = await this.getUserPhotos(this.displayDataArray[pindex].imagedata, this.displayDataArray[pindex].profileImage, pindex);
          }
          
          
          let tempCount = this.displayDataArray[pindex].countUnapprovedPhotos;
          let i = 0;
          while(tempCount >= 0){
            if(this.displayDataArray[pindex].photoArr[i]?.imageType == 'image' && this.displayDataArray[pindex].photoArr[i]?.topBtnColor == 'orange' && this.displayDataArray[pindex].photoArr[i]?.bottomBtnColor ==  'orange'){
              const element = this.displayDataArray[pindex].photoArr.splice(i, 1);
              // element = [{obj1}]  array of 1 length.
              this.displayDataArray[pindex].photoArr.splice(0,0,element[0]);
            }
            i++;
            tempCount--;
          }
          // console.log(this.displayDataArray[pindex].photoArr);
        //  this.displayDataArray[pindex].photoCounter = this.displayDataArray[pindex].photoArr[this.displayDataArray[pindex].photoArr.length - 1].totalPhotos;
        } 
        if (this.displayDataArray[pindex]?.video?.length > 0) {
          this.displayDataArray[pindex].video = this.getUserVideo(this.displayDataArray[pindex].video);
        } else {
          this.displayDataArray[pindex].video = this.getUserVideo(this.displayDataArray[pindex].videodata);

        }
      } else if(e.index == 3){
        this.displayDataArray[pindex].screenerPointSkeleton = true;
        this.displayDataArray[pindex].totalScreenerPointsValue = 0;
        this.displayDataArray[pindex].screenerPointArray = [];
        this.displayDataArray[pindex].veryHighScreenerPoints = [];
        this.displayDataArray[pindex].highScreenerPoints = [];
        this.displayDataArray[pindex].mediumScreenerPoints = [];
        this.displayDataArray[pindex].lowScreenerPoints = [];
        this.displayDataArray[pindex].veryLowScreenerPoints = [];
        this.getScreenerPoints(pindex,userid);
      }else if(e.index == 4){
        this.displayDataArray[pindex].userNotes = [];
        this.skipValue = 0;
        this.displayDataArray[pindex].userNotesSkelton = true;
       this.getUserNotes(pindex,userid);
       
      }
    }
  }

  getScreenerPoints(pindex,userId){
    const apiparams: any = {
      userid: userId
    };
    this.apiservice.getprofilescannerpoints(apiparams).subscribe(res => {
      if(res){
        res.default?.map( async (x,index) => {
          await this.storeDifferentScorePoints(x, 'Defaults', index, pindex, 1);
        })
        res.outlierResult?.map( async (x,index) => {
          await this.storeDifferentScorePoints(x, 'Outliers', index, pindex, 2);
        })
        res.lazyoptionsResult?.map(async (x, index) => {
          await this.storeDifferentScorePoints(x, 'Lazy options' , index, pindex, 3);
        })
        res.inconsistenciesResult?.map(async (x,index) => {
          await this.storeDifferentScorePoints(x, 'Inconsistencies' , index, pindex, 4);
        })
        
        setTimeout(() => {
          this.displayDataArray[pindex].screenerPointSkeleton = false;
          this.displayDataArray[pindex].screenerPointsArrayList[0].total =  this.displayDataArray[pindex].veryHighScreenerPoints.length;
          this.displayDataArray[pindex].screenerPointsArrayList[1].total =  this.displayDataArray[pindex].highScreenerPoints.length;
          this.displayDataArray[pindex].screenerPointsArrayList[2].total =  this.displayDataArray[pindex].mediumScreenerPoints.length;
          this.displayDataArray[pindex].screenerPointsArrayList[3].total =  this.displayDataArray[pindex].lowScreenerPoints.length;
          this.displayDataArray[pindex].screenerPointsArrayList[4].total =  this.displayDataArray[pindex].veryLowScreenerPoints.length;
          this.displayDataArray[pindex].totalScreenerPointsValue = ((this.displayDataArray[pindex].veryHighScreenerPoints.length * 10) + (this.displayDataArray[pindex].highScreenerPoints.length * 7) +  (this.displayDataArray[pindex].mediumScreenerPoints.length * 5) + (this.displayDataArray[pindex].lowScreenerPoints.length * 3) + (this.displayDataArray[pindex].veryLowScreenerPoints.length * 1));
          if(this.displayDataArray[pindex].veryHighScreenerPoints.length > 0){
            this.displayDataArray[pindex].selectActiveScreener = 0;
            this.displayDataArray[pindex].screenerPointArray = this.displayDataArray[pindex].veryHighScreenerPoints;
          } else if(this.displayDataArray[pindex].highScreenerPoints.length > 0){
            this.displayDataArray[pindex].selectActiveScreener = 1;
            this.displayDataArray[pindex].screenerPointArray = this.displayDataArray[pindex].highScreenerPoints;
          } else if(this.displayDataArray[pindex].mediumScreenerPoints.length > 0){
            this.displayDataArray[pindex].selectActiveScreener = 2;
            this.displayDataArray[pindex].screenerPointArray = this.displayDataArray[pindex].mediumScreenerPoints;
          } else if(this.displayDataArray[pindex].lowScreenerPoints.length > 0){
            this.displayDataArray[pindex].selectActiveScreener = 3;
            this.displayDataArray[pindex].screenerPointArray = this.displayDataArray[pindex].lowScreenerPoints;
          } else if(this.displayDataArray[pindex].veryLowScreenerPoints.length > 0){
            this.displayDataArray[pindex].selectActiveScreener = 4;
            this.displayDataArray[pindex].screenerPointArray = this.displayDataArray[pindex].veryLowScreenerPoints;
          } else {
            this.displayDataArray[pindex].selectActiveScreener = 0;
            this.displayDataArray[pindex].screenerPointArray = this.displayDataArray[pindex].veryHighScreenerPoints;
          }
        }, 500);
      }
      
      
     

      
    })

  }

  filterTimeline(index) {
    const re = /gray/gi;
    this.selTimelineIconIndex = index;
    this.selTimelineIcon = this.timelineArr[index].replace(re, 'green');
  }



  prepareIterableDataToView(dataArray) {
    dataArray.forEach(async (element, index) => {
      const temp: any = {};
      const userData = element.user;
      const userVideoActionData = element.videodata;
      const userAction = element.user?.matchesCount;
      let profileImage;
      temp.photoCounter =0;
      if (element.imagedata.length > 0 && element.user.profileimage) {
        element.imagedata.filter(ele => {
          if (ele.imageid === element.user.profileimage) {
            profileImage = ele.imageurl;
          }
          if (ele.imagename.includes('image')) {
            temp.photoCounter ++;
        }
      })
      } else {
        profileImage = this.sharedservice.getAvatarPhoto(userData.ismale);
      }

      // for trust badge
      // temp.verifiedIconsArr[0].verified = userData.isphoneverified;
      // temp.verifiedIconsArr[1].verified = userData.isemailverified;
      temp.isphoneverified = userData.isphoneverified;
      temp.isemailverified = userData.isemailverified;
      if (userVideoActionData.length > 0) {
        if (userVideoActionData[0].declined == true) {
          temp.isvideoDecline = userVideoActionData[0].declined;
        } else if ((userVideoActionData[0].supervisorAction == true || userVideoActionData[0].reviewerAction == true) && userVideoActionData[0].declined == null) {

          temp.isvideoDecline = false;
        } else if (userVideoActionData[0].supervisorAction == null || userVideoActionData[0].reviewerAction == null) {
          temp.isvideoDecline = true;
        }
      } else {
        temp.isvideoDecline = 'empty';
      }


      temp.moderationPanelOpen = false;
      temp.hasModerationPanelActionTaken = false;
      temp.hashTags = element.hashTags;
      temp.profiledata = element.profiledata;
      temp.universities = element.universities;

      // Variable for skelton for Usernotes
      temp.userNotesSkelton = true;

      // // function for getting own summary data;
      // temp.moreAboutMyself = await this.getModerationMyselfData(element.hashTags?.aboutMyself);
      // // function for getting partner summary data;
      // temp.moreAboutPatner = await this.getModerationPartnerData(element.hashTags?.aboutPartner);
      // // function for getting company detail data;
      // temp.company = await this.getModerationCompanynameData(element.profiledata?.moreabout);
      // // function for getting university detail data;
      // temp.academics = await this.getModerationUniversityData(element.universities);

      
      // firstname
      temp.userFirstName = userData.prospectfirstname ? userData.prospectfirstname : userData.firstname;
      if(temp.userFirstName){
        // console.log(userData.firstnameunmoderated);
        if(userData.firstnameunmoderated == null){
          temp.firstnamemoderatedby = userData?.firstnamemoderatedby;
          temp.firstnamemoderatedtime = userData.firstnamemoderatedtime;
          temp.firstnameunmoderated = userData.firstnameunmoderated;
          temp.firstNameleftBtnColor = 'green';
          temp.firstNameButtonDisable = true;
          
          // const acceptedDdate = new Date((temp.firstnamemoderatedtime)).toLocaleTimeString();
          // const acceptedDdateFormat = new Date((temp.firstnamemoderatedtime)).toLocaleDateString(undefined, { weekday: 'short' })+ "," + " " + new Date(
          //   temp.firstnamemoderatedtime
          // ).toLocaleDateString(undefined, { day: '2-digit' }) + " " + new Date(
          //   temp.firstnamemoderatedtime
          // ).toLocaleDateString(undefined, { month: 'short' }) + " " + new Date(
          //   temp.firstnamemoderatedtime
          // ).getFullYear()
          // temp.valueAcceptedRejectedBy = 'Approved,' + ' By ' + temp.firstnamemoderatedby + ' ' + acceptedDdateFormat + ' , ' + acceptedDdate;



          temp.valueAcceptedRejectedBy='Approved,' + ' By ' + temp.firstnamemoderatedby + ' ' + new Date(temp.firstnamemoderatedtime).toLocaleString(undefined, {year: 'numeric', month: 'short', day: '2-digit', weekday:"short", hour: '2-digit', hour12: true, minute:'2-digit', second:'2-digit'});

          temp.valueAcceptedRejectedBy = this.parsedText(temp.valueAcceptedRejectedBy);
        } else {
          temp.firstNameleftBtnColor = 'orange';
          temp.firstnameunmoderated = userData.firstnameunmoderated;
          temp.firstNameButtonDisable = false;
        }
      } else {
        temp.userFirstName = 'N.A.';
      }
     
      temp.profileImage = profileImage;
      temp.alias = userData.alias;
      temp.userId = userData.userid;
      temp.email = userData.email;
      // if(temp.email.length > 18){
      //   temp.emailWithDot = 
      // }
      temp.phone = userData.phonenumber ? ('+' + userData.countrycode + ' ' + userData.phonenumber) : null;
      temp.firstName = userData.firstname ? userData.firstname : null;
      temp.lastName = userData.lastname ? userData.lastname : null;
      temp.prospectFirstName = userData.prospectfirstname ? userData.prospectfirstname : null;
      temp.loginCount = userData.logincount;
      temp.prospectLastName = userData.prospectlastname ? userData.prospectlastname : null;
      temp.ismale = userData.ismale;
      temp.editable = false;
      temp.selMatchAction = -1;
      temp.tabSelection = 1;
      // temp.whom = userData.whom ? userData.whom: null;
      if (userData.whom) {
        if (userData.whom === 'Son/Daughter') {
          temp.whom = 'Parent';
        } else if (userData.whom === 'Nephew/Niece') {
          temp.whom = 'Uncle/Aunt';
        } else {
          temp.whom = userData.whom;
        }
      } else {
        temp.whom = null;
      }
      temp.age = userData.age;
      const height = this.sharedservice.heightData.filter(ele => {
        if (ele.id === userData.height) {
          return ele.value;
        }
      });
      temp.height = height.length > 0 ? height[0].value : 'N.A.';
      const basicLeftView = await this.prepareBasicLeftViewData(userData, element.universities);
      temp.basicLeftView = basicLeftView;
      
      if(userData.isscreeningpending == true){
        temp.profileStatus = [
          {
            heading: 'ON HOLD',
            color: '#ff9800'
          },
          {
            heading: 'SCREENED',
            color: '#008538'
          }
        ];
      } else {
        temp.profileStatus = [
          {
            heading: 'IN SCREENING',
            color: '#a369e8'
          },
          {
            heading: 'ON HOLD',
            color: '#ff9800'
          },
          {
            heading: 'SCREENED',
            color: '#008538'
          }
        ];
      }
      
      const mindex = userData.isscreeningdone === true ? temp.profileStatus[temp.profileStatus.length-1] : userData.isscreeningpending == true ? temp.profileStatus[0] : temp.profileStatus[0];
        temp.selectedProfileStatus = mindex;
     
      // const mindex = userData.isscreeningdone === true ? this.profileStatus[1] : this.profileStatus[0];
      temp.isfakeprofile = userData.isfakeprofile;
      temp.fakeReasons = null;
      temp.fakeReasons = userData.fakeprofilereason;
      const fakeReasonLength = temp.fakeReasons?.length;
      
      // temp.fakeReasons = temp.fakeReasons?.split("\n", fakeReasonLength);
      if(temp.fakeReasons){
        temp.fakeReasons = this.replaceURLs(temp.fakeReasons); 
      }

      temp.HoldReasons = null;
      temp.HoldReasons = userData.holdreason;
      if(temp.HoldReasons && temp.HoldReasons.length > 0){
        temp.holdUserFlag = true;
        temp.HoldReasons = this.replaceURLs(temp.HoldReasons);     
      }else{
        temp.holdUserFlag = false;
      }
    



    //  temp.photoCounter = element.imagedata.length;
      const languages = {
        parameter: [],
        icon: this.assetImagePath + 'language.svg',
        toolTipText: 'Languages'
      };
      const language = {
        language: null,
        fluency: null
      };
      userData.language?.forEach(lang => {
        const motherTongue = lang.split(' - ')[1];
        const english = lang.split(' - ')[1];
        if (motherTongue.includes('mother')) {
          language.language = lang.split(' - ')[0];
          language.fluency = 'mother';
          languages.parameter.splice(0, 0, { ...language })
        } else if (english.includes('complete')) {
          language.language = lang.split(' - ')[0];
          language.fluency = 'complete';
          languages.parameter.splice(1, 0, { ...language })
        } else {
          language.language = lang.split(' - ')[0];
          language.fluency = 'other';
          languages.parameter.splice(2, 0, { ...language })
        }
        //  languages.parameter.push({ ...language });
      });

      temp.languages = languages;
      temp.isTalkedUser = ((userData.phoneverifiedbyadmin || userData.phoneverifiedbysupervisor || userData.phoneverifiedbyreviewer) && userData.phonenumber) ? true : false;
      const activity = {
        signUp: null,
        signUpVia: null,
        signUpViaOption: ['Desktop', 'Android App', 'Ios App', 'MobileWeb'],
        currentquestionnumber: 0,
        profileCompleted: null,
        profileScreened: null,
        lastOnline: null,
        lastOnlineVia: null,
        screeningTime: null
      }
      temp.activityStatus = this.activityLabel[1];
      temp.loginCount = userData.logincount;
      // tslint:disable-next-line: max-line-length
      activity.signUp = userData.signuptime ? userData.signuptime <= 9999999999 ? userData.signuptime * 1000 : userData.signuptime : null;
      activity.signUpVia = activity.signUpViaOption[userData.profileAccess.signupfrom - 1];
      activity.currentquestionnumber = userData.currentquesnumberapp;
      if(userData.currentquesnumberapp == -1){
        activity.currentquestionnumber = null;
      }
      // tslint:disable-next-line: max-line-length
      activity.profileCompleted = userData.profilecompletedon ? userData.profilecompletedon <= 9999999999 ? userData.profilecompletedon * 1000 : userData.profilecompletedon : null;
      // tslint:disable-next-line: max-line-length
      activity.lastOnline = userData.accesstime ? userData.accesstime <= 9999999999 ? userData.accesstime * 1000 : userData.accesstime : null;
      activity.lastOnlineVia = activity.signUpViaOption[userData.profileAccess.accessedFrom - 1];
      activity.screeningTime = userData.screeningtimestamp;
      temp.activity = activity;
      temp.fakeUserFlag = userData.isfakeprofile;
      const planNack = userData.plansNpacks;
      temp.planType = planNack.isassistplan ? 'Assisted connect' : (planNack.isexpressplan ? 'Express connect' : (planNack.isquickplan ? 'Quick connect' : ''));
      if (planNack.subscriptionexpireson) {
        const ExpiryTimeStamp = Math.ceil((planNack.subscriptionexpireson - Date.now()) / 8.64e7);
        temp.remainingDays = this.sharedservice.planExpiryInDays(ExpiryTimeStamp);
      }
      temp.planBoughtOn = planNack.subscriptionexpireson;
      temp.planExpireOn = planNack.subscriptionexpireson;
      temp.profileQuality = userData.ProfileQuality ? this.wordToNumber[userData.ProfileQuality] : null,
        temp.profileDiamond = userData.iselite ? userData.iselite : null,
        temp.profileUpgrade = userData.probabilityToUpgrade ? userData.probabilityToUpgrade : null,
        temp.profileACProspect = userData.probabilityToAssistedUser ? userData.probabilityToAssistedUser.substring(0, 1).toUpperCase() + userData.probabilityToAssistedUser.substring(1) : null
      temp.profileActionBtn = false;
      temp.surveyQuestion = false;
      temp.ratingGivenByUser = false;
      temp.ratingArray = [];
      const matchesCount = await this.prepareMatchesIconArray(index, userAction);
      temp.matchesIconArr = matchesCount;
      temp.selImagePath;
      temp.imagedata = element.imagedata
       temp.photoArr = [];
       temp.userNotes = [];
       temp.screenerPointArray = [];
       temp.selectActiveScreener = 0;
       temp.screenerPointSkeleton = true;
       temp.totalScreenerPointsValue = 0;
       temp.screenerPointsArrayList = [
        {
          text : 'VERY HIGH',
          passedValue : 'veryHighScreenerPoints',
          total : 0,
          value : 10
        },
        {
          text : 'HIGH',
          passedValue : 'highScreenerPoints',
          total : 0,
          value : 7
        },
        {
          text : 'MEDIUM',
          passedValue : 'mediumScreenerPoints',
          total : 0,
          value : 5
        },
        {
          text : 'LOW',
          passedValue : 'lowScreenerPoints',
          total : 0,
          value : 3
        },
        {
          text : 'VERY LOW',
          passedValue : 'veryLowScreenerPoints',
          total : 0,
          value : 1
        }
      ];
       temp.veryHighScreenerPoints = [];
       temp.highScreenerPoints = [];
       temp.mediumScreenerPoints = [];
       temp.lowScreenerPoints = [];
       temp.veryLowScreenerPoints = [];
    //   temp.photoCounter = element.imagedata.length;
       temp.videodata = element.videodata;
       temp.video;
      // if (element.imagedata.length > 0) {
      //   temp.imagedata = element.imagedata
      //   // temp.photoArr = [];
      //   // temp.photoCounter = element.imagedata.length;
      //   //  this.sharedservice.moderationImageData.push({id:index,userid:userData.userid,imagedata:element.imagedata,profileimage:element.user.profileimage})
      //   temp.photoArr = await this.getUserPhotos(element.imagedata, element.user.profileimage);
      //   temp.photoCounter = temp.photoArr.length > 0 ? temp.photoArr[temp.photoArr.length - 1].totalPhotos : 0;
      // }
     // temp.video = this.getUserVideo(element.videodata);
      this.apiKey = this.sharedservice.apiKey;
      temp.editCompany = false;
      temp.editFirstName = false;
      temp.tempCheckForProfilePhoto = 1;
      temp.photoTickBoxActive = -1;
      temp.surveyQuestions = [
        {
          question: 'How did you hear about Jodi365? What was your first impression?',
          answer: ''
        },
        {
          question: 'Have you used any other matchmaking site/app/service? What has been your experience, good and bad, with them?',
          answer: ''
        },
        {
          question: 'What is one thing that Jodi365 could do to WOW you?',
          answer: ''
        },
        {
          question: ' We also offer personalized assistance from start to finish. Would you like to learn more?',
          answer: ''
        }
      ];
      temp.captionEditIndex = -1;
      temp.countUnapprovedPhotos = 0;
      this.displayDataArray.push(temp);
    });

  }
  // prepare basics left view data
  prepareBasicLeftViewData(userData, universities) {

    const basicLeftView = [
      {
        parameter: null,
        icon: this.assetImagePath + 'seeking-for.svg',
        toolTipText: 'Seeking For'
      },
      {
        parameter: null,
        icon: this.assetImagePath + 'time-frame.svg',
        toolTipText: 'Time Frame for Marriage'
      },
      {
        parameter: null,
        icon: this.assetImagePath + 'marital-status.svg',
        toolTipText: 'Marital Status'
      },
      {
        parameter: null,
        icon: this.assetImagePath + 'divorced-since.svg',
        toolTipText: 'Divorced Since',
        dateFlag: null
      },
      {
        parameter: null,
        icon: this.assetImagePath + 'number-of-kids.svg',
        toolTipText: 'Number of Kids'
      },
      {
        parameter: null,
        icon: this.assetImagePath + 'location.svg',
        toolTipText: 'Location'
      },
      {
        parameter: null,
        icon: this.assetImagePath + 'citizen.svg',
        toolTipText: 'Citizen'
      },
      {
        parameter: null,
        icon: this.assetImagePath + 'degree.svg',
        toolTipText: 'Degree'
      },
      {
        parameter: null,
        icon: this.assetImagePath + 'income.svg',
        toolTipText: 'Income'
      },
      {
        parameter: null,
        subparameter: null,
        icon: this.assetImagePath + 'socio-economic-status.svg',
        toolTipText: 'Social Economic Status'
      },
      {
        parameter: null,
        icon: this.assetImagePath + 'religion.svg',
        toolTipText: 'Religion'
      },
      {
        parameter: null,
        icon: this.assetImagePath + 'community.svg',
        toolTipText: 'Community'
      },
    ];
    basicLeftView[0].parameter = userData.marriagetimeframe ? userData.marriagetimeframe : 'N.A.';
    basicLeftView[1].parameter = userData.opentotimeframe ? userData.opentotimeframe : 'N.A.';
    basicLeftView[2].parameter = userData.maritalstatus ? userData.maritalstatus : 'N.A.';
    // tslint:disable-next-line: max-line-length
    basicLeftView[3].parameter = userData.maritalsince ? userData.maritalsince <= 9999999999 ? userData.maritalsince * 1000 : userData.maritalsince : 'Not Applicable';
    basicLeftView[3].dateFlag = basicLeftView[3].parameter === 'Not Applicable' ? null : basicLeftView[3].parameter;
    basicLeftView[4].parameter = userData.currentchildren ? userData.currentchildren : 'N.A.';
    // tslint:disable-next-line: max-line-length
    basicLeftView[5].parameter = userData.location ? userData.location : 'N.A.';
    if (userData.residencystatus === 'Citizen') {
      basicLeftView[6].parameter = userData.location.split(', ')[2];
    } else {
      basicLeftView[6].parameter = userData.permanentresident ? userData.permanentresident : 'N.A.';
    }
    const degree = universities?.map(ele => ele.educationdegree);
  
    basicLeftView[7].parameter = degree?.length > 0 ? degree.toString() : 'N.A.';
    basicLeftView[8].parameter = userData.income ? userData.income : 'N.A.';
    // tslint:disable-next-line: max-line-length
    basicLeftView[9].parameter = userData.socieo ? userData.socieo.split(' (')[0] : 'N.A.';
    // tslint:disable-next-line: max-line-length
    basicLeftView[9].subparameter = userData.socieo ? '(' + userData.socieo.split(' (')[1] : '';
    basicLeftView[10].parameter = userData.religion ? userData.religion.toString() : 'N.A.';
    basicLeftView[11].parameter = userData.community ? userData.community.toString() : 'N.A.';
    return basicLeftView;
  }
  // preparing matches icons array data
  prepareMatchesIconArray(index, userAction) {
    const matchesIconArr = [
      {
        icon: this.assetImagePath + 'total-matches-blue.svg',
        toolTipText: 'Browse Matches',
        count: 0,
        type: 1
      },
      // {
      //   icon: this.assetImagePath + 'unviewed-matches-blue.svg',
      //   toolTipText: 'Unviewed Matches',
      //   count: 0,
      //   type: 10
      // },
      {
        icon: this.assetImagePath + 'viewed-matches-blue.svg',
        toolTipText: 'Viewed Matches',
        count: 0,
        type: 2
      },
      {
        icon: this.assetImagePath + 'shortlisted-matches-blue.svg',
        toolTipText: 'Shortlisted Matches',
        count: 0,
        type: 3
      },
      {
        icon: this.assetImagePath + 'ignored-matches-blue.svg',
        toolTipText: 'Ignored Matches',
        count: 0,
        type: 4
      },
      {
        icon: this.assetImagePath + 'interest-sent-blue.svg',
        toolTipText: 'Interest Sent',
        count: 0,
        type: 5
      },
      {
        icon: this.assetImagePath + 'mutual-matches-blue.svg',
        toolTipText: 'Mutual Matches',
        count: 0,
        type: 6
      },
      {
        icon: this.assetImagePath + 'interest-received-blue.svg',
        toolTipText: 'Interest Received',
        count: 0,
        type: 7
      },
      {
        icon: this.assetImagePath + 'interest-I-declined-blue.svg',
        toolTipText: 'Interest I declined',
        count: 0,
        type: 8
      },
      {
        icon: this.assetImagePath + 'interest-they-declined-blue.svg',
        toolTipText: 'Interest they declined',
        count: 0,
        type: 9
      },
      {
        icon: this.assetImagePath + 'block-blue.svg',
        toolTipText: 'Block matches',
        count: 0,
        type: 14
      }
    ]
    matchesIconArr[0].count = userAction.totalUnviewedMatches;
    // matchesIconArr[1].count = userAction.totalProspectUnviewedMatches;
    matchesIconArr[1].count = userAction.totalViewedMatches;
    matchesIconArr[2].count = userAction.totalShortlistedMatches;
    matchesIconArr[3].count = userAction.totalIgnoredMatches;
    matchesIconArr[4].count = userAction.totalIntrestsSent;
    matchesIconArr[5].count = userAction.totalMutualInterests;
    matchesIconArr[6].count = userAction.totalInterestsReceived;
    matchesIconArr[7].count = userAction.totalInterestsDeclinedByMe;
    matchesIconArr[8].count = userAction.totalProspectDeclinedInterests;
    matchesIconArr[9].count = userAction.totalSuperBlockMatches;
    return matchesIconArr;

  }
  // prepariong hashtags myself data 
  getModerationMyselfData(aboutMyself) {
    this.objTraits.name = '';
    this.objTraits.customized = false;
    const moreAboutMyself = {
      wordsDescribeMe: [],
      myTraits: [],
      myHobbies: [],
    };
    const mySelf = aboutMyself ? aboutMyself : null;
    if (mySelf) {
      // functinality for custom added hashtags not exists
      // mySelf.unmoderatedq1.forEach(traits => {
      //   this.objTraits.name = traits;
      //   this.objTraits.customized = true;
      //   moreAboutMyself.wordsDescribeMe.push({ ...this.objTraits });
      // });
      mySelf.approvedq1.forEach(traits => {
        this.objTraits.name = traits;
        this.objTraits.customized = false;
        moreAboutMyself.wordsDescribeMe.push({ ...this.objTraits });
      });
      // functinality for custom added hashtags not exists
      // mySelf.unmoderatedq2.forEach(traits => {
      //   this.objTraits.name = traits;
      //   this.objTraits.customized = true;
      //   moreAboutMyself.myTraits.push({ ...this.objTraits });
      // });
      mySelf.approvedq2.forEach(traits => {
        this.objTraits.name = traits;
        this.objTraits.customized = false;
        moreAboutMyself.myTraits.push({ ...this.objTraits });
      });
      // functinality for custom added hashtags not exists
      // mySelf.unmoderatedq3.forEach(traits => {
      //   this.objTraits.name = traits;
      //   this.objTraits.customized = true;
      //   moreAboutMyself.myHobbies.push({ ...this.objTraits });
      // });
      mySelf.approvedq3.forEach(traits => {
        this.objTraits.name = traits;
        this.objTraits.customized = false;
        moreAboutMyself.myHobbies.push({ ...this.objTraits });
      });
      return moreAboutMyself;
    } else {
      return null;
    }
  }
  // preparing hashtags partner data 
  getModerationPartnerData(aboutPartner) {
    this.objTraits.name = '';
    this.objTraits.customized = false;
    const moreAboutPatner = {
      lookingFor: [],
      incompatibleQualites: [],
      doesntMatterQualities: []
    };
    const Patner = aboutPartner ? aboutPartner : null;
    if (Patner) {
      // functinality for custom added hashtags not exists
      // Patner.unmoderatedq1.forEach(traits => {
      //   this.objTraits.name = traits;
      //   this.objTraits.customized = true;
      //   moreAboutPatner.lookingFor.push({ ...this.objTraits });
      // });
      Patner.approvedq1.forEach(traits => {
        this.objTraits.name = traits;
        this.objTraits.customized = false;
        moreAboutPatner.lookingFor.push({ ...this.objTraits });
      });
      // functinality for custom added hashtags not exists
      // Patner.unmoderatedq2.forEach(traits => {
      //   this.objTraits.name = traits;
      //   this.objTraits.customized = true;
      //   moreAboutPatner.incompatibleQualites.push({ ...this.objTraits });
      // });
      Patner.approvedq2.forEach(traits => {
        this.objTraits.name = traits;
        this.objTraits.customized = false;
        moreAboutPatner.incompatibleQualites.push({ ...this.objTraits });
      });
      // functinality for custom added hashtags not exists
      // Patner.unmoderatedq3.forEach(traits => {
      //   this.objTraits.name = traits;
      //   this.objTraits.customized = true;
      //   moreAboutPatner.doesntMatterQualities.push({ ...this.objTraits });
      // });
      Patner.approvedq3.forEach(traits => {
        this.objTraits.name = traits;
        this.objTraits.customized = false;
        moreAboutPatner.doesntMatterQualities.push({ ...this.objTraits });
      });
      return moreAboutPatner;
    } else {
      return null;
    }
  }

  // preparing company name data
  getModerationCompanynameData(moreabout) {
    const companyData = moreabout ? moreabout : null;
    this.moderationData.company.name = '';
    const company = companyData;
    company.name = '',
      company.approvedByReviewer = false,
      company.declineByReviewer = false,
      company.moderatedTimeByReviewer = null;
    company.approvedBySupervisor = false,
      company.moderatedTimeBySupervisor = null;
    company.declineBySupervisior = false,
      company.leftBtnColor = 'orange',
      company.rightBtnColor = 'orange'
    company.valueAcceptedRejectedBy = null;
    // for supervisor approved
    if (companyData.companynameModeratedBy && companyData.companynamemoderationfailed == null) {
      company.approvedBySupervisor = companyData.companynameModeratedBy;
      company.valueAcceptedRejectedBy = company.approvedBySupervisor;
      if (companyData.companynamemoderatedtime) {
        company.moderatedTimeBySupervisor = companyData.companynamemoderatedtime;
        // const acceptedDdate = new Date((company.moderatedTimeBySupervisor));
        // const acceptedDdate = new Date((company.moderatedTimeBySupervisor)).toLocaleTimeString();
        // const acceptedDdateFormat = new Date((company.moderatedTimeBySupervisor)).toLocaleDateString(undefined, { weekday: 'short' })+ "," + " " + new Date(
        //   company.moderatedTimeBySupervisor
        // ).toLocaleDateString(undefined, { day: '2-digit' }) + " " + new Date(
        //   company.moderatedTimeBySupervisor
        // ).toLocaleDateString(undefined, { month: 'short' }) + " " + new Date(
        //   company.moderatedTimeBySupervisor
        // ).getFullYear()
        company.valueAcceptedRejectedBy = 'Approved,' + ' By ' + company.valueAcceptedRejectedBy + ' ' + new Date(company.moderatedTimeBySupervisor).toLocaleString(undefined, {year: 'numeric', month: 'short', day: '2-digit', weekday:"short", hour: '2-digit', hour12: true, minute:'2-digit', second:'2-digit'});
        company.valueAcceptedRejectedBy = this.parsedText(company.valueAcceptedRejectedBy);
      }
    }
    // for supervisor rejected
    else if (companyData.companynamemoderationfailed) {
      company.approvedBySupervisor = companyData.companynameModeratedBy;
      company.valueAcceptedRejectedBy = company.approvedBySupervisor;
      if (companyData.companynamemoderatedtime) {
        company.moderatedTimeBySupervisor = companyData.companynamemoderatedtime;
        // const acceptedDdate = new Date((company.moderatedTimeBySupervisor));
        // const acceptedDdate = new Date((company.moderatedTimeBySupervisor)).toLocaleTimeString();
        // const acceptedDdateFormat = new Date((company.moderatedTimeBySupervisor)).toLocaleDateString(undefined, { weekday: 'short' })+ "," + " " + new Date(
        //   company.moderatedTimeBySupervisor
        // ).toLocaleDateString(undefined, { day: '2-digit' }) + " " + new Date(
        //   company.moderatedTimeBySupervisor
        // ).toLocaleDateString(undefined, { month: 'short' }) + " " + new Date(
        //   company.moderatedTimeBySupervisor
        // ).getFullYear()
        company.valueAcceptedRejectedBy = 'Rejected,' + ' By ' + company.valueAcceptedRejectedBy + ' ' + new Date(company.moderatedTimeBySupervisor).toLocaleString(undefined, {year: 'numeric', month: 'short', day: '2-digit', weekday:"short", hour: '2-digit', hour12: true, minute:'2-digit', second:'2-digit'});
        company.valueAcceptedRejectedBy = this.parsedText(company.valueAcceptedRejectedBy);
      }
    }
    // for reviewer Approved
    else if (companyData.companynameReviewerReason == null && companyData.companynamereviewedBy) {
      company.approvedByReviewer = companyData.companynamereviewedBy;
      company.valueAcceptedRejectedBy = company.approvedByReviewer;
      if (companyData.companynamereviewedtime) {
        company.moderatedTimeByReviewer = companyData.companynamereviewedtime;
        // const acceptedDdate = new Date((company.moderatedTimeByReviewer));
        // const acceptedDdate = new Date((company.moderatedTimeByReviewer)).toLocaleTimeString();
        // const acceptedDdateFormat = new Date((company.moderatedTimeByReviewer)).toLocaleDateString(undefined, { weekday: 'short' })+ "," + " " + new Date(
        //   company.moderatedTimeByReviewer
        // ).toLocaleDateString(undefined, { day: '2-digit' }) + " " + new Date(
        //   company.moderatedTimeByReviewer
        // ).toLocaleDateString(undefined, { month: 'short' }) + " " + new Date(
        //   company.moderatedTimeByReviewer
        // ).getFullYear()
        company.valueAcceptedRejectedBy = 'Approved,' + ' By ' + company.valueAcceptedRejectedBy + ' ' + new Date(company.moderatedTimeByReviewer).toLocaleString(undefined, {year: 'numeric', month: 'short', day: '2-digit', weekday:"short", hour: '2-digit', hour12: true, minute:'2-digit', second:'2-digit'});
        company.valueAcceptedRejectedBy = this.parsedText(company.valueAcceptedRejectedBy);
      }
    }
    // for reviewer rejectd
    else if (companyData.companynameReviewerReason && companyData.companynamereviewedBy) {
      company.approvedByReviewer = companyData.companynamereviewedBy;
      company.valueAcceptedRejectedBy = company.approvedByReviewer;
      if (companyData.companynamereviewedtime) {
        company.moderatedTimeByReviewer = companyData.companynamereviewedtime;
        // const acceptedDdate = new Date((company.moderatedTimeByReviewer));
        // const acceptedDdate = new Date((company.moderatedTimeByReviewer)).toLocaleTimeString();
        // const acceptedDdateFormat = new Date((company.moderatedTimeByReviewer)).toLocaleDateString(undefined, { weekday: 'short' })+ "," + " " + new Date(
        //   company.moderatedTimeByReviewer
        // ).toLocaleDateString(undefined, { day: '2-digit' }) + " " + new Date(
        //   company.moderatedTimeByReviewer
        // ).toLocaleDateString(undefined, { month: 'short' }) + " " + new Date(
        //   company.moderatedTimeByReviewer
        // ).getFullYear()
        company.valueAcceptedRejectedBy = 'Rejected,' + ' By ' + company.valueAcceptedRejectedBy + ' ' + new Date(company.moderatedTimeByReviewer).toLocaleString(undefined, {year: 'numeric', month: 'short', day: '2-digit', weekday:"short", hour: '2-digit', hour12: true, minute:'2-digit', second:'2-digit'});
        company.valueAcceptedRejectedBy = this.parsedText(company.valueAcceptedRejectedBy);
      }
    }
    if (companyData.companyname) {
      company.name = companyData.companyname;
      if (companyData.companynameModeratedBy && !companyData.companynameunmoderated && !companyData.companynamemoderationfailed) {
        company.leftBtnColor = 'green';
        company.rightBtnColor = 'white';
      } else if (companyData.companynameModeratedBy && !companyData.companynameunmoderated && companyData.companynamemoderationfailed) {
        company.leftBtnColor = 'white';
        company.rightBtnColor = 'red';
      } else if (companyData.companynamereviewedBy && companyData.companynameaction) {
        company.leftBtnColor = 'purple';
        company.rightBtnColor = 'white';
      } else if (companyData.companynamereviewedBy && companyData.companynameaction === false) {
        company.leftBtnColor = 'white';
        company.rightBtnColor = 'gray';
      } else {
        company.leftBtnColor = 'orange';
        company.rightBtnColor = 'orange';
      }
    } else {
      company.name = 'N.A.';
    }

    return company;
  }

  // edit university
  editUniversityPencilIcon(i, item, pindex) {
    this.displayDataArray[pindex].academics[i].editUniversity = i;
    // this.displayDataArray[pindex].academics[i].college = item.editCollege.value;
  }


  // preparing unviersity/ academic data
  getModerationUniversityData(universities) {
    const university = universities;
    const academics = []
    const college: any = {
      degree: null,
      college: null,
      id: null,
      approvedByReviewer: false,
      declineByReviewer: false,
      approvedBySupervisor: false,
      declineBySupervisior: false,
      moderationTime: null,
      leftBtnColor: 'orange',
      rightBtnColor: 'orange',
      valueAcceptedRejectedBy: null,
      isPreApproved: false,
    };
    if (university.length > 0) {
      university.forEach(clg => {
        college.degree = clg.educationdegree;
        college.college = clg.university;
        college.id = clg.relid;
        // college.editCollege = new FormControl();
        // college.editCollege.setValue(college.college);
        college.editUniversity = -1;
        college.universityunmoderated = clg.universityunmoderated;
        college.universityreason = clg.universityreason;
        if (clg.ModeratedBy == null && clg.ReviewedBy == null && clg.universityunmoderated == null) {
          college.isPreApproved = true;
        } else {
          college.isPreApproved = false;
        }
        if (clg.ModeratedBy && !clg.universityunmoderated && !clg.universitymoderationfailed) {
          college.leftBtnColor = 'green';
          college.rightBtnColor = 'white';
        } else if (clg.ModeratedBy && !clg.universityunmoderated && clg.universitymoderationfailed) {
          college.leftBtnColor = 'white';
          college.rightBtnColor = 'red';
        } else if (clg.ReviewedBy && clg.universityaction) {
          college.leftBtnColor = 'purple';
          college.rightBtnColor = 'white';
        } else if (clg.ReviewedBy && clg.universityaction === false) {
          college.leftBtnColor = 'white';
          college.rightBtnColor = 'gray';
        } else {
          college.leftBtnColor = 'orange';
          college.rightBtnColor = 'orange';
        }

        // for superwiser approved
        if (clg.ModeratedBy && clg.moderatetime && clg.universitymoderationfailed == null) {
          college.approvedBySupervisor = clg.ModeratedBy;
          college.moderationTime = clg.moderatetime;
          const time = new Date(college.moderationTime);
          college.valueAcceptedRejectedBy = 'Approved,' + ' By ' + college.approvedBySupervisor + ' ' + time.toString().slice(0, 3) + ' , ' + time.toLocaleString();
          college.valueAcceptedRejectedBy = this.parsedText(college.valueAcceptedRejectedBy);
          //  console.log(college.valueAcceptedRejectedBy);

        }
        // for superwiser rejected
        else if (clg.ModeratedBy && clg.moderatetime && clg.universitymoderationfailed == true) {
          college.declineBySupervisior = clg.ModeratedBy;
          college.moderationTime = clg.moderatetime;
          const time = new Date(college.moderationTime);
          college.valueAcceptedRejectedBy = 'Rejected,' + ' By ' + college.declineBySupervisior + ' ' + time.toString().slice(0, 3) + ' , ' + time.toLocaleString();
          college.valueAcceptedRejectedBy = this.parsedText(college.valueAcceptedRejectedBy);
          //    console.log(college.valueAcceptedRejectedBy);
        }
        // for reviewer approved
        else if (clg.ReviewedBy && clg.reviewedtime && !clg.universityreason) {
          college.approvedByReviewer = clg.ReviewedBy;
          college.moderationTime = clg.reviewedtime;
          const time = new Date(college.moderationTime);
          college.valueAcceptedRejectedBy = 'Approved,' + ' By ' + college.approvedByReviewer + ' ' + time.toString().slice(0, 3) + ' , ' + time.toLocaleString();
          college.valueAcceptedRejectedBy = this.parsedText(college.valueAcceptedRejectedBy);
          //  console.log(college.valueAcceptedRejectedBy);
        }
        //  for reviewer rejected
        else if (clg.ReviewedBy && clg.reviewedtime && clg.universityreason) {
          college.declineByReviewer = clg.ReviewedBy;
          college.moderationTime = clg.reviewedtime;
          const time = new Date(college.moderationTime);
          college.valueAcceptedRejectedBy = 'Rejected,' + ' By ' + college.declineByReviewer + ' ' + time.toString().slice(0, 3) + ' , ' + time.toLocaleString();
          college.valueAcceptedRejectedBy = this.parsedText(college.valueAcceptedRejectedBy);
          // console.log(college.valueAcceptedRejectedBy);
        }
        academics.push({ ...college });
      });

    } else {
      college.degree = 'N.A.';
      academics.push({ ...college });
    }

    return academics;
  }

  

  // creating unversity or company moderation data
  parsedText(text) {
    const dom = new DOMParser().parseFromString(
      '<!doctype html><body>' + text, 'text/html');
    const decodedString = dom.body.textContent;
    return decodedString;
  }

  getSurveyAnswers(pindex, moderateUserid) {
    this.displayDataArray[pindex].surveyQuestion = !this.displayDataArray[pindex].surveyQuestion;
    // this.surveyQuestion = !this.surveyQuestion;
    let answers;
    if(this.displayDataArray[pindex].surveyQuestion){
      this.apiservice.getSurveyAnswers(moderateUserid).subscribe(result => {


        answers = result[0];
        this.setSurveyAnswer(pindex, answers);
      },
        (err: HttpErrorResponse) => { // HttpErrorResponse for checking errors and we will show a msg to users
          if (err.status === 401) {
            this.localservice.removeToken('user');
            this.router.navigate(['/login']);
          } else if (err.status >= 500) {
            //  this.sharedservice.gettingServerError();
          }
        });
    }
    
  }

  refreshUserNotes(pindex,userId){
    this.displayDataArray[pindex].userNotes = [];
    this.skipValue = 0;
    this.displayDataArray[pindex].userNotesSkelton = true;
    this.getUserNotes(pindex,userId);
  }

  getUserNotes(pindex,userId){
    // console.log(this.displayDataArray[pindex].userNotes);
   
    const apiparams: any = {
      userid: userId,
      limit: 10,
      skip: this.skipValue,
    };
    this.apiservice.getusernotes(apiparams).subscribe(result => {
      let temp : any = result;
      if(temp.length >= 0){
        this.displayDataArray[pindex].userNotesSkelton = false;
      }
     
      if(temp.length % 10 == 0 && temp.length > 0){
        this.showMore = true;
    } else {
      this.showMore = false;
    }
      if (result) {
        for(const i in result){
          let temp :any = {}
          
            temp.createdby = result[i].createdby
            temp.detail = this.replaceURLs(result[i].detail);
            temp.talkwith = result[i].talkwith;
            temp.Time = new Date(result[i].timestamp);
            temp.day = temp.Time.toString().slice(0, 3);
            this.displayDataArray[pindex].userNotes.push(temp);
      }

      if(this.skipValue >= 10){
        this.loaderDailog.close();
      }
    
    
      }

    },
      (err: HttpErrorResponse) => { // HttpErrorResponse for checking errors and we will show a msg to users
        if (err.status === 401) {
          this.localservice.removeToken('user');
          this.router.navigate(['/login']);
        } else if (err.status >= 500) {
          //  this.sharedservice.gettingServerError();
        }
      });
    
      // let duplicate = [];
      // duplicate = this.displayDataArray[pindex].userNotes;
      // duplicate.forEach(value => {
      //   if (!this.displayDataArray[pindex].userNotes.includes(value)) {
      //     this.displayDataArray[pindex].userNotes.push(value);
      //   }
      // });
     
      // console.log(this.displayDataArray[pindex].userNotes);
  }

  loadMoreData(pindex,userId){
    this.skipValue = this.skipValue + 10;
    this.getUserNotes(pindex, userId);
     this.loaderDailog = this.md.open(LoadingScreenComponent, { disableClose: true });
      
  }

  talkwithuserFunction(e){
    this.talkWithUser = e.checked;
  }
 
  saveSupportNotesData(notes, userID,pindex){ 
    this.disableSaveNoteButton = true;
    const finalnotes = this.replaceURLs(notes);
    if(notes.length> 0){
      const apiParams: any = {
        id: Number(userID),
        detail: notes ,
        talkwith: this.talkWithUser,
      };
      this.apiservice.makeusernotes(apiParams).subscribe(result => {
        this.disableSaveNoteButton = false;
          this.supportNotes = null;
          this.talkWithUser = false;
      },
      (err: HttpErrorResponse) => { // HttpErrorResponse for checking errors and we will show a msg to users
        if (err.status === 401) {
          this.localservice.removeToken('user');
          this.router.navigate(['/login']);
        } else if (err.status >= 500) {
        
        }
      });
  
      const temp : any = {};
      temp.createdby = this.supportUserEmail.email;
      temp.detail = finalnotes ;
      temp.talkwith = false;
      temp.Time = new Date();
      temp.day = new Date().toString().slice(0, 3);
      this.displayDataArray[pindex].userNotes.splice(0,0,temp);
  
    }
  }

  // prepareNotesData(userNoteData){
  //   console.log("print data");
  //   const temp: any = {}
  //   temp.createdby = userNoteData.createdby;
  //   temp.detail = this.replaceURLs(userNoteData.detail); // check URL inside Notes then hightlight the link.
  //   temp.talkwith = userNoteData.talkwith;
  //   temp.Time = new Date(userNoteData.timestamp);
  //   temp.day = temp.Time.toString().slice(0, 3);

  //   this.localUserDataNotes.push(temp);
  //   console.log(this.localUserDataNotes);
  // }

  setSurveyAnswer(pindex, answers) {
    const personalassist = answers?.personalassist;
    this.displayDataArray[pindex].surveyQuestions[0].answer = answers?.howdidyouhear ? answers.howdidyouhear : 'N.A.';
    this.displayDataArray[pindex].surveyQuestions[1].answer = answers?.experience ? answers.experience : 'N.A.';
    this.displayDataArray[pindex].surveyQuestions[2].answer = answers?.wowquestion ? answers.wowquestion : 'N.A.';
    this.displayDataArray[pindex].surveyQuestions[3].answer = personalassist != null ? (personalassist === 'false' || !personalassist ? 'No' : 'Yes') : 'N.A';

  }
  
  detectURLs(notes) {
    const urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
    return notes.match(urlRegex)
  }

  replaceURLs(notes) { //check URL inside Notes then hightlight the link.
    this.detectURLs(notes);
    if(!notes) return;
  
    const urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
    return notes.replace(urlRegex, function (url) {
      let hyperlink = url;
      if (!hyperlink.match('^https?:\/\/')) {
        hyperlink = 'http://' + hyperlink;
      }
      return  '<a href="' + hyperlink + '" target="_blank" rel="noopener noreferrer">' + url + ' </a>'
    });
  }


  getNPSrating(pindex, moderateUserid) {
    this.displayDataArray[pindex].ratingGivenByUser = !this.displayDataArray[pindex].ratingGivenByUser;
    if(this.displayDataArray[pindex].ratingGivenByUser){
      this.apiservice.getnpsrating({ userid: Number(moderateUserid) }).subscribe(result => {
        if (result) {
          // let userRating;
          const userRating = result.ratings;
          this.setUserRating(pindex, userRating);
  
        }
      },
        (err: HttpErrorResponse) => { // HttpErrorResponse for checking errors and we will show a msg to users
          if (err.status === 401) {
            this.localservice.removeToken('user');
            this.router.navigate(['/login']);
          } else if (err.status >= 500) {
            //  this.sharedservice.gettingServerError();
          }
        });
    }
    
  }

  setUserRating(pindex, userRating) {
    this.displayDataArray[pindex].ratingArray = userRating.reverse();
  }

  moderateCollege(pindex, index, item, status) {
    const params: any = {
      relid: item.id,
      decision: {
        universities: {
          message: status ? null : 'This is testing',
          collegename: status ? item.college : null,
          approved: status ? true : false
        }
      }
    };
    if (this.displayDataArray[pindex].academics[index].editUniversity === index && status) {
      // this.displayDataArray[pindex].academics[index].college = item.editCollege.value;
      params.decision.universities.collegename = item.college;
      params.decision.universities.collegename = params.decision.universities.collegename?.trim();
      // params.decision.universities.collegename = this.newUniversityName._results[pindex].nativeElement.innerText;
    } else if (this.displayDataArray[pindex].academics[index].editUniversity === index && status === false) {
      // this.displayDataArray[pindex].academics[index].editCollege.setValue(item.college);
      this.displayDataArray[pindex].academics[index].college = item.college;
    }



    // this.displayDataArray[pindex].academics[index].editUniversity = -1;
    const colleges = this.displayDataArray[pindex].academics;
    this.apiservice.unversityModeration(params).subscribe(res => {
      if (res) {
        this.displayDataArray[pindex].academics[index].editUniversity = -1;
        if (!this.isReviewer && status) {
          this.displayDataArray[pindex].academics[index].college = item.college;
          colleges.ModeratedBy = true;
          colleges.universityunmoderated = false;
          colleges.universitymoderationfailed = false;
          this.displayDataArray[pindex].academics[index].leftBtnColor = 'green';
          this.displayDataArray[pindex].academics[index].rightBtnColor = 'white';
        } else if (!this.isReviewer && status === false) {
          colleges.ModeratedBy = true;
          colleges.universityunmoderated = false;
          colleges.universitymoderationfailed = false;
          this.displayDataArray[pindex].academics[index].leftBtnColor = 'white';
          this.displayDataArray[pindex].academics[index].rightBtnColor = 'red';
        } else if (this.isReviewer && status) {
          this.displayDataArray[pindex].academics[index].college = item.college;
          colleges.ReviewedBy = true;
          colleges.universityaction = true;
          this.displayDataArray[pindex].academics[index].leftBtnColor = 'purple';
          this.displayDataArray[pindex].academics[index].rightBtnColor = 'white';
        } else {
          colleges.ReviewedBy = true;
          colleges.universityaction = false;
          this.displayDataArray[pindex].academics[index].leftBtnColor = 'white';
          this.displayDataArray[pindex].academics[index].rightBtnColor = 'gray';
        }
      }
    },
      (err: HttpErrorResponse) => { // HttpErrorResponse for checking errors and we will show a msg to users
        if (err.status === 401) {
          this.localservice.removeToken('user');
          this.router.navigate(['/login']);
        } else if (err.status >= 500) {
          //  this.sharedservice.gettingServerError();
        }
      });

  }

  modeateFirstName(pindex, status, userId){
    this.displayDataArray[pindex].firstNameButtonDisable = true;
    const params : (any) = {}
    if(this.displayDataArray[pindex].firstnameunmoderated){
      params.userid =  userId,
      params.approve = status;
      params.firstname = this.displayDataArray[pindex].userFirstName;
    } else {
      params.userid =  userId,
      params.firstname = this.displayDataArray[pindex].userFirstName;
    }
    this.apiservice.editfirstname(params).subscribe(result => {
      if(result){
        this.isSubmitted = true;
        this.displayDataArray[pindex].editFirstName = false;
        this.displayDataArray[pindex].userFirstName =  params.firstname;
        this.displayDataArray[pindex].firstNameleftBtnColor = 'green';
        this.displayDataArray[pindex].firstnameunmoderated = null;
        this.newFirstName._results = [];
      }
    }, (err: HttpErrorResponse) => { // HttpErrorResponse for checking errors and we will show a msg to users
      if (err.status === 401) {
        this.localservice.removeToken('user');
        this.router.navigate(['/login']);
      } else if (err.status >= 500) {
        //  this.sharedservice.gettingServerError();
      }
    });
    
  }

  editFirstNameFunction(pindex){
    this.displayDataArray[pindex].editFirstName = !this.displayDataArray[pindex].editFirstName;
    this.displayDataArray[pindex].firstNameleftBtnColor = 'orange';
    
   
  }

  modeateCompany(pindex, status, userId) {
    const params: (any) = {
      userid: userId,
      decision: {
        companyname: {
          approved: status,
          message: status ? null : 'This is for testing'
        }
      }
    };

    // if (this.displayDataArray[pindex].editCompany) {
    params.newvalue = this.newCompanyName._results[pindex]?.nativeElement.innerText;
    params.newvalue = params.newvalue?.trim();
    // }

    const company = this.displayDataArray[pindex].company;
    if (company.companynameunmoderated || company.companynameunmoderated == null) {
      this.apiservice.companyModeration(params).subscribe(res => {
        if (res) {
          this.displayDataArray[pindex].editCompany = false;
          if (!this.isReviewer && status) {
            company.companynameModeratedBy = true;
            company.companynameunmoderated = false;
            company.companynamemoderationfailed = false;
            this.displayDataArray[pindex].company.leftBtnColor = 'green';
            this.displayDataArray[pindex].company.rightBtnColor = 'white';
          } else if (!this.isReviewer && status === false) {
            company.companynameModeratedBy = true;
            company.companynameunmoderated = false;
            company.companynamemoderationfailed = true;
            this.displayDataArray[pindex].company.leftBtnColor = 'white';
            this.displayDataArray[pindex].company.rightBtnColor = 'red';
          } else if (this.isReviewer && status) {
            company.companynamereviewedBy = true;
            company.companynameaction = true;
            this.displayDataArray[pindex].company.leftBtnColor = 'purple';
            this.displayDataArray[pindex].company.rightBtnColor = 'white';
          } else {
            company.companynamereviewedBy = true;
            company.companynameaction = false;
            this.displayDataArray[pindex].company.leftBtnColor = 'white';
            this.displayDataArray[pindex].company.rightBtnColor = 'gray';
          }
        }
      },
        (err: HttpErrorResponse) => { // HttpErrorResponse for checking errors and we will show a msg to users
          if (err.status === 401) {
            this.localservice.removeToken('user');
            this.router.navigate(['/login']);
          } else if (err.status >= 500) {
            //  this.sharedservice.gettingServerError();
          }
        });
    }
  }

  /// about section moderation panel open
  getmoderationInfoData(pindex) {
    if (!this.displayDataArray[pindex].moderationPanelOpen && !this.displayDataArray[pindex].hasModerationPanelActionTaken) {

      this.displayDataArray[pindex].moderationPanelOpen = !this.displayDataArray[pindex].moderationPanelOpen;
      this.displayDataArray[pindex].hasModerationPanelActionTaken = true;
      // function for getting own summary data;
      this.displayDataArray[pindex].moreAboutMyself = this.getModerationMyselfData(this.displayDataArray[pindex].hashTags?.aboutMyself);
      // function for getting partner summary data;
      this.displayDataArray[pindex].moreAboutPatner = this.getModerationPartnerData(this.displayDataArray[pindex].hashTags?.aboutPartner);
      // function for getting company detail data;
      this.displayDataArray[pindex].company = this.getModerationCompanynameData(this.displayDataArray[pindex].profiledata?.moreabout);
      // function for getting university detail data;
      this.displayDataArray[pindex].academics = this.getModerationUniversityData(this.displayDataArray[pindex].universities);
    } else {
      this.displayDataArray[pindex].moderationPanelOpen = !this.displayDataArray[pindex].moderationPanelOpen;
    }


  }

  ///---------------------------------------------------------//
  //   photos section
  ///// ------------  --- --- ----   ----------------------------------------------//
  makeProfilePhoto(pindex, index, e, pitem) {
    if (pitem.tempCheckForProfilePhoto == 1) {
      pitem.tempCheckForProfilePhoto = 2;
      pitem.photoTickBoxActive = index;
    } else if (index != pitem.photoTickBoxActive) {
      this.displayDataArray[pindex].photoArr[pitem.photoTickBoxActive].markasprofile = false;
      this.displayDataArray[pindex].photoArr[index].markasprofile = e.checked;
      pitem.photoTickBoxActive = index;
      pitem.tempCheckForProfilePhoto = 2;
    }

  }
  changeStatus(pindex, index, status, item, type) {
    this.displayDataArray[pindex].photoArr[index].disablePhotoApproveButton  = true;
    if (this.tempCaption != null) {
      this.displayDataArray[pindex].captionEditIndex = -1;
      item.description = this.tempCaption;
      this.tempCaption = null;
    }
    const params: any = {
      approve: status,
      caption: type === 'img' ? null : item.description,
      imageid: item.imageid,
      isphotoid: item.imageType === 'image' ? false : true,
      markasprofile: item.markasprofile ? true : false
    };
    if (!status && type === 'img') {
      params.declinereason = this.sharedservice.userType.isReviewer ? item.reason : item.declinereason;
    }
    const action = this.displayDataArray[pindex].photoArr[index];
    if (item.unmoderated && type === 'img' || ((item.descriptionunmoderated && !this.isReviewer) && type === 'caption')) {
      this.apiservice.imagemoderation(params).subscribe(res => {
        if (res) {
          if (type === 'img') {
            this.displayDataArray[pindex].countUnapprovedPhotos--;
            if (!this.isReviewer && status) {
              action.supervisorAction = status;
              this.displayDataArray[pindex].photoArr[index].supervisorAction = status;
              action.unmoderated = null;
              this.displayDataArray[pindex].photoArr[index].unmoderated = null;
              this.displayDataArray[pindex].photoArr[index].topBtnColor = 'green';
              this.displayDataArray[pindex].photoArr[index].bottomBtnColor = 'white';
            } else if (!this.isReviewer && status === false) {
              action.supervisorAction = status;
              this.displayDataArray[pindex].photoArr[index].supervisorAction = status;
              action.unmoderated = null;
              this.displayDataArray[pindex].photoArr[index].unmoderated = null;
              action.declinereason = item.declinereason;
              this.displayDataArray[pindex].photoArr[index].declinereason = item.declinereason;
              action.topBtnColor = 'white';
              this.displayDataArray[pindex].photoArr[index].topBtnColor = 'white';
              action.bottomBtnColor = 'red';
              this.displayDataArray[pindex].photoArr[index].bottomBtnColor = 'red';
            } else if (this.isReviewer && status) {
              action.reviewerAction = status;
              this.displayDataArray[pindex].photoArr[index].reviewerAction = status;
              this.displayDataArray[pindex].photoArr[index].topBtnColor = 'purple';
              this.displayDataArray[pindex].photoArr[index].bottomBtnColor = 'white';
            } else {
              action.reviewerAction = status;
              this.displayDataArray[pindex].photoArr[index].reviewerAction = status;
              action.reason = item.reason;
              this.displayDataArray[pindex].photoArr[index].reason = item.reason;
              this.displayDataArray[pindex].photoArr[index].topBtnColor = 'white';
              this.displayDataArray[pindex].photoArr[index].bottomBtnColor = 'gray';

            }
            const currenttime = Date.now();
            this.displayDataArray[pindex].photoArr[index].photoApprovedtime = item.markasprofile ? currenttime : null;
            if (this.sharedservice.userType.isReviewer) {
              this.displayDataArray[pindex].photoArr[index].reviewerAction = status;
              this.displayDataArray[pindex].photoArr[index].reviewedBy = this.sharedservice.userType.name
              this.displayDataArray[pindex].photoArr[index].reviewerModerationTime = currenttime;
            } else {
              this.displayDataArray[pindex].photoArr[index].supervisorAction = status;
              this.displayDataArray[pindex].photoArr[index].moderatedBy = this.sharedservice.userType.name;
              this.displayDataArray[pindex].photoArr[index].supervisorModerationTime = currenttime;
            }


          } else if (type === 'caption') {
            if (!this.isReviewer && status) {
              action.descriptionunmoderated = null;
              this.displayDataArray[pindex].photoArr[index].descriptionunmoderated = null;
              this.displayDataArray[pindex].photoArr[index].descriptionLeftBtnColor = 'green';
              this.displayDataArray[pindex].photoArr[index].descriptionRightBtnColor = 'white';
            } else if (!this.isReviewer && status === false) {
              action.descriptionunmoderated = null;
              this.displayDataArray[pindex].photoArr[index].descriptionunmoderated = null;
              this.displayDataArray[pindex].photoArr[index].descriptionLeftBtnColor = 'white';
              this.displayDataArray[pindex].photoArr[index].descriptionRightBtnColor = 'red';
            } else if (this.isReviewer && status) {
              this.displayDataArray[pindex].photoArr[index].descriptionLeftBtnColor = 'purple';
              this.displayDataArray[pindex].photoArr[index].descriptionRightBtnColor = 'white';
            } else {
              this.displayDataArray[pindex].photoArr[index].descriptionLeftBtnColor = 'white';
              this.displayDataArray[pindex].photoArr[index].descriptionRightBtnColor = 'gray';
            }
          }
          this.displayDataArray[pindex].photoArr[index].disablePhotoApproveButton  = false;
        }
      },
        (err: HttpErrorResponse) => { // HttpErrorResponse for checking errors and we will show a msg to users
          if (err.status === 401) {
            this.localservice.removeToken('user');
            this.router.navigate(['/login']);
          } else if (err.status >= 500) {
            //  this.sharedservice.gettingServerError();
          }
        });
    }
  }

  saveCaption(pindex, e, item) {
    // item.description = e.target.innerText;
    // this.changeStatus(pindex, this.captionEditIndex, true, item, 'caption');
    // this.captionEditIndex = -1;
    this.tempCaption = e.target.innerText;
  }

  getUserPhotos(data, profileimage, pindex) {
    const userPhotosArr = data;
    const photoArr = [];
    let photoCount = 0;
    if (userPhotosArr) {
      userPhotosArr.forEach(photos => {
        //  console.log(photos);
        if (photos.imageid === profileimage) {
          photos.markasprofile = true;
        }
        photos.disablePhotoApproveButton = false;
        // Dont act overSmart else If both Conditions are required.
        // If you think it can de done by taking first condition then read above line
        if (photos.supervisorAction && !photos.unmoderated) {
          photos.topBtnColor = 'green';
          photos.bottomBtnColor = 'white';
          photos.checkActionTaken = true;
        } else if (photos.supervisorAction === false && !photos.unmoderated) {
          photos.topBtnColor = 'white';
          photos.bottomBtnColor = 'red';
          photos.checkActionTaken = true;
        } else if (photos.reviewerAction === true && photos.unmoderated) {
          photos.topBtnColor = 'purple';
          photos.bottomBtnColor = 'white';
          photos.checkActionTaken = true;
        } else if (photos.reviewerAction === false && photos.unmoderated) {
          photos.topBtnColor = 'white';
          photos.bottomBtnColor = 'gray';
          photos.checkActionTaken = true;
        } else {
          photos.topBtnColor = 'orange';
          photos.bottomBtnColor = 'orange';
          photos.checkActionTaken = false;
        }
        /////////////////////////////////////
        // We dont have a reject Caption functionality in existing CRM
        // So here I code it if API build to reject the caption according to that
        //
        if (!photos.descriptionunmoderated && photos.description) {
          photos.descriptionLeftBtnColor = 'green';
          photos.descriptionRightBtnColor = 'white';
          // Yeah I know both condition are same
          // Condition will change when we have a API to reject the caption
        } else if (!photos.descriptionunmoderated && photos.description) {
          photos.descriptionLeftBtnColor = 'white';
          photos.descriptionRightBtnColor = 'red';
        } else if (photos.descriptionunmoderated && photos.description1) {
          photos.descriptionLeftBtnColor = 'purple';
          photos.descriptionRightBtnColor = 'white';
        } else if (photos.descriptionunmoderated && photos.description1) {
          photos.descriptionLeftBtnColor = 'white';
          photos.descriptionRightBtnColor = 'gray';
        } else {
          photos.descriptionLeftBtnColor = 'orange';
          photos.descriptionRightBtnColor = 'orange';
        }

        const photoData = { ...photos };
        if (photos.imagename) {
          if (photos.imagename.includes('deploma')) {
            photoData.imageType = 'deploma';
          } else if (photos.imagename.includes('photoid')) {
            photoData.imageType = 'photoid';
          } else if (photos.imagename.includes('employeeId')) {
            photoData.imageType = 'employeeId';
          } else {
            photoData.imageType = 'image';
            photoCount++;
          }
        } else {
          photoData.imageType = 'image';
          // photoCount++;
        }
        photoData.totalPhotos = photoCount;
        setTimeout(() => {
          const img = new Image();
          img.src = photos.imageurl;
          img.onload = function () {
            photoData.tempheight = img.height;
            photoData.tempwidth = img.width;
          };
        });
        if(photoData.topBtnColor == "orange" && photos.bottomBtnColor == "orange"){
          this.displayDataArray[pindex].countUnapprovedPhotos++;
          photoArr.splice(0, 0, photoData);
        } else{
          photoArr.push(photoData);
        }
      });
    }
    return photoArr;
  }

  changeMind(pindex, e, item) {
    e.target.innerText = item.description;
    this.displayDataArray[pindex].captionEditIndex = -1;
  }

  getUserVideo(data) {
    const video = data[0] ? data[0] : null;
    if (video) {
      video.disabledselfieVideoButton = false;
      if (video.supervisorAction && !video.unmoderated) {
        video.topBtnColor = 'green';
        video.bottomBtnColor = 'white';
      } else if (video.supervisorAction === false && !video.unmoderated) {
        video.topBtnColor = 'white';
        video.bottomBtnColor = 'red';
      } else if (video.reviewerAction === true && video.unmoderated) {
        video.topBtnColor = 'purple';
        video.bottomBtnColor = 'white';
      } else if (video.reviewerAction === false && video.unmoderated) {
        video.topBtnColor = 'white';
        video.bottomBtnColor = 'gray';
      } else {
        video.topBtnColor = 'orange';
        video.bottomBtnColor = 'orange';
      }
      video.declineReasonsByReviewer = video.reason;
      video.declineReasonsBySupervisor = video.declinereason;
    }

    //  console.log(video);
    return video;
    // console.log(this.declineReasonsBySupervisor);
  }

  userVideoAction(pindex, userId, status) {
    this.displayDataArray[pindex].video.disabledselfieVideoButton = true;
    const params = {
      approve: status,
      declinereason: null,
      userid: userId
    };
    params.declinereason = status ? null : (this.sharedservice.userType.isReviewer ? this.displayDataArray[pindex].video.declineReasonsByReviewer : this.displayDataArray[pindex].video.declineReasonsBySupervisor);
    const action = this.displayDataArray[pindex]?.video;
    // console.log(action);

    if (action.unmoderated) {
      this.apiservice.videoModeration(params).subscribe(res => {
        if (!this.isReviewer && status) {
          //  console.log('reviewer false status true');
          this.displayDataArray[pindex].isvideoDecline = false;
          action.supervisorAction = status;
          this.displayDataArray[pindex].video.supervisorAction = status;
          action.unmoderated = null;
          this.displayDataArray[pindex].video.unmoderated = null;
          this.displayDataArray[pindex].video.topBtnColor = 'green';
          this.displayDataArray[pindex].video.bottomBtnColor = 'white';
        } else if (!this.isReviewer && status === false) {
          // console.log('reviewer false status false');
          action.supervisorAction = status;
          this.displayDataArray[pindex].video.supervisorAction = status;
          action.unmoderated = null;
          this.displayDataArray[pindex].video.unmoderated = null;
          action.declinereason = this.displayDataArray[pindex].video?.declineReasonsBySupervisor;
          this.displayDataArray[pindex].video.declinereason = this.displayDataArray[pindex].video?.declineReasonsBySupervisor;
          this.displayDataArray[pindex].video.topBtnColor = 'white';
          this.displayDataArray[pindex].video.bottomBtnColor = 'red';
        } else if (this.isReviewer && status) {
          //  console.log('reviewer true status true');
          this.displayDataArray[pindex].isvideoDecline = false;
          action.reviewerAction = status;
          this.displayDataArray[pindex].reviewerAction = status;
          this.displayDataArray[pindex].video.topBtnColor = 'purple';
          this.displayDataArray[pindex].video.bottomBtnColor = 'white';
        } else {
          action.reviewerAction = status;
          this.displayDataArray[pindex].video.reviewerAction = status;
          this.displayDataArray[pindex].video.topBtnColor = 'white';
          this.displayDataArray[pindex].video.bottomBtnColor = 'gray';
          action.reason = this.displayDataArray[pindex].video?.declineReasonsByReviewer;
        }
        if (this.sharedservice.userType.isReviewer) {
          this.displayDataArray[pindex].video.reviewedBy = this.sharedservice.userType.name
          this.displayDataArray[pindex].video.reviewerModerationTime = Date.now();
        } else {
          this.displayDataArray[pindex].video.moderatedBy = this.sharedservice.userType.name;
          this.displayDataArray[pindex].video.supervisorModerationTime = Date.now();
        }
        this.displayDataArray[pindex].video.disabledselfieVideoButton = false;
        
      },
        (err: HttpErrorResponse) => { // HttpErrorResponse for checking errors and we will show a msg to users
          if (err.status === 401) {
            this.localservice.removeToken('user');
            this.router.navigate(['/login']);
          } else if (err.status >= 500) {
            //  this.sharedservice.gettingServerError();
          }
        });
    }
  }

  declineDialog(pindex, userId, param) {
    // console.log(pindex);
    // console.log(param);

    const action = this.displayDataArray[pindex].video;
    //  console.log(action);

    if (action.unmoderated || param) {
      const dialog = this.dialog.open(DeclineReasonDialogComponent, {
        autoFocus: false,
        maxHeight: '90vh',
        maxWidth: '50vw',
        data: { videoData: this.displayDataArray[pindex].video, readmore: param }
      });

      dialog.afterClosed().subscribe(result => {
        if (result) {
          if (!this.sharedservice.userType.isReviewer) {
            this.displayDataArray[pindex].video.declineReasonsBySupervisor = result;
          } else {
            this.displayDataArray[pindex].video.declineReasonsByReviewer = result;
          }
          this.userVideoAction(pindex, userId, false);
        }
      });
    }
  }

  openPhotoDeclineDialog(pindex, index, status, item, type, openD) {
    if (openD || item.unmoderated) {
      const dialog = this.dialog.open(DeclineReasonsPhotosDialogComponent, {
        autoFocus: false,
        maxHeight: '90vh',
        maxWidth: '50vw',
        data: { data: item, readmore: openD }
      });

      dialog.afterClosed().subscribe(result => {
        if (result) {
          if (!this.sharedservice.userType.isReviewer) {
            item.declinereason = result;
          } else {
            item.reason = result;
            //  console.log(item);
          }
          this.changeStatus(pindex, index, status, item, type);
          //   console.log(result);
          // this.userVideoAction(false);
        }
      });
    }
  }
  // talked user toggle 
  istalkedUser(pindex, userId, e) {
    //console.log(e);
    if (e.checked) {
      this.apiservice.adminverifyphone(userId).subscribe(res => {
        this.displayDataArray[pindex].isTalkedUser = true;
        // console.log(res);

      },
        (err: HttpErrorResponse) => { // HttpErrorResponse for checking errors and we will show a msg to users
          if (err.status === 401) {
            this.localservice.removeToken('user');
            this.router.navigate(['/login']);
          } else if (err.status >= 500) {
            //  this.sharedservice.gettingServerError();
          }
        })
    }

  }
  // profile value 
  profileValue(userId, property, value) {
    const param: any = {
      userid: userId
    };
    if (property === 'quality') {
      param.value = Number(value);
      this.apiservice.setProfileQuality(param).subscribe(res => {
        // console.log(res);

      },
        (err: HttpErrorResponse) => { // HttpErrorResponse for checking errors and we will show a msg to users
          if (err.status === 401) {
            this.localservice.removeToken('user');
            this.router.navigate(['/login']);
          } else if (err.status >= 500) {
            //  this.sharedservice.gettingServerError();
          }
        });
    } else if (property === 'diamond') {
      param.elite = Number(value);
      param.upgrade = 0;
      this.upgradeApi(param);
    } else if (property === 'upgrade') {
      param.upgrade = Number(value);
      this.upgradeApi(param);
    } else if (property === 'acProspect') {
      param.value = value;
      this.apiservice.isAssistedConnectUser(param).subscribe(res => {
        //  console.log(res);

      },
        (err: HttpErrorResponse) => { // HttpErrorResponse for checking errors and we will show a msg to users
          if (err.status === 401) {
            this.localservice.removeToken('user');
            this.router.navigate(['/login']);
          } else if (err.status >= 500) {
            //  this.sharedservice.gettingServerError();
          }
        })
    }

  }
  upgradeApi(param) {
    this.apiservice.probabilityToUpgrade(param).subscribe(res => {
      //  console.log(res);
    },
      (err: HttpErrorResponse) => { // HttpErrorResponse for checking errors and we will show a msg to users
        if (err.status === 401) {
          this.localservice.removeToken('user');
          this.router.navigate(['/login']);
        } else if (err.status >= 500) {
          //  this.sharedservice.gettingServerError();
        }
      });
  }
  // navigate to profile view from alias 
  navigateToProfileView(userId) {
    const url = '/admin/profile?userid=' + userId;
    window.open(url, '_blank');
  }

  openNav() {
    document.getElementById("mySidepanel").style.width = "300px";
  }

  closeNav() {
    document.getElementById("mySidepanel").style.width = "0";
  }

  openFullViewImage(imgUrl) {
    //console.log("dailog open");

    const photoGuideDialog = this.dialog.open(FullPhotoviewDialogComponent, {
      panelClass: 'dialog-container-photoview',
      height: '100vh',
      width: '100vw',
      autoFocus: false,
      data: { image: imgUrl },
      backdropClass: 'dialog-bg-trans'
    });

    photoGuideDialog.afterClosed().subscribe(result => {
      if (result) {
        // console.log('successful');
      }
    });



  }
}
