<div class="row mx-0 bgimg">
    <div class="col-12 px-0">
      <!-- logo -->
        <div class="row mx-0">
            <img src="/assets/images/static/jodi365-logo-white-line.png" class="img-fluid logo ml-5 m-3" alt="logo">
        </div>

        <div class="row mx-0 justify-content-center">
            <div class="col-5 px-0 bg-white text-center mat-elevation-z5 form-area">
                <div class="oswald j-h2 c-darkgray pt-5">
                    Forgot your password? No worries!
                </div>
                <div class="opensans-light pt-4 c-darkgray">
                    Enter your email address and<br> we'll send you a link to reset your password.
                </div>

                <!-- form -->
                <div class="px-4" *ngIf="!checkEmail">
                    <form [formGroup]="forgotPass" (ngSubmit)="OnSubmitForgot()">
                        <!-- email field -->
                        <mat-form-field appearance="outline" class="px-3 w-100 pt-4">
                          <mat-label class="c-darkgray body-subtext">Email address*</mat-label>
                            <input matInput email formControlName="Emailforgot" maxlength="50" autocomplete="off" (blur)="onBlurForgetPswdMethod()" style="font-size:18px;">
                            <mat-error *ngIf="forgotPass.get('Emailforgot').hasError('email') && !forgotPass.get('Emailforgot').hasError('required')" class="mt-1 c-alert-danger mat-error">
                                <span class="fa fa-exclamation-triangle"> </span> Email address is invalid
                            </mat-error>
                            <mat-error *ngIf="forgotPass.get('Emailforgot').hasError('required')" class="mt-1 c-alert-danger mat-error">
                                <span class="fa fa-exclamation-triangle"> </span> Email address is required
                            </mat-error>
                            <mat-error *ngIf="forgotPass.get('Emailforgot').hasError('maxlength')" class="mt-1 c-alert-danger mat-error">
                                <span class="fa fa-exclamation-triangle"> </span> Max length 50 characters exceed
                            </mat-error>
                            <mat-error *ngIf="forgotPass.get('Emailforgot').hasError('doesemailexist')" class="mt-1 c-alert-danger mat-error">
                                <span class="fa fa-exclamation-triangle"> </span> There is no Jodi365 account associated with this email
                            </mat-error>
                        </mat-form-field>
    
                        <div class="px-2 pt-2">
                            <button mat-raised-button class="w-50 bg-btn-primary text-white opensans-regular" >SEND ME THE LINK
                              <span>
                                <mat-spinner diameter="18" class="spinner mat-spinner-color" *ngIf="awaitResponse"></mat-spinner>
                              </span>
                            </button>
                        </div>
                        <div class="opensans-light smaller-text c-darkergray pt-3">
                            <i>Instructions for setting your new password will be emailed to you instantly.</i>
                        </div>
    
                        <div class="c-darkgray pt-4">
                            <button mat-button="" routerLink="/login" tabindex="0" class="mat-button mat-button-base">
                                Remember your password?<span class="opensans-regular c-btn-primary"> LOG IN</span>
                            </button>
                        </div>
                    </form>
                </div>
                
                <div class="col-12 p-lg-3 p-xl-3 text-center pt-1 " *ngIf="checkEmail">

                    <div class="oswald j-h3 pb-3 text-center">Check {{' ' + userEmail}}</div>

                    <div class="body-subtext  text-center">
                        You will receive an email shortly,
                    </div>
                    <div class="body-subtext pb-3 text-center">
                        with a link to reset your password.
                    </div>
                    <div class="body-subtext  text-center">
                        For security reasons,
                    </div>
                    <div class="body-subtext  text-center">
                        that link expires in 24 hours.
                    </div>
                    <div class="body-subtext  text-center">
                        So, don't delay in resetting your password.
                    </div>
                    <div class="row mx-0 text-center pt-2">
                        <div class="col-12 text-center body-subtext c-gray pt-2" *ngIf="showTimer">
                            <span class="small-text" id="timer">{{ (60 - (curSec)) + 's'}}</span>
                            <span class="">
                                <mat-spinner class="mx-auto prog-spin mat-spinner-color"  mode="determinate" diameter="33"
                                [value]="progressbarValue"></mat-spinner>
                            </span>
                        </div>

                        <div class="col-12" *ngIf="showResend">
                            <button mat-button class="c-btn-primary md-btn text-uppercase" (click)=" OnSubmitForgot()">
                                RESEND THE LINK
                            </button>
                        </div>

                       
                        <!-- <div class="col-12 pt-2 text-center" *ngIf="showContact">
                            <button mat-button="" routerLink='/forgot-password/contact-us' tabindex="0" class="mat-button mat-button-base"><span class="mat-button-wrapper"> Didn't receive the email? <span class="c-btn-primary">CONTACT US</span></span>
                            <div class="mat-button-ripple mat-ripple" matripple=""></div>
                            <div class="mat-button-focus-overlay"></div>
                            </button>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>

    </div>  
</div>