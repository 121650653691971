import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { async } from 'rxjs/internal/scheduler/async';
import { DataService } from 'src/app/services/data.service';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-decline-reasons-photos-dialog',
  templateUrl: './decline-reasons-photos-dialog.component.html',
  styleUrls: ['./decline-reasons-photos-dialog.component.css']
})
export class DeclineReasonsPhotosDialogComponent implements OnInit {

  // declineReasonsArr = [
  //   {
  //     reason:
  //       'The photo seems to be of someone else. Please contact the Jodi365 Help Desk to assure us that your photo is genuine.',
  //     isActive: false,
  //   },
  //   {
  //     reason: 'The photo contains inappropriate content.',
  //     isActive: false,
  //   },
  //   {
  //     reason: 'The photo consists of copyrighted content.',
  //     isActive: false,
  //   },
  //   {
  //     reason: 'The photo contains potentially offensive content.',
  //     isActive: false,
  //   },
  //   {
  //     reason: 'The photo contains frivolous content or gibberish.',
  //     isActive: false,
  //   },
  //   {
  //     reason: 'The photo is not clear enough.',
  //     isActive: false,
  //   },
  //   {
  //     reason: 'The photo does not show your face.',
  //     isActive: false,
  //   },
  //   {
  //     reason: 'The photo does not show your full face.',
  //     isActive: false,
  //   },
  //   {
  //     reason:
  //       'No side photos, please. At least one photo must show your full face, clearly.',
  //     isActive: false,
  //   },
  //   {
  //     reason:
  //       'At least one photo must show your full face, clearly, without sunglasses',
  //     isActive: false,
  //   },
  //   {
  //     reason: 'The photo is not upright. Please rotate it and re-submit.',
  //     isActive: false,
  //   },
  //   {
  //     reason:
  //       'The photo seems to be a duplicate (of a previously submitted photo).',
  //     isActive: false,
  //   },
  //   {
  //     reason:
  //       'This photo is too similar to another photo that you have uploaded.',
  //     isActive: false,
  //   },
  //   {
  //     reason:
  //       'The photo shows other individuals\' faces. Even group photos are not allowed, out of respect for others\' privacy.',
  //     isActive: false,
  //   },
  //   {
  //     reason:
  //       'The photo may turn some of your prospects off. Please upload a better photo so that your profile makes a better first impression.',
  //     isActive: false,
  //   },
  //   {
  //     reason:
  //       // tslint:disable-next-line: max-line-length
  //       'The photo may turn some of your prospects off. Be yourself, of course, but we discourage pictures that are too posey, stilted, or serious.',
  //     isActive: false,
  //   },
  //   {
  //     reason:
  //       'The photo contains a photographer or photo studio\'s name. If you like the photo, please blur or crop the name out and re-submit.',
  //     isActive: false,
  //   },
  //   {
  //     reason:
  //       'The photo contains another site\'s watermark. Please remove the watermark or submit another photo.',
  //     isActive: false,
  //   },
  //   {
  //     reason:
  //       'The photo/caption includes contact information (phone numbers, email addresses, social media usernames, web site URLs, etc.).',
  //     isActive: false,
  //   },
  //   {
  //     reason:
  //       // tslint:disable-next-line: max-line-length
  //       'The photo/caption contains references to identifying information (your name, biography, social media accounts, web site URLs, vehicle license plates, street addresses, etc.).',
  //     isActive: false,
  //   },
  //   {
  //     reason:
  //       'This looks like a childhood photo. Please upload a recent photo.',
  //     isActive: false,
  //   },
  //   {
  //     reason: 'This looks like an old photo. Please upload a recent photo.',
  //     isActive: false,
  //   },
  //   {
  //     reason:
  //       // tslint:disable-next-line: max-line-length
  //       'The photo you uploaded happens to be publicly visible online. For your security, please upload a photo that hasn\'t been uploaded on any social media account and isn\'t otherwise searchable on the web.',
  //     isActive: false,
  //   },
  // ];
  declineReasonsArr = [];
  declinePhotoIdReasonsArr = [];
  declineInputReason = new UntypedFormControl();
  selectedReasons = {};
  phtoDescription;
  selDeclineReasonsBySupervisor;
  selDeclineReasonsByReviewer;
  showReasons;
  disableDeclineButton = false;
  // tslint:disable-next-line: max-line-length
  constructor(private dataservice: DataService,public dialogRef: MatDialogRef<DeclineReasonsPhotosDialogComponent>, @Inject(MAT_DIALOG_DATA) public data, private sharedservice: SharedService) { }

  ngOnInit(): void {
    console.log(this.data.data);
    this.phtoDescription = this.data.data;
    this.dataservice.getAllDeclineReason().subscribe((res: any)  => {
      this.declineReasonsArr = res.photodeclineReason;
      this.declinePhotoIdReasonsArr = res.photoIDdeclineReason;

      if(this.phtoDescription.imageType == 'deploma'){
        const regex = /photo ID/gi;
         
        this.declinePhotoIdReasonsArr.forEach((obj) =>{
          obj.reason= obj.reason.replace(regex, 'university diploma');
        })

      }else if(this.phtoDescription.imageType == 'employeeId'){
        const regex = /photo ID/gi;
         
        this.declinePhotoIdReasonsArr.forEach((obj) =>{
          obj.reason= obj.reason.replace(regex, 'employee ID');
        })

      }
    });
     this.declineInputReason.valueChanges.subscribe(x => {
      if(x.length > 0){
        this.disableDeclineButton= true;
      }else {
        this.disableDeclineButton= false;
      }
      
    });

     
    // this.declinePhotoIdReasonsArr.forEach((obj) =>{
    //   if(obj.isActive === false){
    //     this.disableDeclineButton = true;
    //   }else{
    //     this.disableDeclineButton = false;
    //   }
    // })
   
    this.selDeclineReasonsBySupervisor = this.phtoDescription.declinereason?.split('; ');
    this.selDeclineReasonsByReviewer = this.phtoDescription.reason?.split('; ');
    // tslint:disable-next-line: max-line-length
    this.showReasons = this.sharedservice.isReviewer && this.phtoDescription.unmoderated && this.phtoDescription.reviewerAction === null ? true : this.phtoDescription.unmoderated && !this.sharedservice.isReviewer && !this.data.readmore ? true : false;
  }

  selDeclineReasonsPhoto(e, item, i) {

    if (e.checked) {
      this.disableDeclineButton = true;
      this.selectedReasons[i] = item.reason;
    } else {
      delete this.selectedReasons[i];
      if(Object.keys(this.selectedReasons).length === 0){
        this.disableDeclineButton = false;
      }
    }
    
   // console.log(this.selectedReasons);
  }

  sendDataBack() {
    let reasons = null;
    console.log(this.selectedReasons);
    for (const key in this.selectedReasons) {
      if (!reasons) {
        reasons = this.selectedReasons[key];
      } else {
        reasons += '; ' + this.selectedReasons[key];
      }
    }
    console.log(this.declineInputReason);
    if (this.declineInputReason.value) {
      if (reasons) {
        reasons += '; ' + this.declineInputReason.value;
      } else {
        reasons = this.declineInputReason.value;
      }
    }
    this.dialogRef.close(reasons);
  }
}
