<div class="row mx-0" *ngIf="forWhom == 'noOfRatings'">
    <div class="col-12 px-0 heading-box">
        <div class="row mx-0">
             <div class="col-12 px-0 p-4 opensans-light c-darkgray subheading text-center ">
                <img  class="position-absolute clickable cross-icon-position" (click)="closeDialog()" src="/assets/images/static/close-cross-icon.svg" alt="" height="20" width="20">
                <div>
                    Ratings from <span class="c-darkblue pl-2 opensans-regular">{{alias}}</span>
                </div>
                
             </div>
        </div>
    </div>
    
    <div class="col-12 px-0 p-2">
            <div class="row mx-0 border-bottom" *ngFor="let item of ratingData; let first = first; let last = last" [ngClass]="(first || last) ? 'mt-0' : 'mt-2'">
                <div class="col-3 px-0 p-1 text-center opensans-regular c-darkgray">
                    {{item.timestamp | date: 'mediumDate' }}<br>{{item.timestamp | date: 'mediumTime' }}
                </div>
                <div class="col-1 px-0 p-1 c-darkgray opensans-regular" [ngClass]="item.reason ? 'pt-3' : 'pt-1'">
                    {{item.rating}} 
                </div>
                <div class="col-8 px-0 p-1 c-darkgray" style=" word-wrap: break-word;">
                    {{item.reason ? item.reason : null }}
                </div>
            </div>
    </div>
</div>


<!-- for read more functionality -->
<div class="row mx-0"  *ngIf ="forWhom == 'comment'">
    <div class="col-12 px-0 heading-box">
        <div class="row mx-0">
             <div class="col-12 px-0 p-4 opensans-light c-darkgray subheading text-center ">
                <img  class="position-absolute clickable cross-icon-position" (click)="closeDialog()" src="/assets/images/static/close-cross-icon.svg" alt="" height="20" width="20">
                <div>
                    Ratings from <span class="c-darkblue pl-2 opensans-regular">{{alias}}</span>
                </div>
                
             </div>
        </div>
    </div>
    <div class="col-12 px-3 c-darkgray opensans-light mb-4" *ngIf="reason" style="word-wrap: break-word;">
        {{reason}}
    </div>
</div>