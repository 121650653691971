<div class="row mx-0 bg-color">
    <div class="col-12 px-0" [ngClass]="afterSearchResultData ? 'afterSearchClass' : '' ">
        <!-- search bar -->
        <div class="row mx-0 justify-content-center pt-1">
            <div class="col-6 px-0">
                <img class="search-button rounded-left clickable" src="../../../assets/images/static/search-white-icon.svg" (click)= "searchByUserIdData(userIdValue.value)" height="40px" alt="">
                <input #userIdValue class="input-box-field rounded-right" (keyup.enter)= "searchByUserIdData(userIdValue.value)" placeholder="Enter user id">
            </div>
        </div>

        <!-- table data-->
        <div class="row mx-0 pt-4 pb-3 justify-content-center">
            <table class="table-size">
                <thead class="div-sticky" *ngIf="apiresponce">
                    <tr class="oswald c-darkgray body-subtext text-center" style="background-color: #e1effb;">
                        <th class="py-3 sno-width">S.No.</th>
                        <th class="">
                            <div class="col text-center">
                                <span class="pr-2">Date</span>
                                <img *ngIf="sortingDateToggle" class="text-right clickable" (click)="sorting('desc','Date')" src="../../../assets/images/static/arrow-up-blue.svg" alt="" height="17px" width="17px">
                                <img *ngIf="!sortingDateToggle" class="text-right clickable" (click)="sorting('asce','Date')" src="../../../assets/images/static/arrow-down-blue.svg" alt="" height="17px" width="17px">
                            </div>
    
                        </th>
                        <th class="userid-width">User ID</th>
                        <th class="alias-width">Profile ID</th>
                        <th class="">
                            <div class="col text-center">
                                <span class="pr-2">No. of Ratings</span>
                                <img *ngIf="sortingNoofRatingToggle" class="text-right clickable" (click)="sorting('desc','noOfRating')" src="../../../assets/images/static/arrow-up-blue.svg" alt="" height="17px" width="17px">
                                <img *ngIf="!sortingNoofRatingToggle" class="text-right clickable" (click)="sorting('asce','noOfRating')" src="../../../assets/images/static/arrow-down-blue.svg" alt="" height="17px" width="17px">
                            </div>
                        </th>
                        <th class="">
                            <div class="col text-center">
                                <span class="pr-2">Rating</span>
                                <img *ngIf="sortingRatingToggle" class="text-right clickable" (click)="sorting('desc','rating')" src="../../../assets/images/static/arrow-up-blue.svg" alt="" height="17px" width="17px">
                                <img *ngIf="!sortingRatingToggle" class="text-right clickable" (click)="sorting('asce','rating')" src="../../../assets/images/static/arrow-down-blue.svg" alt="" height="17px" width="17px">
                            </div>
                        </th>
                        <th class="p-2 comment-width text-left">Comment</th>
                    </tr>
                </thead>
                
                <!-- whole data of table -->
                <tbody class="opensans-regular c-darkgray small-text text-center" *ngIf="apiresponce">
                    <tr *ngFor="let item of ratingArray; let i = index" [ngStyle]="{'background-color': (i+1)%2==0 ? '#f2f2f2' : '#ffffff'}">
                        <td class="py-3" style="box-shadow: 0 0 3px 0 #d1d1d1;">{{i+1}}</td>
                        <td><span>{{item.lastUpdate | date: 'mediumDate'}} <br>{{item.lastUpdate | date: 'mediumTime'}}</span> </td>
                        <td class="clickable userIdcolor" (click)="navigateToProfileView(item.userid)">{{item.userid}}</td>
                        <td class="clickable userIdcolor" (click)="navigateToProfileView(item.userid)">{{item.alias}}</td>
                        <td>
                            <span class="clickable userIdcolor" *ngIf="item.numberofRating > 1" (click)= "viewAlltheRatingsAndReasons('noOfRatings', item.ratingList, item.alias, item.lastComment)">
                                {{item.numberofRating}}
                            </span>
                            <span class="c-darkgray" *ngIf="item.numberofRating < 2">
                                {{item.numberofRating}}
                            </span>  
                        </td>
                        <td>{{item.lastRating}}</td>
                        <td class="text-left p-2 opensans-light">
                            <div *ngIf="item.lastComment && item.lastComment.length > 300" class="clickable" (click)="viewAlltheRatingsAndReasons('comment', item.ratingList, item.alias, item.lastComment)" style="width: 40vw; word-wrap: break-word;">{{item.shortReason ? item.shortReason : null }}
                                <span class="c-btn-primary opensans-regular">
                                ...READ MORE
                                </span>
                            </div>
                            <div *ngIf="item.lastComment && item.lastComment.length <= 300" style="width: 40vw; word-wrap: break-word;">
                            {{item.shortReason ? item.shortReason : null }}
                            </div>
                        </td>
                    </tr>
                </tbody>

                <!-- sklenton -->
                <div class="col-12 px-0">
                    <div class="row mx-0 justify-content-center ph-item" *ngIf="!apiresponce" >
                        
                        <div class="col-12 px-0 mat-elevation-z5" style="height:50px;"></div>
                        <div class="col-12 px-0 mat-elevation-z5 skelton-class" ></div>
                        <div class="col-12 px-0 mat-elevation-z5 skelton-class" ></div>
                        <div class="col-12 px-0 mat-elevation-z5 skelton-class" ></div>
                        <div class="col-12 px-0 mat-elevation-z5 skelton-class" ></div>
                        <div class="col-12 px-0 mat-elevation-z5 skelton-class" ></div>
                        <div class="col-12 px-0 mat-elevation-z5 skelton-class" ></div>
                        <div class="col-12 px-0 mat-elevation-z5 skelton-class" ></div>
                        <div class="col-12 px-0 mat-elevation-z5 skelton-class" ></div>
                        <div class="col-12 px-0 mat-elevation-z5 skelton-class" ></div>
                        
                    </div>
                </div>
                
            </table> 
        </div>
        
        <!-- Empty Result -->
        <div class="col-12 px-0 pt-3 text-center oswald c-darkgray bg-color" style="font-size: 20px;height: 80vh;" *ngIf="afterSearchResultEmpty">
            Result not found
        </div>

        <div class="col-12 px-0 pt-3 text-center oswald c-darkgray bg-color" style="font-size: 20px; height:80vh;" *ngIf="searchStringType">
            Only userid allowed
        </div>

        <!-- show more -->
        <div class="row mx-0 py-3" *ngIf="showMoreButton && apiresponce">
            <div class="col-12 px-0 text-center">
                <button class="showMoreButtonBgColor" mat-button (click)="showMoreData()" [disabled]="disableShowMoreButton">SHOW MORE</button>
            </div>
        </div>
    </div>
</div>
