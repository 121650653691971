<div class="row mx-0 bg-element justify-content-center">
    <div class="col-11 px-0 ">
        <div class="row mx-0">
            <div class="col-12 px-0 p-2 mt-3">
                <div class="fa fa-times-thin fa-3x float-right" style="color: #a369e8;" mat-dialog-close></div>
                <div class="c-darkgray oswald subheading">Send Email</div>
            </div>
            <div class="col-12 px-0">

                <div class="p-2">
                    <span><img class="rounded-circle mr-2 mb-2" [src]="userProfileImg" alt="profileImg" height="30" width="30"></span>
                    <span class="c-darkgray oswald body-subtext">{{alias}}</span>
                    <span class="opensans-light c-darkgray small-text">  #{{userId}}</span>
                </div>
            </div>

            <div class="col-12 px-0 pb-2">
                <div class="row mx-0">
                    <div class="col-2 px-0">
                        <label  class="text-center pl-2 pt-2" for="emailtemp">Template</label>
                    </div>
                    <div class="col-10 px-0">
                        <select class="form-control" id="option" [(ngModel)]="selectedOptions" name="option_name" (change) = "selectedEmailTemp(selectedOptions)" required>
                            <option value='' selected hidden>Select</option>
                            <option *ngFor="let item of userData;" [value]="item.option" >{{item.option}}</option>
                            <!-- <option value="fake">fake profile</option> -->
                        </select>
                        <div *ngIf="isSelectData" class="fa fa-exclamation-triangle  mt-1 c-alert-danger"> Template is required</div>
                    </div>
                </div>
            </div> 
            <div class="col-12 px-0">
                <form [formGroup] = "emailTemp" (ngSubmit)="onSubmit()">
                    <div class="col-12 px-0"> 
                        <div class="row mx-0">
                            <div class="col-2 px-0">
                                <label  class="text-center pl-2 pt-2" for="emailtemp">CC</label>
                            </div>
                            <div class="col-10 px-0"> 
                                <input class="w-100 p-2 input-element" name="ccmail" type="email" formControlName="ccmail" (blur)="isValidccmail()">
                                <div *ngIf="ccmailError" class="fa fa-exclamation-triangle  mt-1 c-alert-danger"> Please enter a valid email address</div>
                             
                            </div>
                        </div>
                        <div class="row mx-0 pt-2">
                            <div class="col-2 px-0">
                                <label  class="text-center pl-2 pt-2" for="emailtemp">BCC</label>
                            </div>
                            <div class="col-10 px-0">
                                <input class="w-100 p-2 input-element" name="bccmail" type="email" formControlName="bccmail" (blur)="isValidbccmail()">
                                <div *ngIf="bccmailError" class="fa fa-exclamation-triangle mt-1 c-alert-danger"> Please enter a valid email address</div>   
                            </div>
                        </div>
                        <div class="row mx-0 pt-2">
                            <div class="col-2 px-0">
                                <label  class="text-center pl-2 pt-2" for="emailtemp">Subject Line</label>
                            </div>
                            <div class="col-10 px-0">
                                <input class="w-100 p-2 input-element" type="text" formControlName="subjectLine">
                            </div>
                        </div>
                        <div class="row mx-0 pt-2">
                            <div class="col-12 px-0 pt-2">
                                <div class="smaller-text float-right " (click)="clearData()" style="color: #a369e8;"> <span class="clickable">CLEAR ALL</span></div>
                                <div>
                                    <textarea rows="12" type="text" class="w-100 p-2 input-element" id="emailTemp" name="content"  required (blur) = "getTextEmail()" formControlName="content"></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="row mx-0 justify-content-center pt-2 pb-2">
                            <div class="col-12 px-0 text-center">
                                <button mat-stroked-button mat-dialog-close class="body-subtext" style="border: none; background-color: transparent;">CANCEL</button>
                                <button mat-raised-button class=" body-subtext bg-btn-primary c-white" [disabled]="disableSendEmailButton">SEND EMAIL</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            
            
           
              
        </div>
        
    </div>
    
</div>