import { Component, OnInit } from '@angular/core';
import { ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  dataSignupCount = [];
  dataAggregateCount = [];
  dataForCalculation = [];
  dataLables = [];
  apiresponse = false;
  public lineChartData: ChartDataSets[] = [
    { data: this.dataSignupCount, label: 'Monthly Sign Up Count' },
    { data: this.dataAggregateCount, label: 'Total Sign Up Count' },
  ];
  
  public lineChartLabels: Label[] = this.dataLables;
    
  public lineChartOptions = {
    responsive: true,
  };
     
  public lineChartLegend = true;
  public lineChartType = 'line';
  public lineChartPlugins = [];
  constructor(private apiService: ApiService) { }



  ngOnInit(): void {
    this.apiService.getonlinusers().subscribe( result => {
      this.dataForCalculation = result;
      this.drawagraph(this.dataForCalculation);
    });

    
  }



  drawagraph (data) {
    for (let i = data.length - 1, j = 0; i >= 0; i--, j++) {
        data[i].month = new Date(data[i].month * 2629743000).getMonth();
        data[0][j] = data[i].signupusercount;
        this.dataSignupCount.push(data[0][j]);
      
        if (j != 0)
            data[1][j] = data[1][j - 1] + data[i].signupusercount;
        else
            data[1][j] = data[i].signupusercount;

        this.dataAggregateCount.push(data[1][j]);
        switch (data[i].month) {
            case 0:
                this.dataLables[j] = "January";
                break;
            case 1:
              this.dataLables[j] = "February";
                break;
            case 2:
              this.dataLables[j] = "March";
                break;
            case 3:
              this.dataLables[j] = "April";
                break;
            case 4:
              this.dataLables[j] = "May";
                break;
            case 5:
              this.dataLables[j] = "Jun";
                break;
            case 6:
              this.dataLables[j] = "July";
                break;
            case 7:
              this.dataLables[j] = "August";
                break;
            case 8:
              this.dataLables[j] = "September";
                break;
            case 9:
              this.dataLables[j] = "October";
                break;
            case 10:
              this.dataLables[j] = "November";
                break;
            case 11:
              this.dataLables[j] = "December";
                break;
        }
    }
    this.apiresponse = true;
}
}



