import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from './services/local-storage.service';
import { SessionStorageService } from './services/session-storage.service';
import { SharedService } from './services/shared.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'adminFrontendV2';
  constructor(private activatedRoute: ActivatedRoute, private router: Router, private sharedservice: SharedService, private localStorage : LocalStorageService, private sessionstorage: SessionStorageService) {
    // const a = this.router.events.pipe(
    //   filter(event => event instanceof NavigationEnd),
    //   map(() => this.activatedRoute.root),
    //   map(root => root.firstChild),
    //   switchMap(firstChild => {
    //     if (firstChild) {
    //       return firstChild.paramMap.pipe(map(paramMap => paramMap.get('userId')));
    //     }
    //   })
    // );
  }
  ngOnInit() {
    const temp : string = window.location.href;
    const nodeId = window.location.href.split('userid=');
    if(temp.search('admin/profile') > -1){
      if(this.sessionstorage.getFromSession('user'+ nodeId[1])){
        this.sessionstorage.removeToken('user'+ nodeId[1]);
        this.sessionstorage.removeToken('user-' + nodeId[1] + '-unmoderatedData');
        this.sessionstorage.removeToken('user' + nodeId[1] + '-rating');
        this.sessionstorage.removeToken('user' + nodeId[1] + '-survey');
      }
    }
  }
}
