<div class="row mx-0 p-3">
    <div class="col-12 px-0">
        <div class="d-flex">
            <div>
              <mat-spinner diameter="30"></mat-spinner>
            </div>
            <div class="mx-3 body-text">
              Loading
            </div>
        </div>
    </div>
</div>
