<div class="row mx-0">
    <div class="col-12">
        <div class="row mx-0 justify-content-center align-items-center" style="height: 100vh;">
            <div class="col-12 text-center">
                <button mat-raised-button color="primary" (click)="navigateToView()">
                    Click to Go Profile View
                </button>
                <a href="https://www.jodi365.com/" (click) ="noHref($event)">Click Me</a>
            </div>
        </div>
    </div>
</div>