<div class="" *ngIf="showReasons else noReasons">
    <h1 *ngIf="phtoDescription.imageType == 'image' " mat-dialog-title class="body-subtext c-darkergray oswald">
        Select one or more reasons for declining the photo:
    </h1>
    <h1 *ngIf="phtoDescription.imageType == 'photoid' " mat-dialog-title class="body-subtext c-darkergray oswald">
        Select one or more reasons for declining the photo ID:
    </h1>
    <h1 *ngIf="phtoDescription.imageType == 'deploma' " mat-dialog-title class="body-subtext c-darkergray oswald">
        Select one or more reasons for declining the university diploma:
    </h1>
    <h1 *ngIf="phtoDescription.imageType == 'employeeId' " mat-dialog-title class="body-subtext c-darkergray oswald">
        Select one or more reasons for declining the employee ID:
    </h1>
  

    <div mat-dialog-content>
        <div class="row mx-0">
            <div class="col-12" *ngIf="phtoDescription.imageType == 'image'">
                <ng-container *ngFor="let item of declineReasonsArr;let i = index">
                    <div class="py-2">
                        <mat-checkbox [checked]="item.isActive" (change)="selDeclineReasonsPhoto($event,item,i)">
                            <span class="c-darkgray opensans-light small-text">{{item.reason}}</span>
                        </mat-checkbox>
                    </div>
                </ng-container>

            </div>
            <div class="col-12"  *ngIf="phtoDescription.imageType != 'image'" >
                <ng-container *ngFor="let item of declinePhotoIdReasonsArr;let i = index">
                    <div class="py-2">
                        <mat-checkbox [checked]="item.isActive" (change)="selDeclineReasonsPhoto($event,item,i)">
                            <span class="c-darkgray opensans-light small-text">{{item.reason}}</span>
                        </mat-checkbox>
                    </div>
                </ng-container>

            </div>
            <div class="col-12">
                <mat-form-field class="w-100">
                    <textarea matInput [formControl]="declineInputReason" class="w-100"
                        placeholder="Any other comments for the user ?"></textarea>
                </mat-form-field>
            </div>
        </div>
    </div>

    <div mat-dialog-actions align="end">
        <button mat-button mat-dialog-close>CLOSE</button>
        <button  [disabled] = "!disableDeclineButton" mat-raised-button color="primary" (click)="sendDataBack()">DECLINE</button>
    </div>
</div>

<ng-template #noReasons>
    <!-- <div class="fa fa-times-thin fa-3x float-right" style="color: #a369e8;" mat-dialog-close></div> -->
    <div mat-dialog-close class="col-12 px-0 p-3">
    <img class="position-absolute clickable cross-icon-position"
    src="/assets/images/static/close-cross-icon.svg" alt="" height="20" width="20">
    </div>
    <div class="" *ngIf="selDeclineReasonsBySupervisor">
        
        <div class="c-darkgray mb-2 text-center j-h2 oswald">
            Reason for photo decline
        </div>
        <div class="body-subtext c-darkergray text-center mt-3">
            By- Supervisor {{phtoDescription.moderatedBy}}
        </div>
        <ul class="text-left mt-3">
            <li class="py-2 opensans-light c-darkgray" style="font-size: large;" *ngFor="let item of selDeclineReasonsBySupervisor">
                {{item}}
            </li>
        </ul>
    </div>
    <div class="" *ngIf="selDeclineReasonsByReviewer">
        <div class=" c-darkgray py-2 text-center  j-h2 oswald">
            Reason for photo decline
        </div>
        <div class="body-subtext c-darkergray text-center mt-3">
            By- Reviewer {{phtoDescription.reviewedBy}}
        </div>
        <ul class="text-left mt-3">
            <li class="py-2 opensans-light c-darkgray" style="font-size: large;" *ngFor="let item of selDeclineReasonsByReviewer">
                {{item}}
            </li>
        </ul>
    </div>
    <div class="text-center">
    <button mat-raised-button mat-dialog-close color="primary" class="">CLOSE</button>
    </div>
</ng-template>