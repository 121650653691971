<div class="row mx-0">
    <!-- Moderation Info -->
    <div class="col-12 px-0 text-left box-shadow-section">
        <div class="row mx-0">
            <div class="col-12 box-shadow-section py-3 clickable" data-toggle="collapse" data-target="#moderation" (click)=" moderationPanelOpen = !moderationPanelOpen">
                <div class="float-right">
                    <img [src]="btnObj.directionBtn" alt="direction-arrow" height="20" width="20" class="transitionClass" [ngStyle]="{'transform': moderationPanelOpen ? 'rotate(90deg)' : 'rotate(0deg)'}">
                </div>
                <div class="oswald c-darkgray subheading text-left">
                    Moderation Info
                </div>
            </div>
            <div class="col-12 py-2 opensans-light collapse show" id="moderation">
                <div class="row mx-0">


                    <!-- first name -->
                    <div class="col-12 px-0 my-3">
                        <div class="oswald c-darkgray body-subtext mb-2">First name <span *ngIf="moderationFirstname.singleTimemoderation == null" class="small-text clickable opensans-light" style="color: #1b75bb;text-decoration: underline;" (click)="goToEditTab()"> <i>(edit history)</i></span> </div>
                        <div class="row mx-0">
                            <div class="col-7 px-0 my-1">
                                <div class="c-darkergray body-subtext" [ngClass]="{'edit-university-company-class': editFirstName}" [attr.contenteditable]="editFirstName" #firstname>
                                    {{moderationFirstname.userFirstName ? moderationFirstname.userFirstName : 'N.A.'}}
                                </div>
                                <div *ngIf="firstnameLenghtExceed" class="mb-2 c-alert-danger smaller-text">
                                    <span class="fa fa-exclamation-triangle"></span> Please stay within the characters limit(20 max).
                                </div>
                            </div>
                            <div class="col-5 px-0" *ngIf="moderationFirstname.userFirstName != 'N.A.'">
                                <div class="row mx-0 align-items-center">
                                    <div class="col-3 px-1">
                                        <button *ngIf="!isReviewer" mat-icon-button matTooltip="Edit" matTooltipClass="toolTipBgColor" class="border-0 bg-white rounded-circle position-relative btn-container">
                                            <img class="center-box" [src]="btnObj.edit" height="25" width="25"
                                                alt="edit-btn" (click)="editFirstNameFunction()">
                                        </button>
                                    </div>
                                    <div class="col-6 px-1">
                                        <button *ngIf="!isReviewer" matRipple class="w-100 pb-1" [disabled]="moderationFirstname.firstNameButtonDisable" (click)="moderateFirstName(true)" [ngClass]="[moderationFirstname.leftBtnColor, 'moderation-word-bg-'+moderationFirstname.leftBtnColor]">
                                            <img *ngIf="moderationFirstname.leftBtnColor !== 'green'"
                                                [src]="moderationFirstname.leftBtnColor === 'green'  ? btnObj.tick.orange : btnObj.tick.white"
                                                alt="btn-tick" height="15" width="15">
                                            <span *ngIf="moderationFirstname.leftBtnColor === 'green'"
                                                matTooltip="APPROVED" matTooltipClass="toolTipBgColor"
                                                class="c-white small-text position-relative d-block collegeBtnText">
                                                APPROVED
                                            </span>
                                        </button>
                                    </div>
                                    <div class="col-3 px-1 text-center">
                                        <button *ngIf="moderationFirstname.valueAcceptedBy" matTooltipClass="toolTipInfoBgColor"
                                        [title]="parsedText(moderationFirstname.valueAcceptedBy)" mat-icon-button class="border-0 bg-white rounded-circle position-relative btn-container">
                                            <img class="center-box" [src]="btnObj.info" height="25" width="25"
                                                alt="edit-btn">
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- College/University -->
                    <div class="col-12 px-0">
                        <div class="oswald c-darkgray body-subtext my-2">College/University</div>
                        <div class="row mx-0" *ngIf="moderationData.academics.length > 0; else noCollege">
                            <ng-container *ngFor="let item of moderationData.academics;let i = index">
                                <div class="col-7 px-0 my-1">
                                    <div class="c-darkergray body-subtext">
                                        {{item.degree}}
                                    </div>
                                    <div *ngIf="item.college != null && item.editUniversity == -1" class="pt-1 c-darkergray small-text">
                                        <div style="border: white;width: 340px;">
                                            <span [matTooltip]="item.college"  matTooltipClass="toolTipBgColor" >{{item.college}}</span>
                                            
                                        </div>
                                    </div>
                                    
                                    <div class="pt-1" *ngIf="item.editUniversity === i">
                                        <input class="edit-university-company-class" style="width: 340px;" [formControl] = "queryField" [(ngModel)]="item.college" type="text">
                                    </div>

                                    <div  *ngIf="item.editUniversity === i">
                                        <div  *ngFor="let item of filteredOptions" class="border-top row mx-0 clickable px-2 py-1" style="background-color: #373737; color: #fff;" matRipple>
                                            <div class="body-subtext pt-2 px-3 " (click)="selectUniversity(item.universityname,i)" >{{item.universityname}}</div>
                                        </div>
                                    </div>

                                </div>
                                <div class="col-5 px-0">
                                     <span class="tick-box rounded-circle mx-auto" *ngIf="item.isPreApproved">
                                        <img class="p-1" src="../../../../assets/images/static/tick-white.svg" height="25" width="25" alt="">
                                     </span>
                                    <div class="row mx-0 align-items-center">
                                        <div class="col-3 px-1">
                                            <button *ngIf="item.universityunmoderated  && item.leftBtnColor !== 'green' && item.rightBtnColor !== 'red'" mat-icon-button matTooltip="Edit" matTooltipClass="toolTipBgColor" class="border-0 bg-white rounded-circle position-relative btn-container">
                                                <img class="center-box" [src]="btnObj.edit" height="25" width="25"
                                                    alt="edit-btn"
                                                    (click)="item.editUniversity = item.editUniversity === i ? -1 : i">
                                            </button>
                                        </div>
                                        <div class="col-6 px-1" *ngIf="!item.isPreApproved">
                                            <button matRipple class="w-50 pb-1" [ngClass]="[item.leftBtnColor, 'moderation-word-bg-'+item.leftBtnColor]" (click)="moderateCollege(i,item,true)">
                                                <img *ngIf="item.leftBtnColor !== 'green'"
                                                    [src]="item.leftBtnColor === 'white' ? btnObj.tick.orange : btnObj.tick.white"
                                                    alt="btn-tick" height="15" width="15">
                                                <span *ngIf="item.leftBtnColor === 'green'" matTooltip="APPROVED"
                                                    matTooltipClass="toolTipBgColor"
                                                    class="c-white small-text position-relative d-block collegeBtnText">
                                                    APPROVED
                                                </span>
                                            </button>
                                            <button matRipple class="w-50 pb-1" (click)="moderateCollege(i,item,false);" [ngClass]="[item.rightBtnColor, 'moderation-word-bg-'+item.rightBtnColor]" (click)="moderateCollege(i,item,false)">
                                                <img *ngIf="item.rightBtnColor !== 'red'"
                                                    [src]="item.rightBtnColor === 'white' ? btnObj.cross.orange : btnObj.cross.white"
                                                    alt="cross-btn" height="12" width="12">
                                                <span *ngIf="item.rightBtnColor === 'red'" matTooltip="REJECTED"
                                                    matTooltipClass="toolTipBgColor"
                                                    class="c-white small-text position-relative d-block collegeBtnText">
                                                    REJECTED
                                                </span>
                                            </button>
                                        </div>
                                        <div class="col-3 px-1 text-center" *ngIf="!item.isPreApproved">
                                            <button *ngIf="item.valueAcceptedRejectedBy" mat-icon-button class="border-0 bg-white rounded-circle position-relative btn-container" matTooltipClass="toolTipInfoBgColor"
                                            [title]="parsedText(item.valueAcceptedRejectedBy)">
                                                <img class="center-box" [src]="btnObj.info" height="25" width="25"
                                                    alt="edit-btn">
                                            </button>
                                            <!-- <button *ngIf="item.valueAcceptedRejectedBy == null" mat-icon-button class="border-0 bg-white rounded-circle position-relative btn-container">
                                                <img class="center-box" [src]="btnObj.info" height="25" width="25"
                                                    alt="edit-btn">
                                            </button> -->

                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                        <ng-template #noCollege>
                            N.A.
                        </ng-template>
                    </div>

                    <!-- Company -->
                    <div class="col-12 px-0 my-3">
                        <div class="oswald c-darkgray body-subtext mb-2">Company name</div>
                        <div class="row mx-0">
                            <div class="col-7 px-0 my-1">
                                <div class="c-darkergray body-subtext" [ngClass]="{'edit-university-company-class': editCompany}" [attr.contenteditable]="editCompany" #companyName>
                                    {{moderationData.company.name ? moderationData.company.name : 'N.A.'}}
                                </div>
                            </div>
                            <div class="col-5 px-0" *ngIf="moderationData.company.name">
                                <div class="row mx-0 align-items-center">
                                    <div class="col-3 px-1">
                                        <button mat-icon-button matTooltip="Edit" matTooltipClass="toolTipBgColor" class="border-0 bg-white rounded-circle position-relative btn-container" *ngIf="moderationData.company.leftBtnColor !== 'green' && !isReviewer">
                                            <img class="center-box" [src]="btnObj.edit" height="25" width="25"
                                                alt="edit-btn" (click)="editCompany = !editCompany">
                                        </button>
                                    </div>
                                    <div class="col-6 px-1">
                                        <button matRipple class="w-50 pb-1" (click)="modeateCompany(true)" [ngClass]="[moderationData.company.leftBtnColor, 'moderation-word-bg-'+moderationData.company.leftBtnColor]">
                                            <img *ngIf="moderationData.company.leftBtnColor !== 'green'"
                                                [src]="moderationData.company.leftBtnColor === 'white' ? btnObj.tick.orange : btnObj.tick.white"
                                                alt="btn-tick" height="15" width="15">
                                            <span *ngIf="moderationData.company.leftBtnColor === 'green'"
                                                matTooltip="APPROVED" matTooltipClass="toolTipBgColor"
                                                class="c-white small-text position-relative d-block collegeBtnText">
                                                APPROVED
                                            </span>
                                        </button>
                                        <button matRipple class="w-50 pb-1" (click)="modeateCompany(false)" [ngClass]="[moderationData.company.rightBtnColor, 'moderation-word-bg-'+moderationData.company.rightBtnColor]">
                                            <img *ngIf="moderationData.company.rightBtnColor !== 'red'"
                                                [src]="moderationData.company.rightBtnColor === 'white' ? btnObj.cross.orange : btnObj.cross.white"
                                                alt="cross-btn" height="12" width="12">
                                            <span *ngIf="moderationData.company.rightBtnColor === 'red'"
                                                matTooltip="REJECTED" matTooltipClass="toolTipBgColor"
                                                class="c-white small-text position-relative d-block collegeBtnText">
                                                REJECTED
                                            </span>
                                        </button>
                                    </div>
                                    <div class="col-3 px-1 text-center">
                                        <button *ngIf="moderationData.company.valueAcceptedRejectedBy" matTooltipClass="toolTipInfoBgColor"
                                        [title]="parsedText(moderationData.company.valueAcceptedRejectedBy)" mat-icon-button class="border-0 bg-white rounded-circle position-relative btn-container">
                                            <img class="center-box" [src]="btnObj.info" height="25" width="25"
                                                alt="edit-btn">
                                        </button>
                                        <!-- <button *ngIf="moderationData.company.valueAcceptedRejectedBy == null" mat-icon-button class="border-0 bg-white rounded-circle position-relative btn-container">
                                            <img class="center-box" [src]="btnObj.info" height="25" width="25"
                                                alt="edit-btn">
                                        </button> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!--More about myself  -->
                    <div class="col-12 px-0 oswald c-darkgray body-subtext mt-3">
                        More about myself
                    </div>

                    <!--Words that best describe - -->
                    <div class="col-12 px-0">
                        <div class="c-darkergray body-subtext my-3">
                            Words that best describe
                        </div>
                        <div class="d-flex" *ngIf="moderationData.moreAboutMyself.wordsDescribeMe.length > 0; else noWordsDescribeMe">
                            <ul class="list-inline" style="width: 90%;">
                                <ng-container *ngFor="let item of moderationData.moreAboutMyself.wordsDescribeMe">
                                    <li class="list-inline-item mb-2" *ngIf="item.customized; else notCustomized">
                                        <span *ngIf="!item.approvedBySupervisor" class="moderation-icon-container position-relative moderation-icon-tick clickable" [ngClass]="item.color">
                                            <img class="center-box" [src]="btnObj.tick[item.color]" alt="tick-icon"
                                                height="15" width="15">
                                        </span>
                                        <span class="small-text c-darkgray moderation-word moderation-word-bg-{{item.color}}" [ngClass]="{'moderation-word-red': item.color==='red', 'moderation-word-green': item.color==='green' }">
                                            {{item.name}}
                                        </span>
                                        <span *ngIf="!item.declineBySupervisior" class="moderation-icon-container position-relative moderation-icon-cross clickable" [ngClass]="item.color">
                                            <img class="center-box" [src]="btnObj.cross[item.color]" alt="cross-icon"
                                                height="12" width="12">
                                        </span>
                                    </li>
                                    <ng-template #notCustomized>
                                        <li class="small-text c-darkgray list-inline-item mb-2">#{{item.name}}</li>
                                    </ng-template>
                                </ng-container>
                            </ul>
                            <!-- <div class="" style="width: 10%;">
                                <button mat-icon-button class="border-0 bg-white rounded-circle position-relative btn-container">
                                    <img class="center-box" [src]="btnObj.info" height="25" width="25" alt="edit-btn">
                                </button>
                            </div> -->
                        </div>
                        <ng-template #noWordsDescribeMe>
                            <div class="small-text c-darkgray mb-2" style="width: 100%;">N.A.</div>
                        </ng-template>
                    </div>

                    <!-- Someone have to like, accept , or tolerate -->
                    <div class="col-12 px-0">
                        <div class="c-darkergray body-subtext my-3">
                            Someone have to like, accept , or tolerate
                        </div>
                        <div class="d-flex" *ngIf="moderationData.moreAboutMyself.myTraits.length > 0; else noMyTraits">
                            <ul class="list-inline" style="width: 90%;">
                                <ng-container *ngFor="let item of moderationData.moreAboutMyself.myTraits">
                                    <li class="list-inline-item mb-2" *ngIf="item.customized; else notCustomized">
                                        <span *ngIf="!item.approvedBySupervisor" class="moderation-icon-container position-relative moderation-icon-tick clickable" [ngClass]="item.color">
                                            <img class="center-box" [src]="btnObj.tick[item.color]" alt="tick-icon"
                                                height="15" width="15">
                                        </span>
                                        <span class="small-text c-darkgray moderation-word moderation-word-bg-{{item.color}}" [ngClass]="{'moderation-word-red': item.color==='red', 'moderation-word-green': item.color==='green' }">
                                            {{item.name}}
                                        </span>
                                        <span *ngIf="!item.declineBySupervisior" class="moderation-icon-container position-relative moderation-icon-cross clickable" [ngClass]="item.color">
                                            <img class="center-box" [src]="btnObj.cross[item.color]" alt="cross-icon"
                                                height="12" width="12">
                                        </span>

                                    </li>
                                    <ng-template #notCustomized>
                                        <li class="small-text c-darkgray list-inline-item mb-2">#{{item.name}}</li>
                                    </ng-template>
                                </ng-container>
                            </ul>
                            <!-- <div class="" style="width: 10%;">
                                <button mat-icon-button class="border-0 bg-white rounded-circle  position-relative btn-container">
                                    <img class="center-box" [src]="btnObj.info" height="25" width="25" alt="edit-btn">
                                </button>
                            </div> -->
                        </div>
                        <ng-template #noMyTraits>
                            <div class="small-text c-darkgray mb-2" style="width: 100%">N.A.</div>
                        </ng-template>
                    </div>

                    <!--Hobbies/Interests  -->
                    <div class="col-12 px-0">
                        <div class="c-darkergray body-subtext my-3">
                            Hobbies/Interests
                        </div>
                        <div class="d-flex" *ngIf="moderationData.moreAboutMyself.myHobbies.length >0; else noHobbies">
                            <ul class="list-inline" style="width: 90%;">
                                <ng-container *ngFor="let item of moderationData.moreAboutMyself.myHobbies">
                                    <li class="list-inline-item mb-2" *ngIf="item.customized; else notCustomized">
                                        <span *ngIf="!item.approvedBySupervisor" class="moderation-icon-container position-relative moderation-icon-tick clickable" [ngClass]="item.color">
                                            <img class="center-box" [src]="btnObj.tick[item.color]" alt="tick-icon"
                                                height="15" width="15">
                                        </span>
                                        <span class="small-text c-darkgray moderation-word moderation-word-bg-{{item.color}}" [ngClass]="{'moderation-word-red': item.color==='red', 'moderation-word-green': item.color==='green' }">
                                            {{item.name}}
                                        </span>
                                        <span *ngIf="!item.declineBySupervisior" class="moderation-icon-container position-relative moderation-icon-cross clickable" [ngClass]="item.color">
                                            <img class="center-box" [src]="btnObj.cross[item.color]" alt="cross-icon"
                                                height="12" width="12">
                                        </span>
                                    </li>
                                    <ng-template #notCustomized>
                                        <li class="small-text c-darkgray list-inline-item mb-2">#{{item.name}}</li>
                                    </ng-template>
                                </ng-container>
                            </ul>
                            <!-- <div class="" style="width: 10%;">
                                <button mat-icon-button class="border-0 bg-white rounded-circle  position-relative btn-container">
                                    <img class="center-box" [src]="btnObj.info" height="25" width="25" alt="edit-btn">
                                </button>
                            </div> -->
                        </div>
                        <ng-template #noHobbies>
                            <div class="small-text c-darkgray mb-2" style="width: 100%">N.A.</div>
                        </ng-template>
                    </div>

                    <!--More about partner  -->
                    <div class="col-12 px-0 oswald c-darkgray body-subtext mt-3">
                        More about partner
                    </div>

                    <!--I am looking for someone who is  -->
                    <div class="col-12 px-0">
                        <div class="c-darkergray body-subtext my-3">
                            I am looking for someone who is
                        </div>
                        <div class="d-flex" *ngIf=" moderationData.moreAboutPatner.lookingFor.length>0; else noLookingFor">
                            <ul class="list-inline" style="width: 90%;">
                                <ng-container *ngFor="let item of moderationData.moreAboutPatner.lookingFor">
                                    <li class="list-inline-item mb-2" *ngIf="item.customized; else notCustomized">
                                        <span *ngIf="!item.approvedBySupervisor" class="moderation-icon-container position-relative moderation-icon-tick clickable" [ngClass]="item.color">
                                            <img class="center-box" [src]="btnObj.tick[item.color]" alt="tick-icon"
                                                height="15" width="15">
                                        </span>
                                        <span class="small-text c-darkgray moderation-word moderation-word-bg-{{item.color}}" [ngClass]="{'moderation-word-red': item.color==='red', 'moderation-word-green': item.color==='green' }">
                                            {{item.name}}
                                        </span>
                                        <span *ngIf="!item.declineBySupervisior" class="moderation-icon-container position-relative moderation-icon-cross clickable" [ngClass]="item.color">
                                            <img class="center-box" [src]="btnObj.cross[item.color]" alt="cross-icon"
                                                height="12" width="12">
                                        </span>
                                    </li>
                                    <ng-template #notCustomized>
                                        <li class="small-text c-darkgray list-inline-item mb-2">#{{item.name}}</li>
                                    </ng-template>
                                </ng-container>
                            </ul>
                            <!-- <div class="" style="width: 10%;">
                                <button mat-icon-button class="border-0 bg-white rounded-circle  position-relative btn-container">
                                    <img class="center-box" [src]="btnObj.info" height="25" width="25" alt="edit-btn">
                                </button>
                            </div> -->
                        </div>
                        <ng-template #noLookingFor>
                            <div class="small-text c-darkgray mb-2" style="width: 100%">N.A.</div>
                        </ng-template>
                    </div>

                    <!-- Qualities that would make someone incompatible with me -->
                    <div class="col-12 px-0">
                        <div class="c-darkergray body-subtext my-3">
                            Qualities that would make someone incompatible with me
                        </div>
                        <div class="d-flex" *ngIf="moderationData.moreAboutPatner.incompatibleQualites.length > 0; else noIncompatibleQualites">
                            <ul class="list-inline" style="width: 90%;">
                                <ng-container *ngFor="let item of moderationData.moreAboutPatner.incompatibleQualites">
                                    <li class="list-inline-item mb-2" *ngIf="item.customized; else notCustomized">
                                        <span *ngIf="!item.approvedBySupervisor" class="moderation-icon-container position-relative moderation-icon-tick clickable" [ngClass]="item.color">
                                            <img class="center-box" [src]="btnObj.tick[item.color]" alt="tick-icon"
                                                height="15" width="15">
                                        </span>
                                        <span class="small-text c-darkgray moderation-word moderation-word-bg-{{item.color}}" [ngClass]="{'moderation-word-red': item.color==='red', 'moderation-word-green': item.color==='green' }">
                                            {{item.name}}
                                        </span>
                                        <span *ngIf="!item.declineBySupervisior" class="moderation-icon-container position-relative moderation-icon-cross clickable" [ngClass]="item.color">
                                            <img class="center-box" [src]="btnObj.cross[item.color]" alt="cross-icon"
                                                height="12" width="12">
                                        </span>
                                    </li>
                                    <ng-template #notCustomized>
                                        <li class="small-text c-darkgray list-inline-item mb-2">#{{item.name}}</li>
                                    </ng-template>
                                </ng-container>
                            </ul>
                            <!-- <div class="" style="width: 10%;">
                                <button mat-icon-button class="border-0 bg-white rounded-circle position-relative btn-container">
                                    <img class="center-box" [src]="btnObj.info" height="25" width="25" alt="edit-btn">
                                </button>
                            </div> -->
                        </div>
                        <ng-template #noIncompatibleQualites>
                            <div class="small-text c-darkgray mb-2" style="width: 90%">N.A.</div>
                        </ng-template>
                    </div>

                    <!--Qualities that do not matter  -->
                    <div class="col-12 px-0">
                        <div class="c-darkergray body-subtext my-3">
                            Qualities that do not matter
                        </div>
                        <div class="d-flex" *ngIf=" moderationData.moreAboutPatner.doesntMatterQualities.length > 0; else noDoesntMatterQualities">
                            <ul class="list-inline" style="width: 90%;">
                                <ng-container *ngFor="let item of moderationData.moreAboutPatner.doesntMatterQualities">
                                    <li class="list-inline-item mb-2" *ngIf="item.customized; else notCustomized">
                                        <span *ngIf="!item.approvedBySupervisor" class="moderation-icon-container position-relative moderation-icon-tick clickable" [ngClass]="item.color">
                                            <img class="center-box" [src]="btnObj.tick[item.color]" alt="tick-icon"
                                                height="15" width="15">
                                        </span>
                                        <span class="small-text c-darkgray moderation-word moderation-word-bg-{{item.color}}" [ngClass]="{'moderation-word-red': item.color==='red', 'moderation-word-green': item.color==='green' }">
                                            {{item.name}}
                                        </span>
                                        <span *ngIf="!item.declineBySupervisior" class="moderation-icon-container position-relative moderation-icon-cross clickable" [ngClass]="item.color">
                                            <img class="center-box" [src]="btnObj.cross[item.color]" alt="cross-icon"
                                                height="12" width="12">
                                        </span>
                                    </li>
                                    <ng-template #notCustomized>
                                        <li class="small-text c-darkgray list-inline-item mb-2">#{{item.name}}</li>
                                    </ng-template>
                                </ng-container>
                            </ul>
                            <!-- <div class="" style="width: 10%;">
                                <button mat-icon-button class="border-0 bg-white rounded-circle  position-relative btn-container">
                                    <img class="center-box" [src]="btnObj.info" height="25" width="25" alt="edit-btn">
                                </button>
                            </div> -->
                        </div>
                        <ng-template #noDoesntMatterQualities>
                            <div class="small-text c-darkgray mb-2" style="width: 90%">N.A.</div>
                        </ng-template>
                    </div>

                </div>
            </div>
        </div>
    </div>

    <!-- Survey Question -->
    <div class="col-12 px-0 text-left box-shadow-section my-2">
        <div class="row mx-0">
            <div class="col-12 box-shadow-section py-3 clickable" data-toggle="collapse" data-target="#surveyQuestion" (click)="getSurveyAnswers()">
                <div class="float-right">
                    <img [src]="btnObj.directionBtn" alt="direction-arrow" height="20" width="20" class="transitionClass" [ngStyle]="{'transform': surveyQuestion ? 'rotate(90deg)' : 'rotate(0deg)'}">
                </div>
                <div class="oswald c-darkgray subheading text-left">
                    Survey questions
                </div>
            </div>
            <div class="col-12 py-2 opensans-light collapse" id="surveyQuestion">
                <ng-container *ngFor="let item of surveyQuestions">
                    <div class="c-graycolor body-subtext my-2">
                        {{item.question}}
                    </div>
                    <div class="opensans-regular body-subtext c-darkgray my-2">
                        {{item.answer}}
                    </div>
                </ng-container>

            </div>
        </div>
    </div>

    <!-- Reported Concerns -->
    <div class="col-12 px-0 text-left box-shadow-section my-2">
        <div class="row mx-0">
            <div class="col-12 box-shadow-section py-3 clickable" data-toggle="collapse" data-target="#reportConcern" (click)="getReportedConcerns(activated, '1')">
                <div class="float-right">
                    <img [src]="btnObj.directionBtn" alt="direction-arrow" height="20" width="20" class="transitionClass" [ngStyle]="{'transform': reportConcern ? 'rotate(90deg)' : 'rotate(0deg)'}">
                </div>
                <div class="oswald c-darkgray subheading text-left">
                    Reported Concerns
                </div>
            </div>

            <div class="col-12 px-0 text-center body-text py-2" *ngIf="reportConcern">
                <div class="row mx-0 border-button opensans-regular clickable" >
                    <div matRipple [ngClass]="activated === '1'? 'active':'not-active'" class="col-6 py-2" (click)="activationOnTab('others')" (click)="getReportedConcerns(activated, '2')"  style="border-bottom :3px solid #cacaca" id="f-button">
                        BY OTHERS
                    </div>
                    <div matRipple [ngClass]="activated === '2'? 'active':'not-active'" class="col-6 py-2" (click)="activationOnTab('me')" style="border-bottom : 3px solid #cacaca" id="s-button" (click)="getReportedConcerns(activated, '2')">
                        BY ME
                    </div>
                    <div class="slider col-12 px-0"></div>
                </div>
            </div>
            <div class="col-12 px-4 c-darkgray pt-2 pb-3" style="max-height: 850px; overflow-y: auto;" *ngIf="reportConcern && activated === '1'">
                <div class="row mx-0 mb-4" *ngFor="let item of reportListDataByOthers">
                    <div class="col-12 px-0">
                        <span class="oswald subheading">
                              {{item.alias}}
                        </span>
                        <span class="body-subtext opensans-light pl-3">#{{item.reportedId}}</span>
                        <span class="body-subtext opensans-light pl-3">{{item.age}} years |  {{item.location}}</span>
                    </div>
                    <div class="col-12 px-0 pt-2 opensans-regular body-subtext">
                        {{item.day}}, {{item.time | date: 'mediumDate'}} {{item.time | date: 'mediumTime'}}
                    </div>
                    <div class="col-12 px-0 pt-2 opensans-light body-subtext" style="word-wrap: break-word;">
                        <span *ngFor="let item of item.reasonSplit; let i = index">
                           {{item}}<br>
                        </span>
                    </div>
                </div>
                <div class="row mx-0" *ngIf="reportListDataByOthers.length == 0">
                    <div class="col-12 px-0 text-center pt-3 oswald subheading">
                        Data not available
                    </div>
                </div>
                <div class="row mx-0" *ngIf="showMoreForByOther">
                    <!-- by other -->
                    <div class="col-12 px-0 text-center">
                        <button class="showMoreButtonBgColor" mat-button (click)="loadMoreData('1', '2')">LOAD MORE</button>
                    </div>
                </div>
            </div>
            <div class="col-12 px-4 pt-2 pb-3" style="max-height: 850px; overflow-y: auto;"  *ngIf="reportConcern && activated === '2'">
                <div class="row mx-0 mb-4" *ngFor="let item of reportListDataByME">
                    <div class="col-12 px-0">
                        <span class="oswald subheading clickable nodeid-color" (click)="navigateToProfileView(item.reportedOnUser)">#{{item.reportedOnUser}}</span>
                    </div>
                    <div class="col-12 px-0 pt-2 opensans-regular body-subtext">
                        {{item.day}}, {{item.time | date: 'mediumDate'}} {{item.time | date: 'mediumTime'}}
                    </div>
                    <div class="col-12 px-0 pt-2 opensans-light body-subtext" style="word-wrap: break-word;">
                        <span *ngFor="let item of item.reasonSplit; let i = index">
                           {{item}}<br>
                        </span>
                    </div>
                </div>
                <div class="row mx-0" *ngIf="reportListDataByME.length == 0">
                    <div class="col-12 px-0 text-center pt-3 oswald subheading">
                        Data not available
                    </div>
                </div>
                <div class="row mx-0" *ngIf="showMoreForByMe">
                    <div class="col-12 px-0 text-center">
                        <button class="showMoreButtonBgColor" mat-button (click)="loadMoreData(activated, '2')">LOAD MORE</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- npsRating -->
    <div class="col-12 px-0 text-left box-shadow-section my-2">
        <div class="row mx-0">
            <div class="col-12 box-shadow-section py-3 clickable" data-toggle="collapse" data-target="#ratingGivenByUser" (click)="getNPSrating()">
                <div class="float-right">
                    <img [src]="btnObj.directionBtn" alt="direction-arrow" height="20" width="20" class="transitionClass" [ngStyle]="{'transform': ratingGivenByUser ? 'rotate(90deg)' : 'rotate(0deg)'}">
                </div>
                <div class="oswald c-darkgray subheading text-left">
                    Net Promoter Score
                </div>
            </div>
            <div class="col-12 py-2 opensans-light collapse" id="ratingGivenByUser">
                <ng-container  *ngIf="ratings.length == 0">
                    <div class="col-12 py-2 px-0 opensans-regular c-darkgray body-text text-center">
                        Question not answered.
                    </div>
                </ng-container>
                <ng-container *ngFor="let item of ratings; let last = last">
                        <div class="row mx-0 py-1">
                            <div class="col-12 px-0 pb-1">
                                <span class="opensans-regular c-darkgray body-subtext">
                                    {{item.rating}}
                                </span>
                                <span class="float-right small-text opensans-regular c-darkgray">
                                    {{item.timestamp | date: 'medium'}}
                                </span>
                                <div class="c-darkgray body-subtext opensans-light" style="word-wrap: break-word;">
                                    {{item.reason}}
                                </div>
                            </div>
                        </div>
                        
                </ng-container>
            </div>
        </div>
    </div>

    <div class="container box-shadow-section">
        <div class="row mx-0">
            <!-- Basics -->
            <div class="col-12 text-left my-3">
                <div class="row mx-0">
                    <div class="col-12">
                        <div class="float-right">
                            <button mat-stroked-button class="edit-button" (click)="navigateToUserview('basics')">EDIT</button>
                        </div>
                        <div class="oswald c-darkgray subheading text-left mt-2">
                            Basics
                        </div>
                    </div>
                    <ng-container *ngFor="let item of userData.basics">
                        <div class="col-6 mt-3">
                            <div class="d-flex">
                                <div class="pr-2">
                                    <img [src]="item.icon" alt="icon" height="25" width="25">
                                </div>
                                <div class="">
                                    <div class="c-darkergray body-subtext opensans-light">
                                        {{item.label}}
                                    </div>
                                    <div class="c-darkergray opensans-regular mt-1">
                                        {{(dateFlag === item.info || visaFlag === item.info) ? (item.info | date: 'MMM, yyy') : item.info}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>

            </div>

            <hr class="col-12 px-0" />

            <!-- Appearance -->
            <div class="col-12 text-left my-3">
                <div class="row mx-0">
                    <div class="col-12">
                        <div class="float-right">
                            <button mat-stroked-button class="edit-button" (click)="navigateToUserview('appearance')">EDIT</button>
                        </div>
                        <div class="oswald c-darkgray subheading text-left mt-2">
                            Appearance
                        </div>
                    </div>
                    <ng-container *ngFor="let item of userData.appearance">
                        <div class="col-6 mt-3">
                            <div class="d-flex">
                                <div class="pr-2">
                                    <img [src]="item.icon" alt="icon" height="25" width="25">
                                </div>
                                <div class="">
                                    <div class="c-darkergray body-subtext opensans-light">
                                        {{item.label}}
                                    </div>
                                    <div class="c-darkergray opensans-regular mt-1">
                                        {{item.info}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>

            </div>

            <hr class="col-12 px-0" />

            <!-- Education and Career -->
            <div class="col-12 text-left my-3">
                <div class="row mx-0">
                    <div class="col-12">
                        <div class="float-right">
                            <button mat-stroked-button class="edit-button" (click)="navigateToUserview('education')">EDIT</button>
                        </div>
                        <div class="oswald c-darkgray subheading text-left mt-2">
                            Education & Career
                        </div>
                    </div>
                    <ng-container *ngFor="let item of userData.education">
                        <div class="col-6 mt-3">
                            <div class="d-flex">
                                <div class="pr-2">
                                    <img [src]="item.icon" alt="icon" height="25" width="25">
                                </div>
                                <div class="">
                                    <div class="c-darkergray body-subtext opensans-light">
                                        {{item.label}}
                                    </div>
                                    <div class="c-darkergray opensans-regular" *ngIf="item.label == 'Degree(s)'">
                                        <div *ngIf="item.info == 'N.A.'">
                                            {{item.info}}
                                        </div>
                                        <div *ngIf="item.info != 'N.A.'">
                                            <div class="mt-1" *ngFor="let infoItem of item.degreeNCollegeData; let i = index">
                                                {{infoItem.degree}} <br> <span class="smaller-text">{{infoItem.college}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="c-darkergray opensans-regular mt-1" *ngIf="item.label != 'Degree(s)'">
                                        {{item.info}}
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>

            <hr class="col-12 px-0" />

            <!-- Socio Background -->
            <div class="col-12 text-left my-3">
                <div class="row mx-0">
                    <div class="col-12">
                        <div class="float-right">
                            <button mat-stroked-button class="edit-button" (click)="navigateToUserview('sociocul')">EDIT</button>
                        </div>
                        <div class="oswald c-darkgray subheading text-left mt-2">
                            Socio-Cultural Background
                        </div>
                    </div>
                    <ng-container *ngFor="let item of userData.socio; let i = index">
                        <div class="col-6 mt-3">
                            <div class="d-flex">
                                <div class="pr-2">
                                    <img [src]="item.icon" alt="icon" height="25" width="25">
                                </div>
                                <div class="">
                                    <div class="c-darkergray body-subtext opensans-light">
                                        {{item.label}}
                                    </div>
                                    <div class="c-darkergray opensans-regular mt-1" *ngIf="item.label != 'Praying habit' && item.label != 'Views on horoscope matching'">
                                        <div *ngIf="item.info == 'N.A.'">
                                            {{item.info}}
                                        </div>
                                        <div *ngIf="item.info != 'N.A.'">
                                            <div *ngFor="let infoItem of item.info">
                                                {{infoItem}}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="c-darkergray opensans-regular mt-1" *ngIf="item.label == 'Praying habit' || item.label == 'Views on horoscope matching'">
                                        {{item.info}}
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>

            </div>

            <hr class="col-12 px-0" />

            <!-- LifeStyle -->
            <div class="col-12 text-left my-3">
                <div class="row mx-0">
                    <div class="col-12">
                        <div class="float-right">
                            <button mat-stroked-button class="edit-button" (click)="navigateToUserview('lifestyle')">EDIT</button>
                        </div>
                        <div class="oswald c-darkgray subheading text-left mt-2">
                            Lifestyle
                        </div>
                    </div>
                    <ng-container *ngFor="let item of userData.lifestyle">
                        <div class="col-6 mt-3">
                            <div class="d-flex">
                                <div class="pr-2">
                                    <img [src]="item.icon" alt="icon" height="25" width="25">
                                </div>
                                <div class="">
                                    <div class="c-darkergray body-subtext opensans-light">
                                        {{item.label}}
                                    </div>
                                    <div class="c-darkergray opensans-regular mt-1" *ngIf="item.label == 'Dietary preferences'">
                                        <div *ngIf="item.info == 'N.A.'">
                                            {{item.info}}
                                        </div>
                                        <div *ngIf="item.info != 'N.A.'">
                                            <div *ngFor="let infoItem of item.info">
                                                {{infoItem}}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="c-darkergray opensans-regular mt-1" *ngIf="item.label != 'Dietary preferences'">
                                        {{item.info}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>

            </div>

            <hr class="col-12 px-0" />

            <!--Family Background-->
            <div class="col-12 text-left my-3">
                <div class="row mx-0">
                    <div class="col-12">
                        <div class="float-right">
                            <button mat-stroked-button class="edit-button" (click)="navigateToUserview('about-family')">EDIT</button>
                        </div>
                        <div class="oswald c-darkgray subheading text-left mt-2">
                            Family Background
                        </div>
                    </div>
                    <ng-container *ngFor="let item of userData.family">
                        <div class="col-6 mt-3">
                            <div class="d-flex">
                                <div class="pr-2">
                                    <img [src]="item.icon" alt="icon" height="25" width="25">
                                </div>
                                <div class="">
                                    <div class="c-darkergray body-subtext opensans-light">
                                        {{item.label}}
                                    </div>
                                    <div class="c-darkergray opensans-regular mt-1" *ngIf="item.label == 'Family based in'">
                                        <div *ngIf="item.info == 'N.A.'">
                                            {{item.info}}
                                        </div>
                                        <div *ngIf="item.info != 'N.A.'">
                                            <div *ngFor="let infoItem of item.info">
                                                {{infoItem}}
                                            </div>
                                        </div>
                                       
                                    </div>
                                    <div class="c-darkergray opensans-regular mt-1" *ngIf="item.label != 'Family based in'">
                                        {{item.info}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>

            </div>

            <hr class="col-12 px-0" />

            <!-- More About Myself -->
            <div class="col-12 text-left my-3">
                <div class="row mx-0">
                    <div class="col-12">
                        <div class="float-right">
                            <button mat-stroked-button class="edit-button" (click)="navigateToUserview('summary/own/description')">EDIT</button>
                        </div>
                        <div class="oswald c-darkgray subheading text-left mt-2">
                            More About Myself
                        </div>
                    </div>
                    <ng-container *ngFor="let traits of userData.aboutMyself">
                        <div class="col-12 mt-3">
                            <div class="d-flex">
                                <div class="pr-2">
                                    <img [src]="traits.icon" alt="icon" height="25" width="25">
                                </div>
                                <div class="">
                                    <div class="c-darkergray body-subtext opensans-light">
                                        {{traits.label}}
                                    </div>
                                    <div class="c-darkergray opensans-regular mt-1">
                                        <ul class="list-inline" *ngIf="traits.info?.length > 0; else noTrait">
                                            <ng-container *ngFor="let item of traits.info">
                                                <li class="list-inline-item traits">
                                                    {{item}}
                                                </li>
                                            </ng-container>
                                        </ul>
                                        <ng-template #noTrait>
                                            <div class="small-text c-darkgray mb-2">N.A.</div>
                                        </ng-template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>

            <hr class="col-12 px-0" />

            <!-- More About Desired Partner -->
            <div class="col-12 text-left my-3">
                <div class="row mx-0">
                    <div class="col-12">
                        <div class="float-right">
                            <button mat-stroked-button class="edit-button" (click)="navigateToUserview('summary/partner/description')">EDIT</button>
                        </div>
                        <div class="oswald c-darkgray subheading text-left mt-2">
                            More About Desired Partner
                        </div>
                    </div>
                    <ng-container *ngFor="let patnerTraits of userData.aboutPatner">
                        <div class="col-12 mt-3">
                            <div class="d-flex">
                                <div class="pr-2">
                                    <img [src]="patnerTraits.icon" alt="icon" height="25" width="25">
                                </div>
                                <div class="">
                                    <div class="c-darkergray body-subtext opensans-light">
                                        {{patnerTraits.label}}
                                    </div>
                                    <div class="c-darkergray opensans-regular mt-1">
                                        <ul class="list-inline" *ngIf="patnerTraits.info?.length > 0; else noTrait">
                                            <ng-container *ngFor="let item of patnerTraits.info">
                                                <li class="list-inline-item traits">
                                                    {{item}}
                                                </li>
                                            </ng-container>
                                        </ul>
                                        <ng-template #noTrait>
                                            <div class="small-text c-darkgray mb-2">N.A.</div>
                                        </ng-template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>