import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { SessionStorageService } from 'src/app/services/session-storage.service';
import { SharedService } from 'src/app/services/shared.service';
import { LoadingScreenComponent } from '../../loading-screen/loading-screen.component';

@Component({
  selector: 'app-user-matches-action',
  templateUrl: './user-matches-action.component.html',
  styleUrls: ['./user-matches-action.component.css']
})
export class UserMatchesActionComponent implements OnInit, OnChanges {
  @Input() userIndex;
  dateFlag = null;
  assetImagePath = '../../../../assets/images/static/';
  activityLabel = [
    {
      label: 'VERY ACTIVE',
      color: '#4f03e0'
    },
    {
      label: 'ACTIVE',
      color: '#008538'
    },
    {
      label: 'SLIPPING',
      color: '#ff9800'
    },
    {
      label: 'INACTIVE',
      color: '#d50000'
    }
  ];

  obj = {
    alias: null,
    userId: null,
    ismale: true,
    firstName: null,
    lastName: null,
    prospectFirstName: null,
    prospectLastName: null,
    whom: null,
    age: null,
    height: null,
    email: null,
    phone: null,
    activityStatus: this.activityLabel[1],
    profileImage: '../../../../assets/images/static/male-avatar-600x600.jpg',
    loginCount: null,
    basicView: [
      {
        parameter: '29 years | 5ft 2.5in–159cm',
        toolTipText: 'Age & Height'
      },
      {
        parameter: null,
        icon: '../../../assets/images/static/degree.svg',
        toolTipText: 'Degree'
      },
      {
        parameter: null,
        icon: '../../../assets/images/static/seeking-for.svg',
        toolTipText: 'Seeking For'
      },
      {
        parameter: null,
        icon: '../../../assets/images/static/income.svg',
        toolTipText: 'Income'
      },

      {
        parameter: null,
        icon: '../../../assets/images/static/time-frame.svg',
        toolTipText: 'Time Frame for Marriage'
      },
      {
        parameter: null,
        subparameter: null,
        icon: '../../../assets/images/static/socio-economic-status.svg',
        toolTipText: 'Socio Economic Status'
      },

      {
        parameter: null,
        icon: '../../../assets/images/static//marital-status.svg',
        toolTipText: 'Marital Status'
      },
      {
        parameter: null,
        icon: '../../../assets/images/static/religion.svg',
        toolTipText: 'Religion'
      },
      {
        parameter: null,
        icon: '../../../assets/images/static/divorced-since.svg',
        toolTipText: 'Divorced Since'
      },
      {
        parameter: null,
        icon: '../../../assets/images/static/location.svg',
        toolTipText: 'Location'
      },
      {
        parameter: null,
        icon: '../../../assets/images/static/number-of-kids.svg',
        toolTipText: 'Number of Kids'
      },
      {
        parameter: null,
        icon: '../../../assets/images/static/community.svg',
        toolTipText: 'Community'
      },
      {
        parameter: null,
        icon: '../../../assets/images/static/citizen.svg',
        toolTipText: 'Citizen'
      },
      {
        parameter: null,
        icon: '../../../assets/images/static/language.svg',
        toolTipText: 'Language'
      }
    ],
    photoCounter: 8,
    verifiedIconsArr: [
      {
        icon: '../../../assets/images/static/mobile-verified.svg',
        verified: false,
        toolTipText: 'Mobile Verified'
      },
      {
        icon: '../../../assets/images/static/email-verified.svg',
        verified: false,
        toolTipText: 'Email Verified'
      },
      {
        icon: '../../../assets/images/static/photoID-verified.svg',
        verified: false,
        toolTipText: 'PhotoID Verified'
      },
      {
        icon: '../../../assets/images/static/degree-verified.svg',
        verified: false,
        toolTipText: 'Degree Verified'
      },
      {
        icon: '../../../assets/images/static/employee-verified.svg',
        verified: false,
        toolTipText: 'Employee Verified'
      },
      {
        icon: '../../../assets/images/static/video-verified.svg',
        verified: false,
        toolTipText: 'Video Verified'
      }
    ],
    btnObj: {
      rightPanelIcons: [
        this.assetImagePath + 'bookmark-gray.svg',
        this.assetImagePath + 'customer-support-interaction-history-gray.svg',
        this.assetImagePath + 'mail-gray.svg',
        this.assetImagePath + 'sent-sms-gray.svg',
        this.assetImagePath + 'call-gray.svg',
        this.assetImagePath + 'sent-notification-gray.svg'
      ],
      matcheIcons: [
        {
          icon: this.assetImagePath + 'total-matches-green.svg',
          toolTipText: 'Total Matches',
          count: 100
        },
        {
          icon: this.assetImagePath + 'unviewed-matches-green.svg',
          toolTipText: 'Unviewed Matches',
          count: 100
        },
        {
          icon: this.assetImagePath + 'viewed-matches-green.svg',
          toolTipText: 'Viewed Matches',
          count: 100
        },
        {
          icon: this.assetImagePath + 'shortlisted-matches-green.svg',
          toolTipText: 'Shortlisted Matches',
          count: 100
        },
        {
          icon: this.assetImagePath + 'ignored-matches-green.svg',
          toolTipText: 'Ignored Matches',
          count: 100
        },
        {
          icon: this.assetImagePath + 'interest-sent-green.svg',
          toolTipText: 'Interest Sent',
          count: 100
        },
        {
          icon: this.assetImagePath + 'mutual-matches-green.svg',
          toolTipText: 'Mutual Matches',
          count: 100
        },
        {
          icon: this.assetImagePath + 'interest-received-green.svg',
          toolTipText: 'Interest Received',
          count: 100
        },
        {
          icon: this.assetImagePath + 'interest-I-declined-green.svg',
          toolTipText: 'Interest I declined',
          count: 100
        },
        {
          icon: this.assetImagePath + 'interest-they-declined-green.svg',
          toolTipText: 'Interest they declined',
          count: 100
        }
      ],
      planIcons: [
        this.assetImagePath + 'quick-connect.svg',
        this.assetImagePath + 'express-connect.svg',
        this.assetImagePath + 'assisted-connect.svg'
      ],
      profileModeration: [
        {
          icon: this.assetImagePath + 'moderation-pending.svg',
          value: true
        }
      ],
      profileQuality: [
        {
          icon: this.assetImagePath + 'user-profile-quality.svg',
          value: 7
        },
        {
          icon: this.assetImagePath + 'user-diamond-profile.svg',
          value: this.assetImagePath + 'no.svg'
        },
        {
          icon: this.assetImagePath + 'user-probability-to-upgrade.svg',
          value: 7
        },
        {
          icon: this.assetImagePath + 'upgrade-to-assisted-connect.svg',
          value: this.assetImagePath + 'yes.svg'
        }
      ]
    },
    userStats: [
      {
        user: [
          {
            icon: this.assetImagePath + 'user-viewed-matches.svg',
            value: 21
          },
          {
            icon: this.assetImagePath + 'user-interest-sent.svg',
            value: 12
          },
          {
            icon: this.assetImagePath + 'user-interest-accepted.svg',
            value: 34
          },
          {
            icon: this.assetImagePath + 'user-photo-requested.svg',
            value: 54
          },
          {
            icon: this.assetImagePath + 'user-messages.svg',
            value: 234
          },
          {
            icon: this.assetImagePath + 'user-incoming-secure-dial.svg',
            value: 123
          },
          {
            icon: this.assetImagePath + 'block-user.svg',
            value: 125
          }
        ],
      },
      {
        other: [
          {
            icon: this.assetImagePath + 'user-viewed-matches.svg',
            value: 21
          },
          {
            icon: this.assetImagePath + 'user-interest-sent.svg',
            value: 12
          },
          {
            icon: this.assetImagePath + 'user-interest-recieved-and-accepted.svg',
            value: 34
          },
          {
            icon: this.assetImagePath + 'user-request-photo-declined.svg',
            value: 54
          },
          {
            icon: this.assetImagePath + 'user-messages.svg',
            value: 234
          },
          {
            icon: this.assetImagePath + 'user-incoming-secure-dial.svg',
            value: 123
          },
          {
            icon: this.assetImagePath + 'user-missed-securedial.svg',
            value: 123
          },
          {
            icon: this.assetImagePath + 'report-user.svg',
            value: 125
          }
        ]
      }
    ]
  };

  userData = [];

  selUserActionIndex = -1;
  selUserActionIcon;
  objIndex;
  skip = 0;
  showMoreButton = false;
  // @Input() moderateUserid : any;
  constructor(private sessionstorage: SessionStorageService, private sharedservice: SharedService, private apiservice: ApiService, private md: MatDialog) {

  }

  ngOnChanges() {
    this.userData = [];
    this.selUserActionIndex = -1;
    this.selUserActionIcon = null;
    this.objIndex = null;
    this.skip = 0;
    if (this.sharedservice.userMatchAction.count > 0) {
      this.showMoreButton = false;
      this.otherUserSetToView(this.sharedservice.userMatchAction.Index, this.skip);
      
    }else {
      this.showMoreButton = false;
    }
   
  }

  ngOnInit(): void {
  }


  userCallToAction(userIndex, index) {
    const re = /gray/gi;
    this.selUserActionIndex = index;
    this.objIndex = userIndex;
    this.selUserActionIcon = this.userData[userIndex].btnObj.rightPanelIcons[index].replace(re, 'orange');
  }


  otherUserSetToView(index, skip) {
    const params = {
      userId: this.sharedservice.userId,
      type: index,
      skip: this.skip
    };

    // const sessionStorageArr = this.sessionstorage.getFromSession('user-' + this.sharedservice.userId + '-userDetail');
    // if (sessionStorageArr && sessionStorageArr[index]) {
    //   const otherUserArr = sessionStorageArr[index];
    //   this.setDataToView(otherUserArr);
    // //  console.log(index, otherUserArr);
    // } else {
      const loaderDailog = this.md.open(LoadingScreenComponent, { disableClose: true });
      this.apiservice.getmatcheduser(params).subscribe(result => {
        const userDetailsArr = [];
        // if (sessionStorageArr) {
        //   userDetailsArr = sessionStorageArr;
        // }
        userDetailsArr[index] = result;
      //  this.sessionstorage.setToSession('user-' + this.sharedservice.userId + '-userDetail', userDetailsArr);
        this.setDataToView(result);
        loaderDailog.close();
      });
   // }
  }

  setDataToView(otherUsers) {
    if (otherUsers.length > 0) {
      otherUsers.forEach((other, index) => {
        const temp: any = {}
        temp.alias = other.alias;
        temp.userId = other.userid;
        temp.email = other.email;
        temp.username = other.username;
        temp.timeStamp = other.timestamp;
       // this.obj.activityStatus = this.activityLabel[index % 4];
        this.userData.push(temp);
      });
      
      if(this.userData.length === this.sharedservice.userMatchAction.count) {
        this.showMoreButton = false;
      }else {
        this.showMoreButton = this.sharedservice.userMatchAction.count > 10 ? true : false;
      }
    }
  }

  viewCompleteProfile(userId) {
    console.log(userId);
    const url = '/admin/profile?userid=' + userId;
    window.open(url,'_blank');
  }
  showMoreData() {
    if (this.sharedservice.userMatchAction.count > this.userData.length) {
      this.skip = this.userData.length;
      this.otherUserSetToView(this.sharedservice.userMatchAction.Index, this.skip)
    }
  }
}
