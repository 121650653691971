import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { OopsErrorPageComponent } from './helping-views/oops-error-page/oops-error-page.component';
import { AdminComponent } from './admin/admin.component';
import { DashboardComponent } from './admin/dashboard/dashboard.component';
import { MetricComponent } from './admin/metric/metric.component';
import { ReportsComponent } from './admin/reports/reports.component';
import { TableComponent } from './admin/table/table.component';
import { ProfileComponent } from './admin/profile/profile.component';
import { ProfilesModerationComponent } from './admin/profiles-moderation/profiles-moderation.component';
import { adminGuardService, adminUrlAccessService, sessionGuardService } from './services/auth.service';
import { ActiveUserComponent } from './admin/active-user/active-user.component';
import { NpsRatingComponent } from './admin/nps-rating/nps-rating.component';
import { AccountManageComponent } from './admin/account-manage/account-manage.component';
import { AdminConsoleComponent } from './admin/admin-console/admin-console.component';
import { CreateUserComponent } from './admin/admin-console/create-user/create-user.component';

const routes: Routes = [
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [adminGuardService],
    children: [
      {
        path: 'account-details',
        component: AccountManageComponent
      },
      {
        path: 'dashboard',
        component: DashboardComponent
      },
      {
        path: 'activeuser',
        component: ActiveUserComponent
      },
      {
        path: 'metrics',
        component: MetricComponent
      },
      {
        path: 'reports',
        component: ReportsComponent
      },
      {
        path: 'table',
        component: TableComponent
      },
      {
        path: 'moderation',
        component: ProfilesModerationComponent
      },
      {
        path: 'analytics/nps',
        component: NpsRatingComponent
      },
      {
        path: 'profile',
        component: ProfileComponent
      },
      {
        path: '**',
        redirectTo: 'dashboard',
      }
    ]
  },
  {
    path: 'admin-console',
    component: AdminComponent,
    canActivate: [adminUrlAccessService],
    children:[
      {
        path: '',
        component: AdminConsoleComponent
      },
      {
        path: 'add-user',
        component: CreateUserComponent
      },
      {
        path: '**',
        component: AdminConsoleComponent,
      }
    ]
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [sessionGuardService]
    // data: {
    //   title: 'Secure login | Jodi365.com',
    //   description: 'Securely log into Jodi365, the best matchmaking site for quality singles.',
    //   image: 'https://jodi365.com/assets/images/static/jodi365-quality-indian-matchmaking.jpg'

    // }
  },
  {
    path: 'oops',
    component: OopsErrorPageComponent,
    data: {
      title: 'Server error'
    }
  },
  {
    path: '**',
    redirectTo: 'admin'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
