import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class TableComponent implements OnInit {
  constructor(private router: Router) {
  }

  ngOnInit(): void {
  }

  navigateToView() {
    this.router.navigate(['/admin/profile'], { queryParams: { id: 178503 } });
  }

  noHref(e) {
    e.preventDefault();
  }
}
