import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-preferences',
  templateUrl: './preferences.component.html',
  styleUrls: ['./preferences.component.css']
})
export class PreferencesComponent implements OnInit {
  displayData = [];
  prefsData : any = {
    age : {
      acceptable : {},
      ideal : {},
      importance : null,
      strict : null,
    },
    marital : {
      acceptable : {},
      ideal : {},
      importance : null,
      strict : null,
    },
    location : {
      acceptable : {},
      ideal : {},
      importance : null,
      strict : null,
    },
    height : {
      acceptable : {},
      ideal : {},
      importance : null,
      strict : null,
    },
    educationLevel : {
      acceptable : {},
      ideal : {},
      importance : null,
      strict : null,
    },
    language : {
      acceptable : {},
      ideal : {},
      importance : null,
      strict : null,
    },
    religion : {
      acceptable : {},
      ideal : {},
      importance : null,
      strict : null,
    },
    approchToFood : {
      acceptable : {},
      ideal : {},
      importance : null,
      strict : null,
    },
    smoking : {
      acceptable : {},
      ideal : {},
      importance : null,
      strict : null,
    },
    drinking : {
      acceptable : {},
      ideal : {},
      importance : null,
      strict : null,
    },
    managedby: {
      acceptable : {},
      ideal : {},
      importance : null,
      strict : null,
    },
    withPhotos : {
      acceptable : {},
      ideal : {},
      importance : null,
      strict : null,
    },
    degrees : {
      acceptable : {},
      ideal : {},
      importance : null,
      strict : null,
    },
    occupation : {
      acceptable : {},
      ideal : {},
      importance : null,
      strict : null,
    },
    socioEconomic : {
      acceptable : {},
      ideal : {},
      importance : null,
      strict : null,
    },
    hasChildren : {
      acceptable : {},
      ideal : {},
      importance : null,
      strict : null,
    }, 
    wantMoreChildren : {
      acceptable : {},
      ideal : {},
      importance : null,
      strict : null,
    },
    bodyType : {
      acceptable : {},
      ideal : {},
      importance : null,
      strict : null,
    },
    bodyPiercings : {
      acceptable : {},
      ideal : {},
      importance : null,
      strict : null,
    },
    tattoos : {
      acceptable : {},
      ideal : {},
      importance : null,
      strict : null,
    },
    communityCaste : {
      acceptable : {},
      ideal : {},
      importance : null,
      strict : null,
    },
    ethnicNationalOrigin : {
      acceptable : {},
      ideal : {},
      importance : null,
      strict : null,
    },
    horoscopeMatching : {
      acceptable : {},
      ideal : {},
      importance : null,
      strict : null,
    },
    grewUpIn : {
      acceptable : {},
      ideal : {},
      importance : null,
      strict : null,
    },
    dietary : {
      acceptable : {},
      ideal : {},
      importance : null,
      strict : null,
    },
    exerciseHabits : {
      acceptable : {},
      ideal : {},
      importance : null,
      strict : null,
    },
    profileManagedBy : {
      acceptable : {},
      ideal : {},
      importance : null,
      strict : null,
    }

  };
  skelentonView = true;
  constructor(private apiservice: ApiService,private sharedservice: SharedService) { }

  ngOnInit(): void {
    const apiparams = Number(window.location.search.substr(1).split('userid=')[1]);
    this.apiservice.getuserpreferences(apiparams).subscribe(async result => {
      if(result){
        
        await this.prepareData(result.preferences);
        this.skelentonView = false;
      }
      
    })
  }

  navigateToUserview () {
    const url = '/userview/' + this.sharedservice.userId + '/preferences';
    window.open(url,'_blank');

  }

  prepareData(apiData){
    // age
    if(apiData.age){
      this.prefsData.age.acceptable.high = apiData.age.acceptable ?  apiData.age.acceptable.high : null;
      this.prefsData.age.acceptable.low = apiData.age.acceptable ? apiData.age.acceptable.low : null; 
      this.prefsData.age.ideal.high = apiData.age.ideal ? apiData.age.ideal.high : null;
      this.prefsData.age.ideal.low = apiData.age.ideal ? apiData.age.ideal.low : null;
      this.prefsData.age.importance = apiData.age.importance;
      this.prefsData.age.strict = apiData.age.strict;
      if(this.prefsData.age.importance >= 0 && this.prefsData.age.importance <= 25 ){
        this.prefsData.age.importance = 25;
      } else if(this.prefsData.age.importance >= 26 && this.prefsData.age.importance <= 50){
        this.prefsData.age.importance = 50;
      } else if(this.prefsData.age.importance >= 51 && this.prefsData.age.importance <= 75){
        this.prefsData.age.importance = 75;
      } else if(this.prefsData.age.importance >= 76 && this.prefsData.age.importance <= 100){
        this.prefsData.age.importance = 100;
      }
    }
   

    // marital
    if(apiData.marital){
      this.prefsData.marital.acceptable = apiData.marital.acceptable ? apiData.marital.acceptable : null;
      this.prefsData.marital.ideal = apiData.marital.ideal ? apiData.marital.ideal : null;
      this.prefsData.marital.importance = apiData.marital.importance;
      this.prefsData.marital.strict = apiData.marital.strict;
      if(this.prefsData.marital.importance >= 0 && this.prefsData.marital.importance <= 25 ){
        this.prefsData.marital.importance = 25;
      } else if(this.prefsData.marital.importance >= 26 && this.prefsData.marital.importance <= 50){
        this.prefsData.marital.importance = 50;
      } else if(this.prefsData.marital.importance >= 51 && this.prefsData.marital.importance <= 75){
        this.prefsData.marital.importance = 75;
      } else if(this.prefsData.marital.importance >= 76 && this.prefsData.marital.importance <= 100){
        this.prefsData.marital.importance = 100;
      }
    }
    
    // location
    if(apiData.location){
      this.prefsData.location.acceptable = apiData.location.acceptable ? apiData.location.acceptable : null;
      this.prefsData.location.ideal = apiData.location.ideal ? apiData.location.ideal : null;
      this.prefsData.location.importance = apiData.location.importance;
      this.prefsData.location.strict = apiData.location.strict;
      if(this.prefsData.location.importance >= 0 && this.prefsData.location.importance <= 25 ){
        this.prefsData.location.importance = 25;
      } else if(this.prefsData.location.importance >= 26 && this.prefsData.location.importance <= 50){
        this.prefsData.location.importance = 50;
      } else if(this.prefsData.location.importance >= 51 && this.prefsData.location.importance <= 75){
        this.prefsData.location.importance = 75;
      } else if(this.prefsData.location.importance >= 76 && this.prefsData.location.importance <= 100){
        this.prefsData.location.importance = 100;
      }
    }

    // height
    if(apiData.height){
      this.prefsData.height.acceptable.high = apiData.height.acceptable ?  apiData.height.acceptable.high : null;
      this.prefsData.height.acceptable.low = apiData.height.acceptable ? apiData.height.acceptable.low : null; 
      this.prefsData.height.ideal.high = apiData.height.ideal ? apiData.height.ideal.high : null;
      this.prefsData.height.ideal.low = apiData.height.ideal ? apiData.height.ideal.low : null;
      if(this.prefsData.height.ideal.high != null){
        this.prefsData.height.ideal.high = this.sharedservice.getConvertedHeight(this.prefsData.height.ideal.high);
      } 
      if(this.prefsData.height.ideal.low != null){
        this.prefsData.height.ideal.low = this.sharedservice.getConvertedHeight(this.prefsData.height.ideal.low);
      }
      if(this.prefsData.height.acceptable.high != null){
        this.prefsData.height.acceptable.high = this.sharedservice.getConvertedHeight(this.prefsData.height.acceptable.high);
      } 
      if(this.prefsData.height.acceptable.low != null){
        this.prefsData.height.acceptable.low = this.sharedservice.getConvertedHeight(this.prefsData.height.acceptable.low);
      }
      this.prefsData.height.importance = apiData.height.importance;
      this.prefsData.height.strict = apiData.height.strict;
      if(this.prefsData.height.importance >= 0 && this.prefsData.height.importance <= 25 ){
        this.prefsData.height.importance = 25;
      } else if(this.prefsData.height.importance >= 26 && this.prefsData.height.importance <= 50){
        this.prefsData.height.importance = 50;
      } else if(this.prefsData.height.importance >= 51 && this.prefsData.height.importance <= 75){
        this.prefsData.height.importance = 75;
      } else if(this.prefsData.height.importance >= 76 && this.prefsData.height.importance <= 100){
        this.prefsData.height.importance = 100;
      }
    }

    // education level
    if(apiData.edulevel){
      this.prefsData.educationLevel.acceptable = apiData.edulevel.acceptable ?  apiData.edulevel.acceptable : null;
      this.prefsData.educationLevel.ideal = apiData.edulevel.ideal ?  apiData.edulevel.ideal : null;
      this.prefsData.educationLevel.importance = apiData.edulevel.importance;
      this.prefsData.educationLevel.strict = apiData.edulevel.strict;
      if(this.prefsData.educationLevel.importance >= 0 && this.prefsData.educationLevel.importance <= 25 ){
        this.prefsData.educationLevel.importance = 25;
      } else if(this.prefsData.educationLevel.importance >= 26 && this.prefsData.educationLevel.importance <= 50){
        this.prefsData.educationLevel.importance = 50;
      } else if(this.prefsData.educationLevel.importance >= 51 && this.prefsData.educationLevel.importance <= 75){
        this.prefsData.educationLevel.importance = 75;
      } else if(this.prefsData.educationLevel.importance >= 76 && this.prefsData.educationLevel.importance <= 100){
        this.prefsData.educationLevel.importance = 100;
      }
    }

    // language
    if(apiData.language){
      this.prefsData.language.acceptable = apiData.language.acceptable ? apiData.language.acceptable : null;
      this.prefsData.language.ideal = apiData.language.ideal ? apiData.language.ideal : null;
      this.prefsData.language.importance = apiData.language.importance;
      this.prefsData.language.strict = apiData.language.strict;
      if(this.prefsData.language.importance >= 0 && this.prefsData.language.importance <= 25 ){
        this.prefsData.language.importance = 25;
      } else if(this.prefsData.language.importance >= 26 && this.prefsData.language.importance <= 50){
        this.prefsData.language.importance = 50;
      } else if(this.prefsData.language.importance >= 51 && this.prefsData.language.importance <= 75){
        this.prefsData.language.importance = 75;
      } else if(this.prefsData.language.importance >= 76 && this.prefsData.language.importance <= 100){
        this.prefsData.language.importance = 100;
      }
    }

    // religion
    if(apiData.religion){
      this.prefsData.religion.acceptable = apiData.religion.acceptable ? apiData.religion.acceptable : null;
      this.prefsData.religion.ideal = apiData.religion.ideal ? apiData.religion.ideal : null;
      this.prefsData.religion.importance = apiData.religion.importance;
      this.prefsData.religion.strict = apiData.religion.strict;
      if(this.prefsData.religion.importance >= 0 && this.prefsData.religion.importance <= 25 ){
        this.prefsData.religion.importance = 25;
      } else if(this.prefsData.religion.importance >= 26 && this.prefsData.religion.importance <= 50){
        this.prefsData.religion.importance = 50;
      } else if(this.prefsData.religion.importance >= 51 && this.prefsData.religion.importance <= 75){
        this.prefsData.religion.importance = 75;
      } else if(this.prefsData.religion.importance >= 76 && this.prefsData.religion.importance <= 100){
        this.prefsData.religion.importance = 100;
      }
    }

    // approchToFood
    if(apiData.foodapproach){
      this.prefsData.approchToFood.acceptable = apiData.foodapproach.acceptable ? apiData.foodapproach.acceptable : null;
      this.prefsData.approchToFood.ideal = apiData.foodapproach.ideal ? apiData.foodapproach.ideal : null;
      this.prefsData.approchToFood.importance = apiData.foodapproach.importance;
      this.prefsData.approchToFood.strict = apiData.foodapproach.strict;
      if(this.prefsData.approchToFood.importance >= 0 && this.prefsData.approchToFood.importance <= 25 ){
        this.prefsData.approchToFood.importance = 25;
      } else if(this.prefsData.approchToFood.importance >= 26 && this.prefsData.approchToFood.importance <= 50){
        this.prefsData.approchToFood.importance = 50;
      } else if(this.prefsData.approchToFood.importance >= 51 && this.prefsData.approchToFood.importance <= 75){
        this.prefsData.approchToFood.importance = 75;
      } else if(this.prefsData.approchToFood.importance >= 76 && this.prefsData.approchToFood.importance <= 100){
        this.prefsData.approchToFood.importance = 100;
      }
    }

    // smoking
    if(apiData.smoking){
      this.prefsData.smoking.acceptable = apiData.smoking.acceptable ? apiData.smoking.acceptable : null;
      this.prefsData.smoking.ideal = apiData.smoking.ideal ? apiData.smoking.ideal : null;
      this.prefsData.smoking.importance = apiData.smoking.importance;
      this.prefsData.smoking.strict = apiData.smoking.strict;
      if(this.prefsData.smoking.importance >= 0 && this.prefsData.smoking.importance <= 25 ){
        this.prefsData.smoking.importance = 25;
      } else if(this.prefsData.smoking.importance >= 26 && this.prefsData.smoking.importance <= 50){
        this.prefsData.smoking.importance = 50;
      } else if(this.prefsData.smoking.importance >= 51 && this.prefsData.smoking.importance <= 75){
        this.prefsData.smoking.importance = 75;
      } else if(this.prefsData.smoking.importance >= 76 && this.prefsData.smoking.importance <= 100){
        this.prefsData.smoking.importance = 100;
      }
    } 

    // drinking
    if(apiData.drinking){
      this.prefsData.drinking.acceptable = apiData.drinking.acceptable ? apiData.drinking.acceptable : null;
      this.prefsData.drinking.ideal = apiData.drinking.ideal ? apiData.drinking.ideal : null;
      this.prefsData.drinking.importance = apiData.drinking.importance;
      this.prefsData.drinking.strict = apiData.drinking.strict;
      if(this.prefsData.drinking.importance >= 0 && this.prefsData.drinking.importance <= 25 ){
        this.prefsData.drinking.importance = 25;
      } else if(this.prefsData.drinking.importance >= 26 && this.prefsData.drinking.importance <= 50){
        this.prefsData.drinking.importance = 50;
      } else if(this.prefsData.drinking.importance >= 51 && this.prefsData.drinking.importance <= 75){
        this.prefsData.drinking.importance = 75;
      } else if(this.prefsData.drinking.importance >= 76 && this.prefsData.drinking.importance <= 100){
        this.prefsData.drinking.importance = 100;
      }
    }

    // managedby
    if(apiData.managedby){
      this.prefsData.managedby.acceptable = apiData.managedby.acceptable ? apiData.managedby.acceptable : null;
      this.prefsData.managedby.ideal = apiData.managedby.ideal ? apiData.managedby.ideal : null;
      this.prefsData.managedby.importance = apiData.managedby.importance;
      this.prefsData.managedby.strict = apiData.managedby.strict;
      if(this.prefsData.managedby.importance >= 0 && this.prefsData.managedby.importance <= 25 ){
        this.prefsData.managedby.importance = 25;
      } else if(this.prefsData.managedby.importance >= 26 && this.prefsData.managedby.importance <= 50){
        this.prefsData.managedby.importance = 50;
      } else if(this.prefsData.managedby.importance >= 51 && this.prefsData.managedby.importance <= 75){
        this.prefsData.managedby.importance = 75;
      } else if(this.prefsData.managedby.importance >= 76 && this.prefsData.managedby.importance <= 100){
        this.prefsData.managedby.importance = 100;
      }
    }

    // withPhotos
    if(apiData.withphoto){
      this.prefsData.withPhotos.strict = apiData.withphoto.strict;
    }

    // degrees 
    if(apiData.degrees){
      this.prefsData.degrees.acceptable = apiData.degrees.acceptable ? apiData.degrees.acceptable : null;
      this.prefsData.degrees.ideal = apiData.degrees.ideal ? apiData.degrees.ideal : null;
      this.prefsData.degrees.importance = apiData.degrees.importance;
      this.prefsData.degrees.strict = apiData.degrees.strict;
      if(this.prefsData.degrees.importance >= 0 && this.prefsData.degrees.importance <= 25 ){
        this.prefsData.degrees.importance = 25;
      } else if(this.prefsData.degrees.importance >= 26 && this.prefsData.degrees.importance <= 50){
        this.prefsData.degrees.importance = 50;
      } else if(this.prefsData.degrees.importance >= 51 && this.prefsData.degrees.importance <= 75){
        this.prefsData.degrees.importance = 75;
      } else if(this.prefsData.degrees.importance >= 76 && this.prefsData.degrees.importance <= 100){
        this.prefsData.degrees.importance = 100;
      }
    }

    // occupation
    if(apiData.occupation){
      this.prefsData.occupation.acceptable = apiData.occupation.acceptable ? apiData.occupation.acceptable : null;
      this.prefsData.occupation.ideal = apiData.occupation.ideal ? apiData.occupation.ideal : null;
      this.prefsData.occupation.importance = apiData.occupation.importance;
      this.prefsData.occupation.strict = apiData.occupation.strict;
      if(this.prefsData.occupation.importance >= 0 && this.prefsData.occupation.importance <= 25 ){
        this.prefsData.occupation.importance = 25;
      } else if(this.prefsData.occupation.importance >= 26 && this.prefsData.occupation.importance <= 50){
        this.prefsData.occupation.importance = 50;
      } else if(this.prefsData.occupation.importance >= 51 && this.prefsData.occupation.importance <= 75){
        this.prefsData.occupation.importance = 75;
      } else if(this.prefsData.occupation.importance >= 76 && this.prefsData.occupation.importance <= 100){
        this.prefsData.occupation.importance = 100;
      }
    }

    // socioEconomic
    if(apiData.socieoeconomic){
      this.prefsData.socioEconomic.acceptable = apiData.socieoeconomic.acceptable ? apiData.socieoeconomic.acceptable : null;
      this.prefsData.socioEconomic.ideal = apiData.socieoeconomic.ideal ? apiData.socieoeconomic.ideal : null;
      this.prefsData.socioEconomic.importance = apiData.socieoeconomic.importance;
      this.prefsData.socioEconomic.strict = apiData.socieoeconomic.strict;
      if(this.prefsData.socioEconomic.importance >= 0 && this.prefsData.socioEconomic.importance <= 25 ){
        this.prefsData.socioEconomic.importance = 25;
      } else if(this.prefsData.socioEconomic.importance >= 26 && this.prefsData.socioEconomic.importance <= 50){
        this.prefsData.socioEconomic.importance = 50;
      } else if(this.prefsData.socioEconomic.importance >= 51 && this.prefsData.socioEconomic.importance <= 75){
        this.prefsData.socioEconomic.importance = 75;
      } else if(this.prefsData.socioEconomic.importance >= 76 && this.prefsData.socioEconomic.importance <= 100){
        this.prefsData.socioEconomic.importance = 100;
      }
    }

    // hasChildren
    if(apiData.currentchildren){
      this.prefsData.hasChildren.acceptable = apiData.currentchildren.acceptable ? apiData.currentchildren.acceptable : null;
      this.prefsData.hasChildren.ideal = apiData.currentchildren.ideal ? apiData.currentchildren.ideal : null;
      this.prefsData.hasChildren.importance = apiData.currentchildren.importance;
      if(this.prefsData.hasChildren.importance >= 0 && this.prefsData.hasChildren.importance <= 25 ){
        this.prefsData.hasChildren.importance = 25;
      } else if(this.prefsData.hasChildren.importance >= 26 && this.prefsData.hasChildren.importance <= 50){
        this.prefsData.hasChildren.importance = 50;
      } else if(this.prefsData.hasChildren.importance >= 51 && this.prefsData.hasChildren.importance <= 75){
        this.prefsData.hasChildren.importance = 75;
      } else if(this.prefsData.hasChildren.importance >= 76 && this.prefsData.hasChildren.importance <= 100){
        this.prefsData.hasChildren.importance = 100;
      }
      // this.prefsData.hasChildren.strict = apiData.currentchildren.strict;
    }

    // wantMoreChildren
    if(apiData.wantschildren){
      this.prefsData.wantMoreChildren.acceptable = apiData.wantschildren.acceptable ? apiData.wantschildren.acceptable : null;
      this.prefsData.wantMoreChildren.ideal = apiData.wantschildren.ideal ? apiData.wantschildren.ideal : null;
      this.prefsData.wantMoreChildren.importance = apiData.wantschildren.importance;
      if(this.prefsData.wantMoreChildren.importance >= 0 && this.prefsData.wantMoreChildren.importance <= 25 ){
        this.prefsData.wantMoreChildren.importance = 25;
      } else if(this.prefsData.wantMoreChildren.importance >= 26 && this.prefsData.wantMoreChildren.importance <= 50){
        this.prefsData.wantMoreChildren.importance = 50;
      } else if(this.prefsData.wantMoreChildren.importance >= 51 && this.prefsData.wantMoreChildren.importance <= 75){
        this.prefsData.wantMoreChildren.importance = 75;
      } else if(this.prefsData.wantMoreChildren.importance >= 76 && this.prefsData.wantMoreChildren.importance <= 100){
        this.prefsData.wantMoreChildren.importance = 100;
      }
      // this.prefsData.wantMoreChildren.strict = apiData.wantschildren.strict;
    }

    // bodyType
    if(apiData.bodytype){
      this.prefsData.bodyType.acceptable = apiData.bodytype.acceptable ? apiData.bodytype.acceptable : null;
      this.prefsData.bodyType.ideal = apiData.bodytype.ideal ? apiData.bodytype.ideal : null;
      this.prefsData.bodyType.importance = apiData.bodytype.importance;
      if(this.prefsData.bodyType.importance >= 0 && this.prefsData.bodyType.importance <= 25 ){
        this.prefsData.bodyType.importance = 25;
      } else if(this.prefsData.bodyType.importance >= 26 && this.prefsData.bodyType.importance <= 50){
        this.prefsData.bodyType.importance = 50;
      } else if(this.prefsData.bodyType.importance >= 51 && this.prefsData.bodyType.importance <= 75){
        this.prefsData.bodyType.importance = 75;
      } else if(this.prefsData.bodyType.importance >= 76 && this.prefsData.bodyType.importance <= 100){
        this.prefsData.bodyType.importance = 100;
      }
      // this.prefsData.bodyType.strict = apiData.bodytype.strict;
    }

    // bodyPiercings
    if(apiData.piercings){
      this.prefsData.bodyPiercings.acceptable = apiData.piercings.acceptable ? apiData.piercings.acceptable : null;
      this.prefsData.bodyPiercings.ideal = apiData.piercings.ideal ? apiData.piercings.ideal : null;
      this.prefsData.bodyPiercings.importance = apiData.piercings.importance;
      if(this.prefsData.bodyPiercings.importance >= 0 && this.prefsData.bodyPiercings.importance <= 25 ){
        this.prefsData.bodyPiercings.importance = 25;
      } else if(this.prefsData.bodyPiercings.importance >= 26 && this.prefsData.bodyPiercings.importance <= 50){
        this.prefsData.bodyPiercings.importance = 50;
      } else if(this.prefsData.bodyPiercings.importance >= 51 && this.prefsData.bodyPiercings.importance <= 75){
        this.prefsData.bodyPiercings.importance = 75;
      } else if(this.prefsData.bodyPiercings.importance >= 76 && this.prefsData.bodyPiercings.importance <= 100){
        this.prefsData.bodyPiercings.importance = 100;
      }
      // this.prefsData.bodyPiercings.strict = apiData.piercings.strict;
    }

    // tattoos
    if(apiData.tattoos){
      this.prefsData.tattoos.acceptable = apiData.tattoos.acceptable ? apiData.tattoos.acceptable : null;
      this.prefsData.tattoos.ideal = apiData.tattoos.ideal ? apiData.tattoos.ideal : null;
      this.prefsData.tattoos.importance = apiData.tattoos.importance;
      if(this.prefsData.tattoos.importance >= 0 && this.prefsData.tattoos.importance <= 25 ){
        this.prefsData.tattoos.importance = 25;
      } else if(this.prefsData.tattoos.importance >= 26 && this.prefsData.tattoos.importance <= 50){
        this.prefsData.tattoos.importance = 50;
      } else if(this.prefsData.tattoos.importance >= 51 && this.prefsData.tattoos.importance <= 75){
        this.prefsData.tattoos.importance = 75;
      } else if(this.prefsData.tattoos.importance >= 76 && this.prefsData.tattoos.importance <= 100){
        this.prefsData.tattoos.importance = 100;
      }
    }

    // communityCaste 
    if(apiData.community){
      this.prefsData.communityCaste.acceptable = apiData.community.acceptable ? apiData.community.acceptable : null;
      this.prefsData.communityCaste.ideal = apiData.community.ideal ? apiData.community.ideal : null;
      this.prefsData.communityCaste.importance = apiData.community.importance;
      this.prefsData.communityCaste.strict = apiData.community.strict;
      if(this.prefsData.communityCaste.importance >= 0 && this.prefsData.communityCaste.importance <= 25 ){
        this.prefsData.communityCaste.importance = 25;
      } else if(this.prefsData.communityCaste.importance >= 26 && this.prefsData.communityCaste.importance <= 50){
        this.prefsData.communityCaste.importance = 50;
      } else if(this.prefsData.communityCaste.importance >= 51 && this.prefsData.communityCaste.importance <= 75){
        this.prefsData.communityCaste.importance = 75;
      } else if(this.prefsData.communityCaste.importance >= 76 && this.prefsData.communityCaste.importance <= 100){
        this.prefsData.communityCaste.importance = 100;
      }
    }

    // ethnicNationalOrigin
    if(apiData.ethnicorigin){
      this.prefsData.ethnicNationalOrigin.acceptable = apiData.ethnicorigin.acceptable ? apiData.ethnicorigin.acceptable : null;
      this.prefsData.ethnicNationalOrigin.ideal = apiData.ethnicorigin.ideal ? apiData.ethnicorigin.ideal : null;
      this.prefsData.ethnicNationalOrigin.importance = apiData.ethnicorigin.importance;
      if(this.prefsData.ethnicNationalOrigin.importance >= 0 && this.prefsData.ethnicNationalOrigin.importance <= 25 ){
        this.prefsData.ethnicNationalOrigin.importance = 25;
      } else if(this.prefsData.ethnicNationalOrigin.importance >= 26 && this.prefsData.ethnicNationalOrigin.importance <= 50){
        this.prefsData.ethnicNationalOrigin.importance = 50;
      } else if(this.prefsData.ethnicNationalOrigin.importance >= 51 && this.prefsData.ethnicNationalOrigin.importance <= 75){
        this.prefsData.ethnicNationalOrigin.importance = 75;
      } else if(this.prefsData.ethnicNationalOrigin.importance >= 76 && this.prefsData.ethnicNationalOrigin.importance <= 100){
        this.prefsData.ethnicNationalOrigin.importance = 100;
      }
      // this.prefsData.ethnicNationalOrigin.strict = apiData.ethnicorigin.strict;
    }

    // horoscopeMatching
    if(apiData.horoscopes){
      this.prefsData.horoscopeMatching.acceptable = apiData.horoscopes.acceptable ? apiData.horoscopes.acceptable : null;
      this.prefsData.horoscopeMatching.ideal = apiData.horoscopes.ideal ? apiData.horoscopes.ideal : null;
      this.prefsData.horoscopeMatching.importance = apiData.horoscopes.importance;
      if(this.prefsData.horoscopeMatching.importance >= 0 && this.prefsData.horoscopeMatching.importance <= 25 ){
        this.prefsData.horoscopeMatching.importance = 25;
      } else if(this.prefsData.horoscopeMatching.importance >= 26 && this.prefsData.horoscopeMatching.importance <= 50){
        this.prefsData.horoscopeMatching.importance = 50;
      } else if(this.prefsData.horoscopeMatching.importance >= 51 && this.prefsData.horoscopeMatching.importance <= 75){
        this.prefsData.horoscopeMatching.importance = 75;
      } else if(this.prefsData.horoscopeMatching.importance >= 76 && this.prefsData.horoscopeMatching.importance <= 100){
        this.prefsData.horoscopeMatching.importance = 100;
      }
    }

    // grewUpIn
    if(apiData.grewupin){
      this.prefsData.grewUpIn.acceptable = apiData.grewupin.acceptable ? apiData.grewupin.acceptable : null;
      this.prefsData.grewUpIn.ideal = apiData.grewupin.ideal ? apiData.grewupin.ideal : null;
      this.prefsData.grewUpIn.importance = apiData.grewupin.importance;
      if(this.prefsData.grewUpIn.importance >= 0 && this.prefsData.grewUpIn.importance <= 25 ){
        this.prefsData.grewUpIn.importance = 25;
      } else if(this.prefsData.grewUpIn.importance >= 26 && this.prefsData.grewUpIn.importance <= 50){
        this.prefsData.grewUpIn.importance = 50;
      } else if(this.prefsData.grewUpIn.importance >= 51 && this.prefsData.grewUpIn.importance <= 75){
        this.prefsData.grewUpIn.importance = 75;
      } else if(this.prefsData.grewUpIn.importance >= 76 && this.prefsData.grewUpIn.importance <= 100){
        this.prefsData.grewUpIn.importance = 100;
      }
    }

    // dietary
    if(apiData.dietaryprefs){ 
      this.prefsData.dietary.acceptable = apiData.dietaryprefs.acceptable ? apiData.dietaryprefs.acceptable : null;
      this.prefsData.dietary.ideal = apiData.dietaryprefs.ideal ? apiData.dietaryprefs.ideal : null;
      this.prefsData.dietary.importance = apiData.dietaryprefs.importance;
      if(this.prefsData.dietary.importance >= 0 && this.prefsData.dietary.importance <= 25 ){
        this.prefsData.dietary.importance = 25;
      } else if(this.prefsData.dietary.importance >= 26 && this.prefsData.dietary.importance <= 50){
        this.prefsData.dietary.importance = 50;
      } else if(this.prefsData.dietary.importance >= 51 && this.prefsData.dietary.importance <= 75){
        this.prefsData.dietary.importance = 75;
      } else if(this.prefsData.dietary.importance >= 76 && this.prefsData.dietary.importance <= 100){
        this.prefsData.dietary.importance = 100;
      }
    }

    // exerciseHabits
    if(apiData.exercise){
      this.prefsData.exerciseHabits.acceptable = apiData.exercise.acceptable ? apiData.exercise.acceptable : null;
      this.prefsData.exerciseHabits.ideal = apiData.exercise.ideal ? apiData.exercise.ideal : null;
      this.prefsData.exerciseHabits.importance = apiData.exercise.importance;
      if(this.prefsData.exerciseHabits.importance >= 0 && this.prefsData.exerciseHabits.importance <= 25 ){
        this.prefsData.exerciseHabits.importance = 25;
      } else if(this.prefsData.exerciseHabits.importance >= 26 && this.prefsData.exerciseHabits.importance <= 50){
        this.prefsData.exerciseHabits.importance = 50;
      } else if(this.prefsData.exerciseHabits.importance >= 51 && this.prefsData.exerciseHabits.importance <= 75){
        this.prefsData.exerciseHabits.importance = 75;
      } else if(this.prefsData.exerciseHabits.importance >= 76 && this.prefsData.exerciseHabits.importance <= 100){
        this.prefsData.exerciseHabits.importance = 100;
      }
    }

    this.displayData.push(this.prefsData);
    
  }
}
