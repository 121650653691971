<div class="row mx-0 mt-5 justify-content-center">
    <div class="col-10 px-0">
        <h1>Representation of sign up count</h1>

        <div style="display: block;" *ngIf="apiresponse">
            <canvas baseChart width="500" height="200" [datasets]="lineChartData" [labels]="lineChartLabels"
                [options]="lineChartOptions" [legend]="lineChartLegend" [chartType]="lineChartType"
                [plugins]="lineChartPlugins">
            </canvas>
        </div>
        <div class="row mx-0 justify-content-center" *ngIf="!apiresponse">
            <div class="col-12 px-2" >
                <div class="row mx-0">
                    <div class="col-12 px-0  mat-elevation-z5 ph-item" style="height: 480px;background-color: #ffffff;">
                       <div class="row mx-4">
                            <div class="d-flex px-0 mat-elevation-z5 mt-5 my-2" style="height: 18px; width: 600px;background-color: #ced4da;"></div>
                            <div class="d-flex px-0 mat-elevation-z5 my-2" style="height: 18px; width: 700px;background-color: #ced4da;"></div>
                            <div class="d-flex px-0 mat-elevation-z5 my-2" style="height: 18px; width: 420px;background-color: #ced4da;"></div>
                       </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>