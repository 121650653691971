import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { SharedService } from 'src/app/services/shared.service';
import { LoadingScreenComponent } from '../../loading-screen/loading-screen.component';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.css']
})
export class ConfirmationDialogComponent implements OnInit {
  markAsfakeReasons = [
    // {
    //   reason:
    //     // tslint:disable-next-line: max-line-length
    //     'The video you uploaded happens to be publicly visible online. For your security, please upload a video that hasn\'t been uploaded on any social media account and isn\'t otherwise searchable on the web.',
    //   isActive: false,
    // },
  ];

  selectedReasons = {};
  declineInputReason = new UntypedFormControl('', Validators.required);
  holdInputProfileReason = new UntypedFormControl('', Validators.required);
  showDeclineReasons;
  selDeclineReasonsBySupervisor;
  selDeclineReasonsByReviewer;
  supervisor;
  reviewer;
  isFakeUser;
  forWhom;
  templateData;
  loaderDailog;
  disableFakeProfileButton = false;
  disableHoldProfileButton = false;
  constructor(public dialogRef: MatDialogRef<ConfirmationDialogComponent>, @Inject(MAT_DIALOG_DATA) public data, private sharedservice: SharedService, private apiservice: ApiService, private localservice:LocalStorageService, private router:Router,private md: MatDialog, public mdRef: MatDialogRef<ConfirmationDialogComponent>,) { 
    this.templateData = this.data.template;
  }

  ngOnInit(): void {
    // tslint:disable-next-line: max-line-length
    if(this.data.isEmailTemp) {
      this.forWhom = this.data.tempEmailData;
    } else {
      if(this.data.isScreeningProfile) {
        this.forWhom = 'screening';
      } else if(this.data.isScreeningHold){
        this.forWhom = 'screening hold';
      } else if(this.data.isfakeUser) {
        this.forWhom = 'fake user';
        this.showDeclineReasons = true;
      } else {
        this.forWhom = 'undo';
        this.showDeclineReasons = false;
        this.isFakeUser = false;
      }
    }
    
    
    
  //  this.showDeclineReasons = this.data.isfakeUser ? true :false;
    // this.selDeclineReasonsBySupervisor = this.data.videoData.declinereason?.split('; ');
    // this.selDeclineReasonsByReviewer = this.data.videoData.reason?.split('; ');
 
  }

  selDeclineReason(e, item, i) {
    if (e.checked) {
      this.selectedReasons[i] = item.reason;
    } else {
      delete this.selectedReasons[i];
    }
    console.log(this.selectedReasons);
  }

  async sendDataBack() {
    let reasons = null;
    // for (const key in this.selectedReasons) {
    //   if (!reasons) {
    //     reasons = this.selectedReasons[key];
    //   } else {
    //     reasons += '; ' + this.selectedReasons[key];
    //   }
    // }
    const param = {
      isfakeprofile: this.data.isfakeUser,
      reason: reasons,
      userid: this.data.userId
    };
    this.disableFakeProfileButton = true;
    if (this.declineInputReason.value) {
      if (reasons) {
        reasons += '; ' + this.declineInputReason.value;
      } else {
        reasons = this.declineInputReason.value;
      }
    }
    param.reason = reasons;
    await this.profileScreeningStatus(param);
   
   // this.dialogRef.close(reasons);
  }

   profileScreeningStatus(obj) {
    const backToData = {
      forWhom:null,
      fakeReason : obj.reason,
    };
    const backToHoldData = {
      comingfrom: null,
      holdReason: obj.holdreason
    }
    this.apiservice.profileScreeningStatus(obj).subscribe(res => {
      this.disableFakeProfileButton = true;
      if(obj.isscreeningdone == 1) {
        this.loaderDailog.close();
        this.dialogRef.close('screening done');
      } else if(obj.isscreeningdone == 3){
        backToHoldData.comingfrom = 'screening is on hold';
        this.loaderDailog.close();
        this.dialogRef.close(backToHoldData);
      }else {
        this.isFakeUser = this.data.isfakeUser;
        this.showDeclineReasons = false;
        if(!this.isFakeUser) {
          backToData.forWhom = 'undo';
          this.dialogRef.close(backToData);
        }else {
          this.forWhom = 'undo';
          setTimeout(() => {
            // this.goBack();
            backToData.forWhom = 'marked as fake';
            console.log('marked as fake');
            this.dialogRef.close(backToData);
          }, 2000);
        }
      }
    
    },
    (err: HttpErrorResponse) => { // HttpErrorResponse for checking errors and we will show a msg to users
      if (err.status === 401) {
        this.localservice.removeToken('user');
        this.router.navigate(['/login']);
      } else if (err.status >= 500) {
      //  this.sharedservice.gettingServerError();
      }
    });
  }
  goBack () {
    
  }
  async screeningDone(type) {
    let holdreasons = null;
    let holdUserReason = this.holdInputProfileReason.value?.trim();
    if(type === 'hold'){
      if(this.holdInputProfileReason.value?.trim() == ''){
        console.log("disable trigger");
        this.disableHoldProfileButton = true;
      }else {
        this.disableHoldProfileButton = false;
        const param = {
          isscreeningdone: 3,
          holdreason: null,
          userid: this.data.userId
        };
        if (this.holdInputProfileReason.value) {
          if (holdreasons) {
            holdreasons += '; ' + holdUserReason;
          } else {
            holdreasons = holdUserReason;
          }
        }
        param.holdreason = holdreasons;
        console.log(param.holdreason);
        this.loaderDailog = this.md.open(LoadingScreenComponent, { disableClose: true });
       
       await this.profileScreeningStatus(param);
      }
     
    } else {
      const param = {
        isscreeningdone: 1,
        reason: null,
        userid: this.data.userId
      };
      this.loaderDailog = this.md.open(LoadingScreenComponent, { disableClose: true });
      await this.profileScreeningStatus(param);
    }
     

   
   
  }
  closeDialog() {
    this.mdRef.close();
  }
}
