import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from '@angular/material/snack-bar';
import { DataService } from 'src/app/services/data.service';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { SharedService } from 'src/app/services/shared.service';
import { MatDatepickerInputEvent, MatDatepicker } from '@angular/material/datepicker';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MyDateAdapter, MONTH_YEAR_FORMATS } from '../../../app/helper';
import { LoadingScreenComponent } from '../loading-screen/loading-screen.component';
import { MatDialog } from '@angular/material/dialog';
declare const $;
@Component({
  selector: 'app-active-user',
  templateUrl: './active-user.component.html',
  styleUrls: ['./active-user.component.css'],
  providers: [
    { provide: DateAdapter, useClass: MyDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: MONTH_YEAR_FORMATS },
  ]
})
export class ActiveUserComponent implements OnInit {
  @ViewChild('dp') datePicker: MatDatepicker<any>;
  @ViewChild('widgetsContent') widgetsContent: ElementRef;
  @ViewChild('userIdValue') searchValue: ElementRef;
  maxdate: Date = new Date();
  dateInput = new UntypedFormControl();
  activeUserData = [];
  searchUserData = [];
  lastRowNumber = -1;
  apiresponse = false;
  apiresponceForSearch = false;
  skipValue = 0;
  skipValueFilter = 0;
  changeColor = [false];
  sortingSignupToggle = false;
  gettingError = false;
  sortingCompletedOnToggle = false;
  sortingLastOnAtToggle = false; 
  sortingProfileQualityToggle = false; 
  sortingACprospectToggle = false; 
  sortingEliteToggle = false; 
  sortingUpgradeToggle = false; 
  sortingAgeToggle = false;
  sortingUnviewedMatchesToggle = false; 
  sortingViewedMatchesToggle = false; 
  sortingShortListMatchesToggle = false;
  sortingIgnoredMatchesToggle = false;
  sortingInterestSentToggle = false;
  sortingMutualInterestToggle = false;
  sortingInterestReceivedToggle = false;
  sortingInterestIdeclineToggle = false;
  sortingInterestTeyDeclineToggle = false;
  removeTheHighLight = -1;
  currentDate = new Date();
  currentRowHighLightId;
  userId;
  preIndex = -1;
  horizontalPosition: MatSnackBarHorizontalPosition = 'end';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  countryCodeOptions = [];
  resultAfterSearch = false;
  isRemoveButtonClick = false;
  filterTypeValue = 'Signup time';
  sortTypeValue = 'Descending order';
  maintainStateForFilter = false;
  calculateDateTimeStamp = 0;
  calculateStartDateTimeStamp = 0;
  calculateEndDateTimeStamp = 0;
  disableShowMoreButton = false;
  filterBasedOn = ['Signup time', 'Profile completed on', 'Access time', 'Unviewed matches', 'Viewed matches', 'Shortlisted matches', 'Ignored matches', 'Interests sent', 'Mutual interests', 'Interests received', 'Interests I declined', 'Interests they declined', 'Profile quality', 'AC prospect', 'Elite profile', 'Upgrade probablity','Incomplete profile', 'Screened but Fake/Hidden'];
  sortByType = ['Descending order', 'Ascending order'];
  repeater;
  notFoundUserId = false;
  disableApplyFilterButton = false;
  loaderDailog;
  current = new Date();
  range = new UntypedFormGroup({
    start: new UntypedFormControl(),
    end: new UntypedFormControl(new Date()),
  });
  dataScroll = false;
  loadMoreButtonClicked = false;
  constructor(private dataservice: DataService, private apiservice : ApiService, private snackBar: MatSnackBar, private router: Router, private localservice:LocalStorageService, private sharedservice:SharedService, private md: MatDialog) {
    this.calculateEndDateTimeStamp =  this.currentDate.getTime();
    this.current.setHours(0)
    this.current.setMinutes(0)
    this.current.setSeconds(0)
    this.current.setMilliseconds(0);
    this.range.controls['start'].setValue(new Date(this.current));
    this.calculateStartDateTimeStamp = new Date(this.current).getTime();
  } 

  ngOnInit(): void {
    // this.currentDate = new Date();
    const params = {
      skip : this.skipValue,
      orderby : 1,
      type : 1,
      from : this.current.getTime(),
      to : this.calculateEndDateTimeStamp
      // signup : 0
    };
    this.apiservice.activeUser(params).subscribe(async result => {
      if(result){
        this.apiresponse = true;
        const apiData = result['userdata'];
        for(const i in apiData){
          await this.prepareData(apiData[i]);
        }
        this.disableShowMoreButton = false;
        this.checkReadMoreButton(this.activeUserData.length);
      }
    },
    (err: HttpErrorResponse) => { // HttpErrorResponse for checking errors and we will show a msg to users
      if (err.status === 401) {
        this.localservice.removeToken('user');
        this.router.navigate(['/login']);
      } else if (err.status >= 500) {
      //  this.sharedservice.gettingServerError();
      }
    });
    if(this.countryCodeOptions?.length == 0){
      this.dataservice.getAllCountriesCodes().subscribe(data => {
        const t: any = data;
        this.countryCodeOptions = t.all;
      });
    }
    
  }

  // automatic scrolloing function
  scrollTo(position, event: MouseEvent){
    if(!this.resultAfterSearch && !this.notFoundUserId){
      if(position == 'left'){
        // console.log('left');
        this.repeater = setInterval(() => {
          this.widgetsContent.nativeElement.scrollLeft -= 5;
        }, 0);
      } else {
        this.repeater = setInterval(() => {
          // console.log('right');
          this.widgetsContent.nativeElement.scrollLeft += 5;
        }, 0);
      }
    }
      
    
    
  }

  clearIntervalRepeater(){
    // console.log('clearing');
    clearInterval(this.repeater);
  }

  callToUser(userId){
    let status;
    this.apiservice.clicktocall(userId).subscribe(result => {
    const statusType = result['type'];
      if (statusType == 1) {
        status = "DB error";
      } else if (statusType == 2) {
        status = "User not exixts";
      } else if (statusType == 3) {
        status = "Outgoing Support phonenumber not listed";
      } else if (statusType == 4) {
        status = "Not an indian number";
      } else if (statusType == 5) {
        status = "Call in progress";
      } else if (statusType == 6) {
        status = "Call can't completed because of user has activated DND";
      } else if (statusType == 7) {
        status = "Exception in Exotel call API";
      } else if (statusType == 8) {
        status = "Exception in Exotel call API";
      }

      this.snackBar.open(status ,'' ,{
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
        duration: 3000,
      });
    });
    
  }
  
  sorting(type, key){

    if(key == 'signupDate'){
      this.sortingSignupToggle = !this.sortingSignupToggle;
      if(type == 'asce'){
        this.activeUserData = this.activeUserData.sort((a,b) => {
          if(a.signupDate < b.signupDate){
            return -1;
          } if(a.signupDate > b.signupDate){
            return 1;
          } 
          return 0;
        });
      } else {
        this.activeUserData = this.activeUserData.sort((b,a) => {
          if(a.signupDate < b.signupDate){
            return -1;
          } if(a.signupDate > b.signupDate){
            return 1;
          } 
          return 0;
        });
      }
    } if(key == 'completedOn'){
      this.sortingCompletedOnToggle = !this.sortingCompletedOnToggle;
        if(type == 'asce'){
          this.activeUserData = this.activeUserData.sort((a,b) => {
            if(a.completedOn < b.completedOn){
              return -1;
            } if(a.completedOn > b.completedOn){
              return 1;
            } 
            return 0;
          });
        } else {
          this.activeUserData = this.activeUserData.sort((b,a) => {
            if(a.completedOn < b.completedOn){
              return -1;
            } if(a.completedOn > b.completedOn){
              return 1;
            } 
            return 0;
          });
        }
    } if(key == 'lastOnlineAt'){
      this.sortingLastOnAtToggle = !this.sortingLastOnAtToggle;
        if(type == 'asce'){
          this.activeUserData = this.activeUserData.sort((a,b) => {
            if(a.lastOnlineAt < b.lastOnlineAt){
              return -1;
            } if(a.lastOnlineAt > b.lastOnlineAt){
              return 1;
            } 
            return 0;
          });
        } else {
          this.activeUserData = this.activeUserData.sort((b,a) => {
            if(a.lastOnlineAt < b.lastOnlineAt){
              return -1;
            } if(a.lastOnlineAt > b.lastOnlineAt){
              return 1;
            } 
            return 0;
          });
        }
    } if(key == 'unViewedMatch'){
      this.sortingUnviewedMatchesToggle = !this.sortingUnviewedMatchesToggle;
        if(type == 'asce'){
          this.activeUserData = this.activeUserData.sort((a,b) => {
            if(a.unViewedMatch < b.unViewedMatch){
              return -1;
            } if(a.unViewedMatch > b.unViewedMatch){
              return 1;
            } 
            return 0;
          });
        } else {
          this.activeUserData = this.activeUserData.sort((b,a) => {
            if(a.unViewedMatch < b.unViewedMatch){
              return -1;
            } if(a.unViewedMatch > b.unViewedMatch){
              return 1;
            } 
            return 0;
          });
        }
    } if(key == 'viewedMatch'){
      this.sortingViewedMatchesToggle =!this.sortingViewedMatchesToggle;
        if(type == 'asce'){
          this.activeUserData = this.activeUserData.sort((a,b) => {
            if(a.viewedMatch < b.viewedMatch){
              return -1;
            } if(a.viewedMatch > b.viewedMatch){
              return 1;
            } 
            return 0;
          });
        } else {
          this.activeUserData = this.activeUserData.sort((b,a) => {
            if(a.viewedMatch < b.viewedMatch){
              return -1;
            } if(a.viewedMatch > b.viewedMatch){
              return 1;
            } 
            return 0;
          });
        }
    } if(key == 'shortListMatch'){
      this.sortingShortListMatchesToggle = !this.sortingShortListMatchesToggle;
        if(type == 'asce'){
          this.activeUserData = this.activeUserData.sort((a,b) => {
            if(a.shortListMatch < b.shortListMatch){
              return -1;
            } if(a.shortListMatch > b.shortListMatch){
              return 1;
            } 
            return 0;
          });
        } else {
          this.activeUserData = this.activeUserData.sort((b,a) => {
            if(a.shortListMatch < b.shortListMatch){
              return -1;
            } if(a.shortListMatch > b.shortListMatch){
              return 1;
            } 
            return 0;
          });
        }
    } if(key == 'ignoredMatch'){
      this.sortingIgnoredMatchesToggle =! this.sortingIgnoredMatchesToggle;
        if(type == 'asce'){
          this.activeUserData = this.activeUserData.sort((a,b) => {
            if(a.ignoredMatch < b.ignoredMatch){
              return -1;
            } if(a.ignoredMatch > b.ignoredMatch){
              return 1;
            } 
            return 0;
          });
        } else {
          this.activeUserData = this.activeUserData.sort((b,a) => {
            if(a.ignoredMatch < b.ignoredMatch){
              return -1;
            } if(a.ignoredMatch > b.ignoredMatch){
              return 1;
            } 
            return 0;
          });
        }
    } if(key == 'interestSend'){
      this.sortingInterestSentToggle = !this.sortingInterestSentToggle;
      if(type == 'asce'){
        this.activeUserData = this.activeUserData.sort((a,b) => {
          if(a.interestSend < b.interestSend){
            return -1;
          } if(a.interestSend > b.interestSend){
            return 1;
          } 
          return 0;
        });
      } else {
        this.activeUserData = this.activeUserData.sort((b,a) => {
          if(a.interestSend < b.interestSend){
            return -1;
          } if(a.interestSend > b.interestSend){
            return 1;
          } 
          return 0;
        });
      }
    } if(key == 'mutualInterest'){
      this.sortingMutualInterestToggle = !this.sortingMutualInterestToggle;
        if(type == 'asce'){
          this.activeUserData = this.activeUserData.sort((a,b) => {
            if(a.mutualInterest < b.mutualInterest){
              return -1;
            } if(a.mutualInterest > b.mutualInterest){
              return 1;
            } 
            return 0;
          });
        } else {
          this.activeUserData = this.activeUserData.sort((b,a) => {
            if(a.mutualInterest < b.mutualInterest){
              return -1;
            } if(a.mutualInterest > b.mutualInterest){
              return 1;
            } 
            return 0;
          });
        }
    } if(key == 'interestReceived'){
      this.sortingInterestReceivedToggle = !this.sortingInterestReceivedToggle;
        if(type == 'asce'){
          this.activeUserData = this.activeUserData.sort((a,b) => {
            if(a.interestReceived < b.interestReceived){
              return -1;
            } if(a.interestReceived > b.interestReceived){
              return 1;
            } 
            return 0;
          });
        } else {
          this.activeUserData = this.activeUserData.sort((b,a) => {
            if(a.interestReceived < b.interestReceived){
              return -1;
            } if(a.interestReceived > b.interestReceived){
              return 1;
            } 
            return 0;
          });
        }
    } if(key == 'interestIdeclined'){
      this.sortingInterestIdeclineToggle = !this.sortingInterestIdeclineToggle;
        if(type == 'asce'){
          this.activeUserData = this.activeUserData.sort((a,b) => {
            if(a.interestIdeclined < b.interestIdeclined){
              return -1;
            } if(a.interestIdeclined > b.interestIdeclined){
              return 1;
            } 
            return 0;
          });
        } else {
          this.activeUserData = this.activeUserData.sort((b,a) => {
            if(a.interestIdeclined < b.interestIdeclined){
              return -1;
            } if(a.interestIdeclined > b.interestIdeclined){
              return 1;
            } 
            return 0;
          });
        }
    } if(key == 'interestTheyDeclined'){
      this.sortingInterestTeyDeclineToggle = !this.sortingInterestTeyDeclineToggle;
        if(type == 'asce'){
          this.activeUserData = this.activeUserData.sort((a,b) => {
            if(a.interestTheyDeclined < b.interestTheyDeclined){
              return -1;
            } if(a.interestTheyDeclined > b.interestTheyDeclined){
              return 1;
            } 
            return 0;
          });
        } else {
          this.activeUserData = this.activeUserData.sort((b,a) => {
            if(a.interestTheyDeclined < b.interestTheyDeclined){
              return -1;
            } if(a.interestTheyDeclined > b.interestTheyDeclined){
              return 1;
            } 
            return 0;
          });
        }
    } if(key == 'profileQuality'){
      this.sortingProfileQualityToggle = !this.sortingProfileQualityToggle;
        if(type == 'asce'){
          this.activeUserData = this.activeUserData.sort((a,b) => {
            
            if(a.profileQuality < b.profileQuality){
              return -1;
            } if(a.profileQuality > b.profileQuality){
              return 1;
            } 
            return 0;
          });
        } else {
          this.activeUserData = this.activeUserData.sort((b,a) => {
          if(a.profileQuality < b.profileQuality){
              return -1;
          } if(a.profileQuality > b.profileQuality){
              return 1;
          } 
          return 0;
          });
        }
    } if(key == 'acProspect'){
      this.sortingACprospectToggle = !this.sortingACprospectToggle;
        if(type == 'asce'){
          this.activeUserData = this.activeUserData.sort((a,b) => {
            if(a.acProspect == null){
              a.acProspect = -1;
            } if( a.acProspect == 'Yes' || a.acProspect == 'yes' || a.acProspect == 'No'||  a.acProspect == 'no' || a.acProspect == 'maybe' || a.acProspect == 'Maybe'){
                if(a.acProspect == 'Yes' || a.acProspect == 'yes'){
                  a.acProspect = '0';
                } else if(a.acProspect == 'No'||  a.acProspect == 'no'){
                  a.acProspect = '1';
                } else if(a.acProspect == 'maybe'||  a.acProspect == 'Maybe'){
                  a.acProspect = '2';
                } 
            } if(b.acProspect == null){
              b.acProspect = -1;
            } if( b.acProspect == 'Yes' || b.acProspect == 'yes' || b.acProspect == 'No'||  b.acProspect == 'no' || b.acProspect == 'maybe' || b.acProspect == 'Maybe'){
              if(b.acProspect == 'Yes' || b.acProspect == 'yes'){
                b.acProspect = '0';
              } else if(b.acProspect == 'No'||  b.acProspect == 'no'){
                b.acProspect = '1';
              } else if(b.acProspect == 'maybe'||  b.acProspect == 'Maybe'){
                a.acProspect = '2';
              } 
            } 
            if(a.acProspect < b.acProspect){
              return -1;
            } if(a.acProspect > b.acProspect){
              return 1;
            } 
            return 0;
          });
        } else {
          this.activeUserData = this.activeUserData.sort((b,a) => {
            if(a.acProspect == null){
              a.acProspect = -1;
            } if( a.acProspect == 'Yes' || a.acProspect == 'yes' || a.acProspect == 'No'||  a.acProspect == 'no' || a.acProspect == 'maybe' || a.acProspect == 'Maybe'){
                if(a.acProspect == 'Yes' || a.acProspect == 'yes'){
                  a.acProspect = '0';
                } else if(a.acProspect == 'No'||  a.acProspect == 'no'){
                  a.acProspect = '1';
                } else if(a.acProspect == 'maybe'||  a.acProspect == 'Maybe'){
                  a.acProspect = '2';
                } 
            } if(b.acProspect == null){
              b.acProspect = -1;
            } if( b.acProspect == 'Yes' || b.acProspect == 'yes' || b.acProspect == 'No'||  b.acProspect == 'no' || b.acProspect == 'maybe' || b.acProspect == 'Maybe'){
              if(b.acProspect == 'Yes' || b.acProspect == 'yes'){
                b.acProspect = '0';
              } else if(b.acProspect == 'No'||  b.acProspect == 'no'){
                b.acProspect = '1';
              } else if(b.acProspect == 'maybe'||  b.acProspect == 'Maybe'){
                a.acProspect = '2';
              } 
            } 
            if(a.acProspect < b.acProspect){
              return -1;
            } if(a.acProspect > b.acProspect){
              return 1;
            } 
            return 0;
          });
        }
    } if(key == 'eliteProfile'){
      this.sortingEliteToggle = !this.sortingEliteToggle;
        if(type == 'asce'){
          this.activeUserData = this.activeUserData.sort((a,b) => {
            if(a.eliteProfile == null){
              a.eliteProfile = -1;
            } if(a.eliteProfile == '1' || a.eliteProfile == '2' || a.eliteProfile == '3' ){
                if(a.eliteProfile == '3'){ // for NO
                  a.eliteProfile = '3';
                } else if(a.eliteProfile == '2'){ // for yes 
                  a.eliteProfile = '2';
                } else if(a.eliteProfile == '1'){ // for undefined
                  a.eliteProfile = '1';
                } 
            } if(b.eliteProfile == null){
              b.eliteProfile = -1;
            } if( b.eliteProfile == '1' || b.eliteProfile == '2' || b.eliteProfile == '3'){
              if(b.eliteProfile == '1'){
                b.eliteProfile = '1';
              } else if(b.eliteProfile == '2'){
                b.eliteProfile = '2';
              } else if(b.eliteProfile == '3'){
                b.eliteProfile = '3';
              } 
            } 
            if(a.eliteProfile < b.eliteProfile){
              return -1;
            } if(a.eliteProfile > b.eliteProfile){
              return 1;
            } 
            return 0;
          });
        } else {
          this.activeUserData = this.activeUserData.sort((b,a) => {
            if(a.eliteProfile == null){
              a.eliteProfile = -1;
            } if( a.eliteProfile == '1' || a.eliteProfile == '2' || a.eliteProfile == '2' ){
                if(a.eliteProfile == '1'){
                  a.eliteProfile = '1';
                } else if(a.eliteProfile == '2'){
                  a.eliteProfile = '2';
                } else if(a.eliteProfile == '3'){
                  a.eliteProfile = '3';
                } 
            } if(b.eliteProfile == null){
              b.eliteProfile = -1;
            } if( b.eliteProfile == '1' || b.eliteProfile == '2' || b.eliteProfile == '3'){
              if(b.eliteProfile == '1'){
                b.eliteProfile = '1';
              } else if(b.eliteProfile == '2'){
                b.eliteProfile = '2';
              } else if(b.eliteProfile == '3'){
                b.eliteProfile = '3';
              } 
            } 
            if(a.eliteProfile < b.eliteProfile){
              return -1;
            } if(a.eliteProfile > b.eliteProfile){
              return 1;
            } 
            return 0;
          });
        }
    } if(key == 'upgradeProbability'){
      this.sortingUpgradeToggle = !this.sortingUpgradeToggle;
        if(type == 'asce'){
          this.activeUserData = this.activeUserData.sort((a,b) => {
            if(a.upgradeProbability < b.upgradeProbability){
              return -1;
            } if(a.upgradeProbability > b.upgradeProbability){
              return 1;
            } 
            return 0;
          });
        } else {
          this.activeUserData = this.activeUserData.sort((b,a) => {
            if(a.upgradeProbability < b.upgradeProbability){
              return -1;
            } if(a.upgradeProbability > b.upgradeProbability){
              return 1;
            } 
            return 0;
          });
        }
    } if(key == 'sex'){
        this.sortingAgeToggle = !this.sortingAgeToggle ;
        if(type == 'asce'){
          this.activeUserData = this.activeUserData.sort((a,b) => {
            if(a.sex < b.sex){
              return -1;
            } if(a.sex > b.sex){
              return 1;
            }
            return 0;
          });

        }else {
          this.activeUserData = this.activeUserData.sort((b,a) => {
            if(a.sex < b.sex){
              return -1;

            }if(a.sex > b.sex){
              return 1;
            }
            return 0;

          });
        }
    }
    
    const index = this.activeUserData.findIndex(e => e.nodeId == this.currentRowHighLightId);
    this.lastRowNumber = -1;
    this.highLightRow(index ,this.currentRowHighLightId);
  }

 
  
  highLightRow(index, nodeId){
    this.changeColor = [false];
    this.changeColor[index] = nodeId;
    if(this.lastRowNumber != index){
      $('#r-class-'+ this.lastRowNumber).removeClass('row-color-class');
      $('#r-class-'+ index).addClass('row-color-class');
    } else {
      $('#r-class-'+ index).addClass('row-color-class');
    }
    this.lastRowNumber = index;
    this.currentRowHighLightId = nodeId;
  }
  
   loadMoreData(){
    this.disableShowMoreButton = true;
    this.dataScroll = true;
    if(this.maintainStateForFilter){
      this.skipValueFilter = this.skipValueFilter + 100;
      this.loadMoreButtonClicked = true;
      this.applyFilter();
      
    } else {
      this.skipValue = this.skipValue + 100;
      this.ngOnInit();
      this.maintainStateForFilter = false;
    }
  }

  addEvent(event , type) {
    let lastvalueRecorder;
    if(lastvalueRecorder == event.value?.getTime()){
      this.maintainStateForFilter = true;
    }else{
      this.maintainStateForFilter = false;
    }
      if(type === 'startDate'){
        this.calculateStartDateTimeStamp = event.value.getTime();
      } else if(type === 'endDate' && event.value != null) {
        this.calculateEndDateTimeStamp = event.value?.getTime() + 86399000 ;
        this.skipValueFilter = 0;
        this.applyFilter();
      }
    
    
  }

  
  filterValue(filterBasedOn){
    let lastvalueRecorder;
    if(lastvalueRecorder == filterBasedOn){
      this.maintainStateForFilter = true;
    } else {
      this.maintainStateForFilter = false;
    }
    this.filterTypeValue = filterBasedOn;
    this.skipValueFilter = 0;
    lastvalueRecorder = filterBasedOn;
  }
  sortTypeFilter(sortTypeBasedOn){
    let lastvalueRecorder;
    if(lastvalueRecorder == sortTypeBasedOn){
      this.maintainStateForFilter = true;
    } else {
      this.maintainStateForFilter = false;
    }
    this.skipValueFilter = 0;
    this.sortTypeValue = sortTypeBasedOn;
    lastvalueRecorder = sortTypeBasedOn;
  }
  

  // for filter searching
  applyFilter(){
    this.disableApplyFilterButton = true;
    this.notFoundUserId = false;
    if(!this.dataScroll){
      this.apiresponse = false;
    }
    this.resultAfterSearch = false;
    this.isRemoveButtonClick = false;
    this.searchValue.nativeElement.value = '';
    let orderByValueBasedonFilter = -1;
    let sortByValueBasedon = 1;
    if(this.filterTypeValue == 'Signup time'){
      orderByValueBasedonFilter = 1;
    } else if(this.filterTypeValue == 'Profile completed on'){
      orderByValueBasedonFilter = 2;
    } else if(this.filterTypeValue == 'Access time'){
      orderByValueBasedonFilter = 3;
    } else if(this.filterTypeValue == 'Unviewed matches'){
      orderByValueBasedonFilter = 4;
    } else if(this.filterTypeValue == 'Viewed matches'){
      orderByValueBasedonFilter = 5;
    } else if(this.filterTypeValue == 'Shortlisted matches'){
      orderByValueBasedonFilter = 6;
    } else if(this.filterTypeValue == 'Ignored matches'){
      orderByValueBasedonFilter = 7;
    } else if(this.filterTypeValue == 'Interests sent'){
      orderByValueBasedonFilter = 8;
    } else if(this.filterTypeValue == 'Mutual interests'){
      orderByValueBasedonFilter = 9;
    } else if(this.filterTypeValue == 'Interests received'){
      orderByValueBasedonFilter = 10;
    } else if(this.filterTypeValue == 'Interests I declined'){
      orderByValueBasedonFilter = 11;
    } else if(this.filterTypeValue == 'Interests they declined'){
      orderByValueBasedonFilter = 12;
    } else if(this.filterTypeValue == 'Profile quality'){
      orderByValueBasedonFilter = 13;
    } else if(this.filterTypeValue == 'AC prospect'){
      orderByValueBasedonFilter = 14;
    } else if(this.filterTypeValue == 'Elite profile'){
      orderByValueBasedonFilter = 15;
    } else if(this.filterTypeValue == 'Upgrade probablity'){
      orderByValueBasedonFilter = 16;
    } else if(this.filterTypeValue == 'Incomplete profile'){
      orderByValueBasedonFilter = 17;
    } else if(this.filterTypeValue == 'Screened but Fake/Hidden'){
      orderByValueBasedonFilter = 18;
    } 

    if(this.sortTypeValue == 'Descending order'){
      sortByValueBasedon = 1;
    } else if(this.sortTypeValue == 'Ascending order'){
      sortByValueBasedon = 2;
    }
    
    if(!this.loadMoreButtonClicked){
      this.skipValueFilter = 0;
    }
    const params = {
      skip: this.skipValueFilter,
      orderby: sortByValueBasedon,
      type: orderByValueBasedonFilter,
      from : this.calculateStartDateTimeStamp,
      to : this.calculateEndDateTimeStamp
      // signup: this.calculateDateTimeStamp
    }

    
    this.apiservice.activeUser(params).subscribe(async result => {
      if(result){
        this.apiresponse = true;
        this.gettingError = false;
        if(this.dataScroll){
          setTimeout(() => {
            this.scrollToBottom();
          }, 1000);
          this.dataScroll = false;
        }
        // console.log(this.maintainStateForFilter);
        if(this.loadMoreButtonClicked){
          this.loadMoreButtonClicked = false;
        } else {
          this.activeUserData = [];
          this.maintainStateForFilter = true;
        }
        // if(!this.maintainStateForFilter){
        //   this.activeUserData = [];
        //   this.maintainStateForFilter = true;
        // }
        
        const apiData = result['userdata'];
        for(const i in apiData){
          await this.prepareData(apiData[i]);
        }
        this.disableApplyFilterButton = false;
        this.disableShowMoreButton = false;
        this.checkReadMoreButton(this.activeUserData.length);
      }
    },
    (err: HttpErrorResponse) => { // HttpErrorResponse for checking errors and we will show a msg to users
      if (err.status === 401) {
        this.localservice.removeToken('user');
        this.router.navigate(['/login']);
      } else if (err.status >= 500) {
      //  this.sharedservice.gettingServerError();
      }
      
    });
    if(this.countryCodeOptions?.length == 0){
      this.dataservice.getAllCountriesCodes().subscribe(data => {
        const t: any = data;
        this.countryCodeOptions = t.all;
  
      });
    }
   

  } 


  scrollToBottom(): void {
    this.loaderDailog = this.md.open(LoadingScreenComponent, { disableClose: true });
    setTimeout(() => {
      var $el = $(".table-size");
      if(this.skipValueFilter > 99){
        let tempScrollHeight = this.skipValueFilter;
        tempScrollHeight = tempScrollHeight * 64;
        $el.animate({scrollTop: tempScrollHeight}, 4000);
        setTimeout(() => {
          this.loaderDailog.close();
        }, 4000);
        
      }
    }, 0);
    
  }

  checkReadMoreButton(ResponseLength){
    if((ResponseLength) % 100 == 0){
      if(ResponseLength > 0){
        this.disableShowMoreButton = false; // show "show more button"
      } else{
        this.disableShowMoreButton = true; // disabled button
      } 
    } else {
      this.disableShowMoreButton= true; // disabled button
    }
  }

  navigateToProfileView(userId){
      const url = '/admin/profile?userid=' + userId;
      window.open(url,'_blank');
      
  }
  moderateProfile(userId) {
    const url = '/admin/moderation?userid=' + userId;
    window.open(url,'_blank');
  }

  navigateToProfileViewAfterSearch(type,userId){
    if(type === 'edit'){
      const url = '/userview/'+userId+'/settings/profile-settings';
      window.open(url,'_blank');
    } else{
        const url = '/admin/profile?userid=' + userId;
        window.open(url,'_blank');
    }
    
  }

  // const index = this.ratingData.findIndex(e => e.userId === Number(userID) );
  removeProfileFromList(userId){
    if(this.searchUserData.length == 1){
      this.resultAfterSearch = false;
      this.searchUserData = [];
    } else {
      const index = this.searchUserData.findIndex(e => e.nodeid === Number(userId));
        if(index > -1){
          this.searchUserData.splice(index,1);  // splice 
        }
    }
  }

  searchByUserIdData(value){
    if(value.length > 0){
    this.loaderDailog = this.md.open(LoadingScreenComponent, { disableClose: true });
    this.notFoundUserId = false;
    value = value.trim();
    if(value.trim().length != 0){
      this.resultAfterSearch = true;
      this.isRemoveButtonClick = true;
      this.apiresponceForSearch= false;
      let params;
    if(isNaN(value)){
      const filter = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
      if(filter.test(value)){
        params = '?email=' + value;
      } else {
        if(value.indexOf('-') > 0){
          params = '?alias=' + value;
        } else {
            params = '?name=' + value;
        } 
      }
    } else if( value > 0){
        if(value.toString().length > 3 && value.toString().length < 8){
          params = '?nodeid=' + value;
          this.gettingError = false;
        } else if(value.toString().length > 7 && value.toString().length < 13){
          params = '?phonenumber=' + value;
          this.gettingError = false;
        } else {
          this.gettingError = true;
          this.resultAfterSearch = false;
          this.notFoundUserId = false;
           this.loaderDailog.close();
        }
    }
    if(!this.gettingError){
      this.apiservice.getspecificuserdetail(params).subscribe(result => {
        this.gettingError = false;
        this.searchUserData = [];
        if(result['userdata']){
          this.changeColor = [false];
          this.apiresponceForSearch= true;
          const apiData = result['userdata'];
          for(const i in apiData){
            this.prepareDataAfterSearching(apiData[i]);
          }
        } else {
          this.resultAfterSearch = false;
          // this.isRemoveButtonClick = false;
          // this.searchValue.nativeElement.value = '';
          this.notFoundUserId = true;
          // this.ngOnInit();
        }
        this.loaderDailog.close();
      }, (err: HttpErrorResponse) => { // HttpErrorResponse for checking errors and we will show a msg to users
        this.loaderDailog.close();
        if (err.status === 401) {
          this.localservice.removeToken('user');
          this.router.navigate(['/login']);
        } else if (err.status >= 500) {
        //  this.sharedservice.gettingServerError();
        }
      });
    }
    }
  }
    
    }
    
  
  prepareDataAfterSearching(apiData){
    const tableDataAfterSearching : any = {};
    tableDataAfterSearching.signuptime = apiData.signuptime;
    tableDataAfterSearching.nodeid = apiData.nodeid;
    tableDataAfterSearching.alias = apiData.alias;
    tableDataAfterSearching.currentquestionnumber = apiData.currentquestionnumber;
    tableDataAfterSearching.accesstime = apiData.accesstime;
    tableDataAfterSearching.email = apiData.email;
    tableDataAfterSearching.isemailverified = apiData.isemailverified;
    tableDataAfterSearching.phonenumber = apiData.phonenumber;
    tableDataAfterSearching.isphoneverified = apiData.isphoneverified;
    tableDataAfterSearching.firstname = apiData.firstname;
    tableDataAfterSearching.lastname = apiData.lastname;
    tableDataAfterSearching.dob = apiData.dob;
    if(apiData.dob){
      const date = new Date(apiData.dob * 1000);
      const today = new Date();
      let age  = today.getFullYear() - date.getFullYear();
      const m = today.getMonth() - date.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < date.getDate())) {
          age--;
      }
      tableDataAfterSearching.dob = age;
    }

    tableDataAfterSearching.location = apiData.location;
    tableDataAfterSearching.socioStatus = apiData.socieo;
    if(apiData.socieo){
      tableDataAfterSearching.socioStatus = apiData.socieo;
      tableDataAfterSearching.socioEcoStatussplit = tableDataAfterSearching.socioStatus.split('(');
    }
    if(apiData.income){
      tableDataAfterSearching.income = apiData.income;
      tableDataAfterSearching.incomesplit = tableDataAfterSearching.income.split('-');
    }
    tableDataAfterSearching.isfakeprofile = apiData.isfakeprofile;
    tableDataAfterSearching.numberOfPhotosApproved = apiData.numberofapprovedphotos;
    tableDataAfterSearching.numberofmatches = apiData.numberofmatches;
    tableDataAfterSearching.unviewedmatches = apiData.unviewedmatches;
    tableDataAfterSearching.acceptedmatches = apiData.acceptedmatches;
    tableDataAfterSearching.rejectedmatches  = apiData.rejectedmatches;
    tableDataAfterSearching.latestmatchdate  = apiData.latestmatchdate;
    tableDataAfterSearching.preferredcommunication  = apiData.preferredcommunication;
    tableDataAfterSearching.userwhoshortlisted  = apiData.userwhoshortlisted;
    tableDataAfterSearching.userwhoblocked  = apiData.userwhoblocked;
    this.searchUserData.push(tableDataAfterSearching);
   
    
  }



  prepareData(apiData){
    const tableData : any = {};
    //console.log(tableData);
    tableData.planExpireDate = apiData.plansNpacks.subscriptionexpireson;
    if(tableData.planExpireDate > this.currentDate) {
      tableData.planType =  apiData.plansNpacks.isassistplan ? 'assisted' : (apiData.plansNpacks.isexpressplan ? 'express' : (apiData.plansNpacks.isquickplan) ? 'quick' : '');
    }
    tableData.signupFrom  = apiData.profileAccess.signupfrom;
    tableData.isscreeningdone = apiData.isscreeningdone;
    tableData.signupDate = apiData.signuptime;
    tableData.nodeId = apiData.userid
    tableData.profileId = apiData.alias;
    tableData.lastSavedQuestion  = apiData.currentquestionnumber;
    tableData.completedOn = apiData.profilecompletedon;
    tableData.createdBy  = apiData.whom;
    tableData.managedBy = apiData.ManagedBy;
    tableData.lastAccessFrom = apiData.profileAccess.accessedFrom;
    tableData.lastOnlineAt = apiData.accesstime;
    tableData.loginCount = apiData.logincount;
    tableData.emailAddress = apiData.email;
    tableData.emailVerified = apiData.isemailverified;
    tableData.countryCode = apiData.countrycode;
    tableData.issignuppending = apiData.issignuppending;
    tableData.profilecompletedon = apiData.profilecompletedon;
    if(apiData.isscreeningdone){
      tableData.isscreeningdone = apiData.isscreeningdone;
    }
    if(apiData.isfakeprofile){
      tableData.isFakeProfileUser = apiData.isfakeprofile;
    }
    if(tableData.countryCode){
      tableData.countryCode = '+' + tableData.countryCode;
      this.countryCodeOptions.map(e =>{
        if(e.value == tableData.countryCode){
          tableData.countryCode = e.name;
        }
      });
    }
    tableData.phoneNumber = apiData.phonenumber;
    tableData.phoneVerified = apiData.isphoneverified;
    tableData.callUser = apiData.callstatus;
    tableData.profileQuality = 0;
    if( apiData.ProfileQuality == 'One' || apiData.ProfileQuality == 'Four' || apiData.ProfileQuality == 'Seven' || apiData.ProfileQuality == 'Ten' || apiData.ProfileQuality == null){
      if(apiData.ProfileQuality == null){
        tableData.profileQuality = 0;
      }else if(apiData.ProfileQuality == 'One'){
        tableData.profileQuality = 1;
      } else if(apiData.ProfileQuality == 'Four'){
        tableData.profileQuality = 4;
      } else if(apiData.ProfileQuality == 'Seven'){
        tableData.profileQuality = 7;
      } else if(apiData.ProfileQuality == 'Ten'){
        tableData.profileQuality = 10;
      }
    }
    tableData.acProspect  = apiData.probabilityToAssistedUser;
    tableData.eliteProfile = apiData.iselite;
    tableData.upgradeProbability = apiData.probabilityToUpgrade;
    tableData.firstName = apiData.firstname;
    tableData.lastName = apiData.lastname;
    tableData.prospectFirstName = apiData.prospectfirstname;
    tableData.sex = apiData.ismale;
    tableData.age = apiData.age;
    tableData.location = apiData.location;
    tableData.maritalStatus = apiData.maritalstatus;
    tableData.relationshipGoal = apiData.marriagetimeframe;
    tableData.timeFrame = apiData.opentotimeframe;
    tableData.eduLevel = apiData.educationlevel;
    tableData.screeningtimestamp = apiData.screeningtimestamp;
    //console.log(tableData.screeningtimestamp);
    if(apiData.socieo){
      tableData.socioEcoStatus = apiData.socieo;
      tableData.socioEcoStatussplit = tableData.socioEcoStatus.split('(');
    }
    tableData.income = apiData.income;
    tableData.incomesplit = apiData.income;
    if(apiData.income){
     if(apiData.income.includes("annual")){
        tableData.incomesplit = tableData.income.split('per annual - ');
        tableData.incomesplit[1] = tableData.incomesplit[1].split('(');
      }else if(apiData.income.includes("year")){
        tableData.incomesplit = tableData.income.split('per year - ');
        tableData.incomesplit[1] = tableData.incomesplit[1].split('(');
      }
      else{
        tableData.incomesplit = apiData.income;
      }
    }
      // console.log(tableData.incomesplit[0]);
      // console.log(tableData.incomesplit[1][0]);
   
    tableData.unViewedMatch = apiData.matchesCount.totalUnviewedMatches;
    tableData.viewedMatch = apiData.matchesCount.totalViewedMatches;
    tableData.shortListMatch = apiData.matchesCount.totalShortlistedMatches;
    tableData.ignoredMatch = apiData.matchesCount.totalIgnoredMatches;
    tableData.interestSend = apiData.matchesCount.totalIntrestsSent;
    tableData.mutualInterest = apiData.matchesCount.totalMutualInterests;
    tableData.interestReceived = apiData.matchesCount.totalInterestsReceived;
    tableData.interestIdeclined = apiData.matchesCount.totalInterestsDeclinedByMe;
    tableData.interestTheyDeclined = apiData.matchesCount.totalProspectDeclinedInterests;
    this.activeUserData.push(tableData);
  }

 
  
}


// from desktop : 1
// from mobile : 4 
// from android : 2